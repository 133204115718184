export const FETCH_BAL_INIT = 'FETCH_BAL_INIT';
export const ZAC_TRUST_INIT = 'ZAC_TRUST_INIT';
export const PAYMENT_INIT = 'PAYMENT_INIT';
export const CHECK_FUND_ACCOUNT_INIT = 'CHECK_FUND_ACCOUNT_INIT';
export const FUND_ACCOUNT_INIT = 'FUND_ACCOUNT_INIT';
export const REFRESH_TRAN_INIT = 'REFRESH_TRAN_INIT';
export const PAYMENT_STREAM_INIT = 'PAYMENT_STREAM_INIT';
export const TRADE_INIT = 'TRADE_INIT';
export const PAYMENT_STREAM_LINE = 'PAYMENT_STREAM_LINE';
export const GET_PAYMENT_ARRAY_INIT = 'GET_PAYMENT_ARRAY_INIT';
export const EXTERNAL_PAY_INIT = 'EXTERNAL_PAY_INIT';
export const VERIFY_PRIV_KEY = 'VERIFY_PRIV_KEY';