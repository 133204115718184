import React, {useState } from 'react';
import {connect} from 'react-redux';
import { Paper, Grid,Button,FormHelperText,FormControl,IconButton } from '@material-ui/core/';
import { InputAdornment,TextField,InputLabel,Card,CardContent,CardHeader,Checkbox,FormControlLabel,Input, Typography} from '@material-ui/core/';
import {Visibility,VisibilityOff} from '@material-ui/icons/';
import sjcl from 'sjcl';
import { styles } from '../layout/theme';
import { withStyles} from '@material-ui/core/styles'
import ContentHeader from '../../components/ContentHeader';
//import PrintThisComponent from '../../components/PrintThisComponent';
//import PrintKeysPage from './PrintKeysPage';
import { Redirect } from 'react-router-dom';
import {BalanceIcon } from '../layout/Gobalicons';
import {changePriv, addSPriv}  from '../../Actions/thunkActions';
import PasswLevel from '../../components/PasswLevel';
import zxcvbn from 'zxcvbn';
import { doEncrypt} from '../../utils/myCrypt';



const ChangePassw = React.memo(props => {
    const [showPassword, setshowPassword] = useState(false);
    const [ password, setPassword ] = useState("");
    const [ passCheck, setPassCheck ] = useState(
                {  score: -1,
                    warning: "",
                    suggestions : []
             });

    const [verShowPassword, setverShowPassword] = useState(false);
    const [ verPassword, setVerPassword ] = useState("");
    const [ errResp , setErrResp ] = useState(false);

    const { classes } = props;

    const doPasswordChange  = ( val ) => {
        if (val.length > 1) {
            const psswScore = zxcvbn(val);
            setPassCheck( {
                score: psswScore.score,
                warning: psswScore.feedback.warning,
                suggestions : psswScore.feedback.suggestions
            }
            );
        } else {
            setPassCheck( {
                score: -1,
                warning: "",
                suggestions : []
            })
        }
        setPassword(val);
    }

    
    const checkLoggedIn = () => {
        return (!props.isLoggedIn ? <Redirect to="/login" /> : null );
     }
     const doPasswSimple = () => {
        if (verPassword!==password) {
            setErrResp(true);
            } else {
               setErrResp(false);
                    const toEncrypt = doEncrypt(password,props.publickey, props.privatekey);
                    toEncrypt.pub = props.publickey;
                    props.doSubmitNewPriv(toEncrypt);
                    props.history.push('/wallet');
            }
     }



     const doPassw =() =>{
         if (verPassword!==password) {
         setErrResp(true);
         } else {
            setErrResp(false);
            const encryptedpri = sjcl.encrypt(password,props.privatekey);
            const parsedEnc = JSON.parse(encryptedpri);
            props.doChangePass({
                "pub": props.publickey,
                "iv": parsedEnc.iv,
                "st": parsedEnc.salt,
                'ct': parsedEnc.ct
            });
            props.history.push('/wallet');
   
         }
     }

    return ( <>
        {checkLoggedIn()}
    
        <ContentHeader 
        classes={classes} 
        headerdisplay="Change Password" 
        has_tp3={true}
        tp3="Balance"
        icon3={<BalanceIcon />}
        icon3click={() => {
            props.history.push('/wallet');
        }}/>
        <Paper elevation={3} className={classes.paper}>
               <Grid container
                 direction="column"
                 justify="center"
                 alignItems="center"
               >
                <Card className={props.classes.card}>
                 <CardContent>
                  <Grid item>
                  <InputLabel htmlFor="adornment-password">
                    Password
                </InputLabel>
                <Input
                id="adornment-password"
                type={showPassword ? 'text' : 'password'}
                value={password}
                error={errResp}
                onChange={(e) => {
                    doPasswordChange(e.target.value);
                }}
                endAdornment={
                    <InputAdornment position="end">
                    <IconButton
                        aria-label="Toggle password visibility"
                        onClick={(e) => {
                        setshowPassword(!showPassword);
                        }}
                    >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                    </InputAdornment>
                }
                />
                </Grid>
                  <Grid item>
                  <InputLabel htmlFor="ver-adornment-password">
                    Verify Password
                </InputLabel>
                <Input
                id="ver-adornment-password"
                type={verShowPassword ? 'text' : 'password'}
                value={verPassword}
                error={errResp}
                onChange={(e) => {
                    setVerPassword(e.target.value);
                }
                
                }
                endAdornment={
                    <InputAdornment position="end">
                    <IconButton
                        aria-label="Toggle password visibility"
                        onClick={(e) => {
                            setverShowPassword(!verShowPassword);
                        }}
                    >
                        {verShowPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                    </InputAdornment>
                }
                />
                </Grid>
                {errResp?
                <Typography variant="caption" className={props.classes.title} color="error" gutterBottom>
                Passwords does not match
                </Typography>
                :""}
                <Grid item>
                <Button className={classes.button} variant="contained" color="primary" size="small" onClick={doPasswSimple}
                disabled={passCheck.score < 1}
                >Change</Button>
                </Grid>
                <br/>

                <Grid item>
                {(passCheck.score !== -1)?
                <PasswLevel {...props} passCheck={passCheck} />
                :""}
                </Grid>

                </CardContent>
                </Card>
                  </Grid>
                </Paper>
   </>
    )
    }
);

const mapStateToProps = (state) => {
    return {
        privatekey : state.accounts.privateKey,
        publickey : state.accounts.publicKey,
        zacDomain : state.accounts.zac_domain,
        isLoggedIn : state.accounts.isLoggedIn,
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
doChangePass: (privrecord) => {
    dispatch(changePriv(privrecord));
 },
 doSubmitNewPriv: (privrecord) => {
    dispatch(addSPriv(privrecord)); 
 },
}
}

export default withStyles(styles)(connect(mapStateToProps,mapDispatchToProps)(ChangePassw));
