import React from 'react';


const FundAccount = (props) => {
      /*const changeProps = () => {
        props.isTest = !props.isTest;
         <button onClick={changeProps() }> Set Prod </button>
      }*/
 //     console.log("FundAcc");
      //console.log(props);
      if (props.isTest) {
          return <React.Fragment >
          <p>This is a Test Account you can fund it and by clicking the button below:  </p>
          <p>But this is not available on the production server. A better way is to use another account to fund the new one</p>
          <button onClick={() => props.doAccountFund(props.publickey)}>Fund Test Account</button>
          </React.Fragment>  

      } else {
          return <React.Fragment>  
          <p>This Account is not Active, you need to fund it by </p>
            <p>Ways to activate account </p>
            <ol>
            <li>
            (1) Ask someone to do a Payment of Lumens to this account. 
            </li>
            <li>
            (2) Fund the Zac account details HERE.
            </li>
            </ol>
        </React.Fragment>  
      }
  };

export default FundAccount;