import React from 'react';
import PropTypes from 'prop-types';
import { Dialog ,DialogActions,DialogContent, DialogContentText ,DialogTitle,Button }from '@material-ui/core/';



const ZacDialog = (props) => {
    const {classes}  = props;
    
return (
     <Dialog
        fullWidth={true}
        maxWidth='sm'
        open={props.open}
        onClose={() => props.handleClose()}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogTitle id="max-width-dialog-title">{ props.title }</DialogTitle>
        <DialogContent>
          <DialogContentText >
            { props.message }
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button className={classes.button} variant="contained" size="small" onClick={() => props.handleYes()} color="primary">
            Yes
          </Button>
          <Button className={classes.button} variant="contained" size="small" onClick={() => props.handleNo()} color="secondary">
            No
          </Button>

        </DialogActions>
      </Dialog>

);
}

ZacDialog.propTypes = {
    classes: PropTypes.object.isRequired,
    title: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
    handleYes: PropTypes.func.isRequired,
    handleNo: PropTypes.func.isRequired,
//    handleClose : PropTypes.func.isRequired

  };
export default ZacDialog;