import React, {Component } from 'react';
import {connect} from 'react-redux';
import { withStyles} from '@material-ui/core/styles'
import ContentHeader from '../../components/ContentHeader';
import { Paper } from '@material-ui/core';
import { styles } from '../layout/theme';
import { getToZacNamePubK } from '../../Actions/thunkActions';
import { doPayment,getPaymentArray }  from '../../Actions/stellarActions';
import {getPhoneNo} from '../../Actions/userActions';
import * as ActionTypes from '../../Actions/types';
import Voucher from '../../components/Voucher';
//import ReceiptIcon from '@material-ui/icons/Receipt';
import { TransactionsIcon,BalanceIcon } from '../layout/Gobalicons';
import { Redirect } from 'react-router-dom';
import VoucherStoresPic from './VoucherStores.png';

class VoucherPage extends Component {
    constructor(props) {
        super(props);
        this.state = {  
            payamount : "",
            memo : ""
        };
    }
    componentDidMount() {
        this.props.dofindToZacNamePubK('voucher',this.props.zacDomain);
        this.props.doGetPhoneNo(this.props.publickey, this.props.zacDomain);
        const d = new Date();
        const curTimeShort = d.getDate() + d.toLocaleString('default', { month: 'short' }) + d.getHours()+":"+ d.getMinutes() ;
        const makeMemo  = 'VOUCHER:' + curTimeShort;
        this.setState({memo : makeMemo });
    }

    componentWillUnmount() {
        this.props.clearUserToPay();
    }
 
  locDoPay= (otherPK,amount,memo,payAsset,privK,pubK) => {
        this.props.payOther(otherPK,amount,this.state.memo,'ZAC',privK,pubK);
        this.props.goGetTrans(pubK);
        this.props.history.push('/tran');
    }


    handleChange = field => event => {
        this.setState({ [field]: event.target.value });
      };

      checkLoggedIn = () => {
      return (!this.props.isLoggedIn ? 
      <Redirect to="/login" /> 
      : null
      );
    }

    checkLevlOne = () => {
        //console.log(this.props.acc_level);
        return (this.props.acc_level < 1 ? 
              <Redirect to="/levone" /> 
            : null
            );
    }
        

    render() {
        return (<>
        {this.checkLoggedIn()}
        {this.checkLevlOne()}

        <Paper elevation={3} className={this.props.classes.paper}>
        <ContentHeader 
        classes={this.props.classes} 
        headerdisplay="Buy Cash Vouchers" 
        has_tp2={true}
        tp2={"Wallet"}
        icon2={<BalanceIcon />}
        icon2click={() => {
            this.props.history.push('/wallet');
        }}
        has_tp3={true}
        tp3={"Transactions"}
        icon3={<TransactionsIcon/>}
        icon3click={() => {
               this.props.history.push('/tran');
            }}

        />
        <Voucher 
            paytozacname="voucher"
            handleChange={this.handleChange} 
            findToZacNamePubK={this.props.dofindToZacNamePubK}  
            zacDomain={this.props.zacDomain} 
            payAsset='ZAC'
            toPayAccount={this.props.toPayAccount}
            hasToPayAcc={this.props.hasToPayAcc} 
            payOther={this.locDoPay} 
            payMemo={this.state.paymemo} 
            payAmount={this.state.payamount} 
            privK={this.props.privatekey} 
            pubK={this.props.publickey}  
            resetUserToPay={this.props.resetUserToPay}
            classes={this.props.classes} 
            isTrade={this.props.isTrade}
            history={this.props.history} 
            phone={this.props.phone}
         //TODO   phoneno={this.props.phoneno}
            />
              </Paper>
              <Paper elevation={3} className={this.props.classes.paper}>
            <img src={VoucherStoresPic} alt="Voucher Stores" />
            <ul>
              <li>Pick n Pay – Ask to make a Mobile Payment (then Smartbanking) </li>
              <li>Clicks – Ask to make a Mobile Payment (then NSFAS)</li>
              <li>Checkers, Shoprite, USave, Dis-Chem – Ask to make a Mobile Payment (then WiCode)</li>
            </ul>
        </Paper>
  
        </>
    );
    }
}
    


const mapStateToProps = (state) => {
    return {
        transactions : state.accounts.transactions,
        privatekey : state.accounts.privateKey,
        publickey : state.accounts.publicKey,
        zacNameMessage : state.accounts.zacNameMessage,
        zacNameAvail : state.accounts.zacNameAvail,
        zacDomain : state.accounts.zac_domain,
        zacName : state.accounts.zacName,
        newZacName : state.accounts.newZacName,
        isLoggedIn : state.accounts.isLoggedIn,
        toPayAccount : state.accounts.toPayAccount,
        hasToPayAcc : state.accounts.hasToPayAcc,
        isTrade : state.accounts.isTrade,
        phone: state.users.phone,
        acc_level : state.accounts.acc_level,
        
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        dofindToZacNamePubK: (zacName,zacDomain) => {
            dispatch(getToZacNamePubK(zacName,zacDomain));
        },
        clearUserToPay: () =>
            dispatch(() => dispatch({type : ActionTypes.CLEAR_ZAC_TO_PAY_NAME}))
        ,
        payOther: (otherPK,amount,memo,payAsset,privK,pubK) => {
            dispatch(doPayment(otherPK,amount,memo,payAsset,privK,pubK));

        },
        goGetTrans: (pubK) => {
           // dispatch({ type: actionTypes.CLEAR_PAY_REC });
            dispatch(getPaymentArray(pubK));
            //dispatch(getAllTransactions(pubK));
          },
          doGetPhoneNo : (pubk,domain)  => {
            dispatch(getPhoneNo(pubk,domain));
        }

   }
}

export default withStyles(styles)(connect(mapStateToProps,mapDispatchToProps)(VoucherPage));