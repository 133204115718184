import React from 'react';
//import TextField from '@material-ui/core/TextField';
import {  Paper,Table,TableBody, TableCell,TableContainer,TableHead,TableRow} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';


const StyledTableCell = withStyles(theme => ({
    head: {
      backgroundColor: '#232f3e',
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  }))(TableCell);



const StelTrans = (props) =>  {
    //console.log(props.transactions);
  //  const { classes } = props;
/*
    const displayalltran = 
     props.transactions
       .sort(
           (a,b) => new Date(b.created_at) - new Date(a.created_at)
            )
       .map(
         singletran => 
             <tr> <td>{singletran.type}</td>
             <td>{singletran.created_at}</td>
             <td>{singletran.amount}</td>
             <td>{singletran.asset}</td>
             <td>{singletran.memo}</td>
             </tr>
          );
*/
          const displayalltrannew = () => {
            if (typeof props.transactions === 'undefined') {
              return null ;
            } else { return   props.transactions
            .sort(
                (a,b) => new Date(b.created_at) - new Date(a.created_at)
                 )
            .map(
              singletran => 
              <TableRow key={singletran.id}>
              <TableCell component="th" scope="row">{singletran.displayType}</TableCell>
              <TableCell align="right">{singletran.created_at.toLocaleDateString() + " " + singletran.created_at.toLocaleTimeString()}</TableCell>
              <TableCell align="right">{singletran.asset}</TableCell>
              <TableCell align="left">{singletran.amm_deb}</TableCell>
              <TableCell align="right">{singletran.amm_cred}</TableCell>
              <TableCell align="right">{singletran.memo}</TableCell>
             {/* <TableCell align="right">{singletran.id}</TableCell> */ }
            </TableRow>
               );
            } 
          }
     
/*
const displayPtran =
          props.transactions.map(
              singletran => 
                  console.log(singletran)
               );
               */
/*
const oldret = <Paper className={classes.Paper} >
        <p>Transactions</p>
        {displayPtran}
        <Table>
        <thead>
            <tr>
            <th>Tran Type</th>
            <th>Date</th>
            <th>Amount</th>
            <th>Asset</th>
            <th>Memo</th>
            </tr>
            </thead>  
            <tbody>
            {displayalltran}
            </tbody>
        </Table>
    </Paper>;
*/
 //console.log(ret);
 return ( <TableContainer component={Paper}>
 <Table className={props.classes.table}  size="small" aria-label="a dense table">
   <TableHead >
     <TableRow>
       <StyledTableCell>Tran Type</StyledTableCell>
       <StyledTableCell align="right">Date</StyledTableCell>
       <StyledTableCell align="right">Asset</StyledTableCell>
       <StyledTableCell  colSpan={2} align="center">Amount</StyledTableCell>
       <StyledTableCell align="right">Description</StyledTableCell>
       {/* <StyledTableCell align="right">Id</StyledTableCell> */ }
     </TableRow>
   </TableHead>
   <TableBody>
        {displayalltrannew()}
    </TableBody>
    </Table>
    </TableContainer>
    );
}


export default StelTrans;

