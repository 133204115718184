import StellarSdk   from 'stellar-sdk';
//import { stellarServer } from '../../Actions/stellarActions';
import { put , delay,call,select } from 'redux-saga/effects';
import {setBalanceArr,
        setCheckAccount,
        setCheckFundAccount,
        //zac_asset,
        zac_fee,
        getPaymentArray,
        getPaymentStreamListner,
        callbackStartPayment,
        callbackPayment,
        getBalAct} from '../../Actions/stellarActions';
import {sendError,sendTxtError,getToZacNamePubK} from '../../Actions/thunkActions';
import * as sagaActions from '../../Actions/types/sagatypes';
import * as actionTypes from '../../Actions/types/index';
import {getTranMaxPageToken,getStellerServer,getCurrentNetwork ,getZacAsset,getTranStreamRunning} from './selectors';





export function* checkFundAccountExist(toFundAccout) {
    try {
        const stellarServer = yield select(getStellerServer);
        yield stellarServer.loadAccount(toFundAccout.pubK);
        yield put(setCheckFundAccount("1"));
    } catch (e) {
        if (typeof e.name !== "undefined") {
            if (e.name === "NotFoundError") {
                yield put(setCheckFundAccount("-1"));   // Account Error Does not exist (Good thing HERE becasue we want to fund it.)
            } else { yield put(sendError(e)); }
        }
    }
}

export function* getBalanceSaga(action) {
   try {
    //console.log("delay 1 s");
    yield delay(1 * 1000);
    //console.log("Check Bal");
    //console.log("getBalanceSaga");
    //console.log(action);
    const stellarServer = yield select(getStellerServer);
    const account = yield stellarServer.loadAccount(action.pubK);
    const hasZac = yield account.balances.some( aBalance => aBalance.asset_code ==='ZAC'  );
    console.log(hasZac);
    if (!(hasZac)) {
           delay(3 * 1000);
           yield put({ type: sagaActions.ZAC_TRUST_INIT ,
            action: {
                privK : action.privK,
                pubK: action.pubK
            }
        });
           console.log("No ZAC Please add");
        }
    
    

    yield put(setBalanceArr(account.balances));
}   catch (e) {
    if (typeof e.name !== "undefined") {
        if (e.name === "NotFoundError") {
        console.log("Account Does not exist!!")  ;
        yield put(setCheckAccount("-1") );   // Account Error Does not exist
        //yield put(sendError("Account does not Exist.")); 
        } else {    yield put(sendError(e)); }
  } else {    yield put(sendError(e)); }
}
}

export function* doZacTrustSaga(paramaction) {
    const stellarServer = yield select(getStellerServer);
    const currentNetwork = yield select(getCurrentNetwork);
    const zac_asset = yield select(getZacAsset);
    const transactionData = {
        asset: zac_asset,
        limit: "100000"
    };
    try {
        //console.log("SAGA : loginSignAccount");
        const loginSignAccount = yield StellarSdk.Keypair.fromSecret(paramaction.action.privK);
        //console.log("Load Account");
        const account = yield stellarServer.loadAccount(paramaction.action.pubK);
        //console.log("Build Tran");
        const transaction = yield new StellarSdk.TransactionBuilder(account,
            { fee: zac_fee, networkPassphrase: currentNetwork }
        ).addOperation(StellarSdk.Operation.changeTrust(transactionData))
            .setTimeout(180)
            .build();
        //console.log("Sign");
        yield transaction.sign(loginSignAccount);
        //console.log(transaction);
        const trRes = yield stellarServer.submitTransaction(transaction);
        // console.log(trRes);
        yield delay(1 * 1000);
        yield put(getBalAct (paramaction.action.pubK, paramaction.action.privK));
    } catch (e) {
        yield put(sendError(e));
    }
}

export function* doPaymentSaga(actionparam ) {
        const stellarServer = yield select(getStellerServer);
        const currentNetwork = yield select(getCurrentNetwork);
        const zac_asset = yield select(getZacAsset);
        const paydetail = actionparam.paydetail;
        //console.log("Payment to : " + paydetail.desPub + " Amount : " + paydetail.payAmm + "Memo : " + paydetail.memo + " Asset: " + paydetail.payAsset + " From Pub" + paydetail.fromPub + ' from Priv' + paydetail.fromPriv )
        let locAsset = zac_asset;
        if (paydetail.payAsset !== 'ZAC') {
          locAsset =  yield StellarSdk.Asset.native();
        }
        try {
            yield stellarServer.loadAccount(paydetail.desPub);
        //console.log(destAcc);
        } 
        catch(e) {
            yield put(sendError(new Error('The destination account does not exist!')));
            
        } finally {
        try {
            const sourceAcc = yield stellarServer.loadAccount(paydetail.fromPub);
            const transaction = yield new StellarSdk.TransactionBuilder(sourceAcc,
                { fee: zac_fee, networkPassphrase: currentNetwork  }
               )
             .addOperation(StellarSdk.Operation.payment({
               destination: paydetail.desPub,
               asset: locAsset,
               amount: paydetail.payAmm
             }))
             .addMemo(StellarSdk.Memo.text(paydetail.memo.substring(0, 27)))
             // Wait a maximum of three minutes for the transaction
             .setTimeout(180)  
             .build();

             yield transaction.sign(StellarSdk.Keypair.fromSecret(paydetail.fromPriv));
             const subTran = yield stellarServer.submitTransaction(transaction);
            
             yield put({
                type : actionTypes.CLEAR_ZAC_TO_PAY_NAME,
                toPayAccount: "",
                toPayStellar: ""
              });
            } catch (e) {
                //console.log(e);
                yield put(sendError(e));
            } 
        }
    }
/** doAccountFundSaga Called from FUND_ACCOUNT_INIT
 * Requires 
 *  param.fundDetail.fromPub
 *  param.fundDetail.fromPriv
 *  param.fundDetail.desPub
 *  param.fundDetail.memo
 */
export function* doAccountFundSaga(actionparam )  {
    const stellarServer = yield select(getStellerServer);
    const currentNetwork = yield select(getCurrentNetwork);
    const fundDetail = actionparam.fundDetail;
    //console.log(actionparam);
    try {
        const sourceAcc = yield stellarServer.loadAccount(fundDetail.fromPub);
/*
        fundDetail:
        desPub: "GBTVOFJ6MLABGCFW3VVIKHW5NX322LGXGGWSW6BYQTWFG26DNZR4W4D7"
        fromPriv: "GCKWJXYNCFQ3FSHK3PBH22PS6EX75DSAP3FBM5XM46O4Q52ZJDXWH5YI"
        fromPub: "SC2XEITZMIXZLVZSF3H2SQVPLAC7CATJYVC7EFZGF33XU2Q24IWM4HF7"
        memo: "Account Funded by : hano5"
*/

        const transaction = yield new StellarSdk.TransactionBuilder(sourceAcc,
            { fee: zac_fee, networkPassphrase: currentNetwork  }
           )
         .addOperation(StellarSdk.Operation.createAccount({
           destination: fundDetail.desPub,
           startingBalance: "2"
         }))
         .addMemo(StellarSdk.Memo.text(fundDetail.memo.substring(0, 27)))
         .setTimeout(30)  
         .build();

         yield transaction.sign(StellarSdk.Keypair.fromSecret(fundDetail.fromPriv));
         const subTran = yield stellarServer.submitTransaction(transaction);
         yield put({
            type : actionTypes.CLEAR_ZAC_FUND_NAME,
          });
        } catch (e) {
            yield put(sendError(e));
        } 
}

export function* doTranRefreshSaga(actionparam) {
    try {
    yield put({ type: actionTypes.CLEAR_PAY_REC });
    yield put(getPaymentArray(actionparam.pubK));
} catch (e) {
    yield put(sendError(e));
} 

}

/**
 * Change Undefined to emty string
 * @param {*} actionparam 
 */
const setUndefToEmpty = (inParam) =>{
    return inParam===undefined?"":inParam;
  }
  

/**
 * getTrans
 * 
 */
const getTrans = async (accountAddress,lastCursor,stellarServer,tranCount) => {
      
      const allTrans = await stellarServer.transactions()
        .forAccount(accountAddress)
        //.cursor(lastCursor)
        .limit(tranCount)
        .order('desc')
        .call();
        //console.log("All Trans");
        //console.log(allTrans);
      const allRecods = await allTrans.records;
  //    return allRecods;
  //console.log("All Records");
  //console.log(allRecods);
      let getEffects = [];
      const allResults = await allRecods.map(
        async (aRec) => {
          
          const allEffects = await aRec.effects();
          //#region loop_with_for comment block 
          /*
          console.log(allEffects.records);

            for (var i = 0; i < allEffects.records; i++) { 
                const el = allEffects.records[i];
            if (el.account === accountAddress) {
                const newRec = {
                    memo: setUndefToEmpty(aRec.memo),
                    successful: aRec.successful,
                    time: aRec.created_at,
                    source_account: aRec.source_account,
                    source_account_sequence: aRec.source_account_sequence,
                    valid_before: aRec.valid_before,
                    //effects: aRec.effects,
                    pagining_token: aRec.paging_token,
                    eff_record_count: allEffects.records.length,
                    eff_pag_token: el.paging_token,
                    type: el.type,
                    type_i: el.type_i,
                    asset_code: setUndefToEmpty(el.asset_code),
                    asset_issuer: setUndefToEmpty(el.asset_issuer),
                    amount: setUndefToEmpty(el.amount)
                  }
                  getEffects.push(newRec);
            }
            else {
                console.log(el);
            }
          }
          */
         //#endregion loop_with_for 
          const getEffects = allEffects.records  // We get All the records debit and credit for the tran but we only want these that involv this account
            .filter(
              (el) => el.account === accountAddress
            )
            .map(effRec => { //console.log(rec)
              //console.log(effRec);
              //const firstrec = allEffects.records[0];
             // console.log(allEffects);
  
            // if(effRec.type !== undefined) {
             const newRec = {
                memo: setUndefToEmpty(aRec.memo),
                successful: aRec.successful,
                time: aRec.created_at,
                source_account: aRec.source_account,
                source_account_sequence: aRec.source_account_sequence,
                valid_before: aRec.valid_before,
                //effects: aRec.effects,
                pagining_token: aRec.paging_token,
                eff_record_count: allEffects.records?allEffects.records.length:0,
                //eff_record_count: allEffects.records.length,
                eff_pag_token: effRec.paging_token,
                type: effRec.type,
                type_i: effRec.type_i,
                asset_code: setUndefToEmpty(effRec.asset_code),
                asset_issuer: setUndefToEmpty(effRec.asset_issuer),
                amount: setUndefToEmpty(effRec.amount)
              }
             //console.log(newRec) ;
             return newRec;
             // }
              //allResults.push(newRec);
            })
          
             //console.log(getEffects);
            return getEffects.filter( ( element ) => { return element !== undefined; });
        }
      );
      return   Promise.all(allResults);
  };
/**
 * New procedure to get all the payments in one go.
 * 
 */
export function* getHistoricPayments(actionparam) {
    try {
    const stellarServer = yield select(getStellerServer);
//    const tranMaxPageToken =  yield select(getTranMaxPageToken);
     const tranStreamRunning = yield select(getTranStreamRunning);
     if (!tranStreamRunning) {
            // const currentNetwork = yield select(getCurrentNetwork);
                const allPaymentArr = yield call(getTrans,actionparam.pubK,"0",stellarServer,30);
                
                const reformatArr = yield allPaymentArr
                                            .filter( ( element ) => { return element[0] !== undefined; })
                                            .map( r => r[0]);
                yield put({
                        type: actionTypes.SET_TRAN_ARRAY,
                        allTranArr: reformatArr
                });
                yield delay(2000);
                const lastCursor = yield select(getTranMaxPageToken);
                //console.log("getTranMaxPageToken");
                //console.log(lastCursor);
                yield put(getPaymentStreamListner(actionparam.pubK,lastCursor));
            }
    /*yield put({
        type: sagaActions.PAYMENT_STREAM_INIT,
        lastCursor: lastCursor
    }
    );
    */

}  catch (e) {
    yield put(sendError(e));
} 
}

/**
 * 
 * Not being used
 * 
 */
export function* streamReturn(actionparam) {
    //const helperTranLog = (txResponse) => {
        const ef = yield actionparam.txResponse.effects();
        const accReturn = yield ef.records.filter(
                   (el) => el.account === actionparam.pubK
              )[0];
         if (accReturn != null) {
                accReturn.Memo =   actionparam.txResponse.memo;
               // console.log(accReturn);
                yield put(callbackPayment(accReturn,actionparam.pubK));
               }
} // End stream return
      
/***
 * Not being userd
 */
export function* createPaymentStream(actionparam) {
    const stellarServer = yield select(getStellerServer);
    const helperTranLog = (txResponse) => {
     //   console.log(txResponse);
        return put( { 
            type: sagaActions.PAYMENT_STREAM_LINE,
            txResponse :txResponse
        }
        )
    }
   
    try {
               yield put(callbackStartPayment());
               const trans = yield stellarServer.transactions()
                 .forAccount(actionparam.pubK)
                 .cursor(actionparam.lastCursor)
                // .limit(actionparam.limit)
                 .stream({
                     onmessage:  helperTranLog  // HELLO RAISE N NEW SAGA HERE 
                     }
                     )
        }      
        catch (e) {
            yield put(sendError(e));
    } 
}
/***
 *  Open Payments page and only allow payments to transfer*zac.org.za or transfer*test.zac.org.za
 *  This will search for the account and redirect to the page
 * 
 */
export function* initTrade(params) {
    try {
    yield put(getToZacNamePubK('transfer',params.domain));
    
  //  yield params.history.push('/pay')
    } catch (e) {
        yield put(sendError(e));
    }

}

/**
 * user not logged in but payment get initiated.
 * @param { 
 *      receiver,
 *      amount,
 *      comment   
 * } params 
 */
export function* initExternalPayment(sagaobject) {
    try {
        //find public key of receiver
        //console.log(sagaobject.params.receiver.split('*')[0]);
        //console.log(sagaobject.params.receiver.split('*')[1]);
        yield put( { type: actionTypes.SET_EXT_VALUES,
                amount : sagaobject.params.amount,
                comment : sagaobject.params.comment
            }
            );
        yield put(getToZacNamePubK(sagaobject.params.receiver.split('*')[0],sagaobject.params.receiver.split('*')[1]));
    } catch (e) {
        yield put(sendError(e));
    }
}

/**
 * 
 * @param { priv : XXX
 *      pub : XXX
 * } sagaObj 
 */
       const callSellarSdk = async (priv) => {
          return  await  StellarSdk.Keypair.fromSecret(priv);
        }

export function* verifyKP(sagaObj) {
    try {
    const kp = yield call(callSellarSdk,sagaObj.priv);
    if  (kp.publicKey()===sagaObj.pub) {
       yield put({
              type: actionTypes.LOGIN_PK,
              privatekey: sagaObj.priv
            });
    } else {
        yield put(sendTxtError("Private Key does not Validate")); 
    }
} catch(e) {
    yield put(sendTxtError("Error Occured: Private key does not validate"));
}
}