import React from 'react';
import { Avatar } from '@material-ui/core';
//import SnoozeIcon from '@material-ui/icons/Snooze';
//import stellaricon from '../styles/stellarSmall.svg';
//import { ZACCHAR } from '../utils/globalvar';
import CurrencyCard from '../components/CurrencyCard';
import { ZacIcon, Stellaricon ,USDImg} from '../pages/layout/Gobalicons';

const balanceDisplay = (props) => {
    const { classes } = props;
//    console.log(props)
   // const zac = ZACCHAR + 'AC';
    const picIcon = (assetcode) => {
        if (assetcode === 'ZAC') {
            return <Avatar src={ZacIcon} className={classes.large}>
                     </Avatar>
        } else {
            if (assetcode === 'USD') {
                return <Avatar src={USDImg} className={classes.large}>
                         </Avatar>
            } else {
            return <Avatar className={classes.large}
                        src={Stellaricon} >
                   </Avatar>   
            }
        }
    }

    return ( <>
        <CurrencyCard  avatar={picIcon(props.asset_code)} currenytitle = {props.asset_code} classes={classes} 
                balance={props.balance} asset_code={props.asset_code}  
                />
          </>
        );
    }
export default  balanceDisplay;