import React from 'react';
//import { makeStyles } from '@material-ui/core/styles';
import {Typography,Accordion,AccordionSummary,AccordionDetails} from '@material-ui/core';
import { ExpandMoreIcon } from '../pages/layout/Gobalicons';

export default function ZacAccordian(props) {
    return (<Accordion> 
        <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            >
            <Typography className={props.classes.accordianheading}>{props.heading}</Typography>
            </AccordionSummary>
            <AccordionDetails>
                <Typography>
                {props.body(props)}
                </Typography>
            </AccordionDetails>
                </Accordion>);
}