import React from 'react';
import { Paper,Grid,IconButton,Avatar,Typography } from '@material-ui/core';
import { styles } from '../layout/theme';
import { withStyles} from '@material-ui/core/styles'
import { LoginIcon ,NewAccountIcon, ZacCoinImg} from '../layout/Gobalicons';
import ContentHeader from '../../components/ContentHeader';
import {name as app_name, version as app_version}  from '../../../package.json';

const Info = React.memo(props => {
    const { classes } = props;
    return(

        <Paper elevation={3} className={classes.paper}>
        <ContentHeader 
        classes={classes} 
        headerdisplay="Welcome"
        />
                  <Grid container
            direction="column"
            justify="center"
            alignItems="center"
          >
              <Grid item >
              <IconButton color="inherit" size="small" onClick={() => props.history.push('/login')}>
                <Typography variant="subtitle2">
            Click the <LoginIcon /> to login.
            </Typography>
            </IconButton>
            </Grid>
            <Grid item >
            <IconButton color="inherit" size="small" onClick={() => props.history.push('/new')}>
            <Typography variant="subtitle2">
            
            Click on <NewAccountIcon /> to create a new account
            </Typography>
                  </IconButton>
                  
            </Grid>
            <Grid item >
              <br />
              <ZacCoinImg  size={150}/>
            </Grid>
            </Grid>
            <Grid container
            direction="column"
            justify="center"
            alignItems="center"
          >
            <Grid item >
            <Typography variant="caption">
              <br />
              <br />
              <br />

            Version : {app_version}
            </Typography>
            </Grid>
            <Grid item >
            <Typography variant="caption" align="left" >
              If your TEST account does not exits it because the test application has been reset on 28 Oct 2020. Please Recreate your test accounts.<br /> 
              Next reset date : 28 Jan 2021
              </Typography>
            </Grid>

                </Grid>
            </Paper>
    );
})

export default  withStyles(styles)(Info);