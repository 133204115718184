import React from 'react';
import {connect} from 'react-redux'
import { withStyles} from '@material-ui/core/styles'
import { green } from '@material-ui/core/colors';

import * as actionTypes from '../../Actions/types';
import {sendError,sendTxtError,getFundZacNamePubK }  from '../../Actions/thunkActions';
import {doRunFundAcc} from '../../Actions/stellarActions';
import { Redirect } from 'react-router-dom';
import { styles } from '../layout/theme';
import { Stepper,Step,StepLabel,Button,Typography, FormControl  } from '@material-ui/core';
import { InputAdornment,TextField,Card,CardContent} from '@material-ui/core/';
import { SingleCheckIcon, CrossIcon,TradeIcon} from '../layout/Gobalicons';


function getSteps() {
    return ['Select Account','Check Funds', 'Send Funds'];
  }

  const SearchZacAccount = (props) => {
//    if  (!props.hasFundAcc) {
    return   <Card className={props.classes.card} >
    <Typography variant="h6" className={props.classes.title} color="textSecondary" >
      Get account to fund
    </Typography>
    <CardContent>
    <FormControl>
    <TextField
    name="fundzacname"
    id="fundzacname"
    label="ZAC Account Name you wish to fund / activate"
    placeholder="ZAC Name of Account to Activate"
    className={props.classes.keyTextInput}
    helperText="The zac address of account to be activated / funded"
    margin="normal"
    error={props.toFundAccount === 'DOES_NOT_EXIST'}
    InputLabelProps={{
      shrink: true,
    }}
    value={props.fundzacname}
    onChange={props.handleChange('fundzacname')}
    InputProps={{
        endAdornment: (
         <>
         <InputAdornment position="end">
              {props.hasFundAcc? <SingleCheckIcon  style={{ color: green[500] }}  />
              : <CrossIcon/>
              }
            
          </InputAdornment>
          <InputAdornment position="end">
          {props.fundPubIsValid==="-1"? <SingleCheckIcon  style={{ color: green[500] }}  />
          : <CrossIcon/>
          }
         </InputAdornment>
         </>
         )
      }}
  />
    </FormControl>
  <br />
  <Button variant="contained" color="primary" onClick={() => { props.dofindToZacNamePubK(props.fundzacname,props.zacDomain); } }>Find {props.fundzacname}'s Public Key</Button>
  </CardContent></Card>;
  //  }
}

 const BalanceStep = (props) => {
    const NotEnoughFunds = () => <Typography variant="body1" component="span">
            You don't have enough Lumens to fund an account. Click on the TradeIcon <TradeIcon /> to Trade some ZAC to Lumens.
        </Typography>
    ;

   return (
    <><Card className={props.classes.card} >
    <Typography variant="h6" className={props.classes.title} color="textSecondary" >
      Enough Lumens to fund
    </Typography>
    <CardContent>
    <Typography variant="body1" component="span">
            You require 2 additional Lumens in you account. That means you need a balance of 4 or more lumens.
            <br />
            Your balance is {props.lumenBal}.{props.lumenBal>=4? 
                    <SingleCheckIcon  style={{ color: green[500] }} /> : <NotEnoughFunds />
           }
    </Typography>
</CardContent></Card>
      
    </>
   )
 };

 const FinalStep = (props) => {

  return ( <>
<Card className={props.classes.card} >
    <Typography variant="h6" className={props.classes.title} color="textSecondary" >
      Confirmation
    </Typography>
    <CardContent>
           {props.toFundAccount.length > 0 ? <>
            <Typography variant="body1" component="span">
            Do you want to fund account {props.fundzacname}.<br />
            It will cost you 2 Lumens.<br />
            After this transaction the receipient MUST LOG IN to confirm the account is valid.<br />
            The system will create a ZAC trustline for Him/Her then they will be able to receive ZAC.
            Click on Finish to complete the transaction.
            Or Cancel below to Cancel. 
            <br /></Typography>
           <Button 
           variant="contained"           
           color="primary"
           disabled={!props.fundButtEnable}
           onClick={props.handleReset} className={props.classes.button}>
                  Cancel
          </Button>
          <Button 
            variant="contained"           
            color="secondary"
            disabled={!props.fundButtEnable}
            onClick={() => { 
              //props.doRunFundAcc(props.publickey,props.privatekey,props.toFundAccount,props.toFundStellar.split("*")[0] + " funded by : " + props.zacName);
              props.doRunFundAcc(props.publickey,props.privatekey,props.toFundAccount,"Funded by:" + props.zacName);
              props.setFundButtEnable(false);
            }} className={props.classes.button}>
                   Fund Account
           </Button></>
           : <Typography variant="body1" component="span">Account Funded please ask user to login</Typography>
           }

            
    
</CardContent></Card>
    </>
  );
 }



const FundAccount = React.memo(props => {

    const [fundzacname, setFundzacname] = React.useState("");
    const [activeStep, setActiveStep] = React.useState(0);
    const [lumenBal , setLumenBal ] = React.useState(0);
    const [fundButtEnable , setFundButtEnable ] = React.useState(true);
    const [finalButtEnable , setFinalButtEnable] = React.useState(true);
    const [skipped, setSkipped] = React.useState(new Set());
    const steps = getSteps();
    const { classes } = props;

  const handleChange = field => event => {
    if (field === 'fundzacname') {
        setFundzacname(event.target.value);
    }
  }

    function getStepContent(step) {
        switch (step) {
          case 0:
            return <SearchZacAccount {...props}  fundzacname={fundzacname} handleChange={handleChange} />;
          case 1:
            return <BalanceStep {...props } lumenBal={lumenBal}/>;
          case 2:
           return <FinalStep {...props}  handleReset={handleReset} fundButtEnable={fundButtEnable} setFundButtEnable={setFundButtEnable} />
          default:
            return 'Unknown step';
        }
      }
    
    const isStepOptional = step => {
          return step === -1;
      };
      
    const isStepSkipped = step => {
          return skipped.has(step);
     };
      
    const handleNext = () => {
       let newSkipped = skipped;
          if (isStepSkipped(activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStep);
        }
        if (activeStep === 0) {
            if ((props.hasFundAcc) && (props.fundPubIsValid==="-1"))  { 
                const getLumenBalance = () => {
                    return props.balance.filter( b => b.asset_type ==='native')[0].balance
                }
                const locallumenBal = parseFloat(getLumenBalance());
                if (locallumenBal > 0) { setLumenBal(locallumenBal) } 
                setActiveStep(prevActiveStep => prevActiveStep + 1); 
            } 
                else {  
                    if (!props.hasFundAcc) {  props.doSendError({ message: "You need to choose a valid name and click on the Check button." }) ;
                      }
                    if (props.fundPubIsValid === "1") {
                      
                        props.doSendError({ message: "Seems like the account is already active. Please ask the account holder to login to verify" }) ;
                    }
                    if (props.fundPubIsValid === "0") {
                        props.doSendError({ message: "Please wait while we verify if the account is active" }) 
                    }
                }

          } else {
              if (activeStep===1) {
                        if (lumenBal >=4) { 
                          setActiveStep(prevActiveStep => prevActiveStep + 1);
                          setFinalButtEnable(false);
                        }
                 }
        }
         
        if (activeStep===2) {
          props.history.push('/tran');

        }

          setSkipped(newSkipped);
        };
      
        const handleBack = () => {
          setActiveStep(prevActiveStep => prevActiveStep - 1);
          setFinalButtEnable(false);
        };
      
        const handleSkip = () => {
          if (!isStepOptional(activeStep)) {
            // You probably want to guard against something like this,
            // it should never occur unless someone's actively trying to break something.
            throw new Error("You can't skip a step that isn't optional.");
          }
      
          setActiveStep(prevActiveStep => prevActiveStep + 1);
          setSkipped(prevSkipped => {
            const newSkipped = new Set(prevSkipped.values());
            newSkipped.add(activeStep);
            return newSkipped;
          });
        };
      
        const finalButtEnableFunc = () =>{
          
          if (props.toFundAccount.length === 0 )  {
            return true;
          } else  return finalButtEnable
        }


        const handleReset = () => {
          props.doClearFund();
          //setActiveStep(0);
          props.history.push('/wallet');
        };

     const checkLoggedIn = () => {
      return (!props.isLoggedIn ? <Redirect to="/wallet" /> : null );
   }
        return (
          <React.Fragment>
          {checkLoggedIn()}
          <div className={classes.steproot}>
            <Stepper activeStep={activeStep}>
              {steps.map((label, index) => {
                const stepProps = {};
                const labelProps = {};
                if (isStepOptional(index)) {
                  labelProps.optional = <Typography variant="caption">Optional</Typography>;
                }
                if (isStepSkipped(index)) {
                  stepProps.completed = false;
                }
                return (
                  <Step key={label} {...stepProps}>
                    <StepLabel {...labelProps}>{label}</StepLabel>
                  </Step>
                );
              })}
            </Stepper>
            <>
              {activeStep === steps.length ? (
                <>
                  <Button onClick={handleReset} className={classes.button}>
                    Reset
                  </Button>
                  </>
              ) : (
                <>
                {getStepContent(activeStep,props)}
                    <Button disabled={activeStep === 0} onClick={handleBack} className={classes.button}>
                      Back
                    </Button>
                    {isStepOptional(activeStep) && (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSkip}
                        className={classes.button}
                      >
                        Skip
                      </Button>
                    )}
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleNext}
                      className={classes.button}
                      disabled={!finalButtEnableFunc()}
                    >
                      {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                    </Button>
                    </>
              )}
            </>
          </div>
          </React.Fragment>
        );
    }
);

const mapStateToProps = (state) => {
    return {
        privatekey : state.accounts.privateKey,
        publickey : state.accounts.publicKey,
        zacNameMessage : state.accounts.zacNameMessage,
        zacNameAvail : state.accounts.zacNameAvail,
        zacDomain : state.accounts.zac_domain,
        zacName : state.accounts.zacName,
        isLoggedIn : state.accounts.isLoggedIn,
        toFundAccount : state.accounts.toFundAccount,
        toFundStellar : state.accounts.toFundStellar,
        hasFundAcc  : state.accounts.hasFundAcc,
        fundPubIsValid: state.accounts.fundPubIsValid,
        balance : state.accounts.balance,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        dofindToZacNamePubK: (zacName,zacDomain) => {
            dispatch(getFundZacNamePubK(zacName,zacDomain));
        },
        doClearFund: () => {
            dispatch({type : actionTypes.CLEAR_ZAC_FUND_NAME});
        },
        doChangePubK: (pubk) => {
                dispatch(
                  {
                    type: actionTypes.CHANGE_PUB_K,
                    ...pubk
                  });
            },
        doChangePrivK: (privk) => {
                dispatch(
                  {
                    type: actionTypes.CHANGE_PRIV_K,
                    ...privk
                  });
            },
        doSendError: (err)  => {
        console.log("Dispatch " + err.message);
          dispatch(sendError(err));
        },
        doSendErrorTxt: (err)  => {
            dispatch(sendTxtError(err));
          },
          doRunFundAcc: (fromPub,fromPriv,desPub,memo) => {
             dispatch(doRunFundAcc(fromPub,fromPriv,desPub,memo));
          },
    }
}



export default withStyles(styles)(connect(mapStateToProps,mapDispatchToProps)(FundAccount));