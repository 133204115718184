import React,{useState} from 'react';
import TextField from '@material-ui/core/TextField';
import { Button } from '@material-ui/core';
import {appmenustyles } from '../styles/AppMenuStyle';
import {Select, MenuItem, Input, Grid} from '@material-ui/core';
import ZacDialog from './ZacDialog';
import { PayIcon } from '../pages/layout/Gobalicons';

const Payment = (props) =>  {
   const [ diaOpen , setDiaOpen ] = useState(false);
   

/*   const dialogChange = () => {
        setDiaOpen(false);
   }
   */
   const confirmNo = () => {
      //console.log("No");
      setDiaOpen(false);

   }
   const confirmYes = () => {
     //console.log("yes");
     props.payOther(props.toPayAccount, props.payAmount, props.payMemo, props.payAsset, props.privK, props.pubK);
     setDiaOpen(false);
   }
   const makeMessage = () => {
    return "Pay "  +  props.payAmount  + " (" + props.payAsset + ") TO " + props.paytozacname + "? Comment : " + props.payMemo + ". Note: After Payment has gone through it is irreversable."
   }

   const toAccountForm = () => {
       if (props.hasToPayAcc) {
         return <React.Fragment>
           <Grid container
             direction="row"
             justify="center"
             alignItems="center"
           >
             <Grid item xs={10}>
               <Select
                 value={props.payAsset}
                 onChange={props.handleChange('payAsset')}
                 input={<Input name="asset" id="pay_asset" value={props.payAsset} />}
               >
                 <MenuItem value={'ZAC'}>ZAC</MenuItem>
                 <MenuItem value={'Lumens'}>Lumens</MenuItem>
               </Select>
             </Grid>
             <Grid item xs={10}>
               <TextField
                 name="payamount"
                 id="payamount"
                 label="Amount"
                 placeholder="Amount ZAC"
                 style={appmenustyles.textInput}
                 helperText={setErrorText("payamount")}
                 margin="normal"
                 error={false}
                 InputLabelProps={{
                   shrink: true,
                 }}
                 value={props.payAmount}
                 onChange={props.handleChange('payamount')}
               /></Grid>
             <Grid item xs={10}>
               <TextField
                 name="paymemo"
                 id="paymemo"
                 label="Memo/Comment"
                 placeholder="Comment to Recipient"
                 style={appmenustyles.textInput}
                 helperText={setErrorText("paymemo")}
                 margin="normal"
                 error={false}
                 InputLabelProps={{
                   shrink: true,
                 }}
                 value={props.payMemo}
                 onChange={props.handleChange('paymemo')}
               />
             </Grid><Grid item xs={10}>
              <ZacDialog open={diaOpen}  classes={props.classes}  
                message={makeMessage()}
                title="Payment Confirmation"  
                handleYes={confirmYes}
                handleNo={confirmNo}
                />
               <Button variant="contained" color="primary" onClick={() =>  {setDiaOpen(true) } } endIcon={<PayIcon />}  >PAY</Button>
               <Button variant="contained" color="secondary" onClick={() =>  {props.resetUserToPay() } } >Cancel</Button>
             </Grid>
           </Grid>
         </React.Fragment>;
       }
   }
   
   const searchZacAccount = () => {
        if  (!props.hasToPayAcc) {
        return   <React.Fragment><TextField
        name="paytozacname"
        id="paytozacname"
        label="Pay to ZAC Account Name"
        placeholder="ZAC Name of Receiver"
        style={appmenustyles.textInput}
        helperText={setErrorText("paytozacname")}
        margin="normal"
        error={props.toPayAccount === 'DOES_NOT_EXIST'}
        InputLabelProps={{
          shrink: true,
        }}
        value={props.paytozacname}
        onChange={props.handleChange('paytozacname')}
      /><br />
      <Button variant="contained" color="primary" onClick={() => { props.findToZacNamePubK(props.paytozacname,props.zacDomain); } }>Find {props.paytozacname}'s Public Key</Button>
      </React.Fragment>;
        }
   }

   const setErrorText = (fromField) => {

switch(fromField) {
    case "payamount" :
        if (props.payAsset ==='ZAC')  {
        return "ZAC amount to be paid";
        }
        else  {
          return "Amount to be paid";
        }
        
    case "paytozacname" :
          return "The zac address of user to be paid";
    case "paymemo":
        return "Description memo to the receiver to identify the payment";
    default: 
    if (props.hasToPayAcc === 'DOES_NOT_EXIST') {
        return "User not found";
   } else {
       return "Name / Email of Receiver";
   }
   }
}
   

const ret =  <React.Fragment>
        {searchZacAccount()}
        {toAccountForm()}
        </React.Fragment>;

    return ret;
};

export default Payment;
