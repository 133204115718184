import React,{useState} from 'react';
import { Button } from '@material-ui/core';
import {Select, MenuItem, Input, Grid,Typography} from '@material-ui/core';
import ZacDialog from './ZacDialog';
import { TradeIcon } from '../pages/layout/Gobalicons';

const Trade = (props) =>  {
   const [ diaOpen , setDiaOpen ] = useState(false);
   

/*   const dialogChange = () => {
        setDiaOpen(false);
   }
   */
   const confirmNo = () => {
      //console.log("No");
      setDiaOpen(false);

   }
   const confirmYes = () => {
     //console.log("yes");
//     console.log(props);
     
     props.payOther(props.toPayAccount, props.payAmount, props.payMemo, props.payAsset, props.privK, props.pubK);
     setDiaOpen(false);
   }
   const makeMessage = () => {
     const lumAmm = parseFloat(props.payAmount) / 10;
     return "Do you want to Trade " +  props.payAmount  + " (ZAC) FOR " + String(lumAmm) + " (Lumens)? Note: After Payment has gone through it is irreversable.";
   }

   const toAccountForm = () => {
       if (props.hasToPayAcc) {
         return <React.Fragment>
           <Grid container
             direction="row"
             justify="center"
             alignItems="center"
             spacing={2}
           >
             <Grid item xs={10} >
             <Select
                 value={props.payAmount}
                 onChange={props.handleChange('payamount')}
                 input={<Input name="amount" id="pay_amount" value={props.payAmount} 
                 />}
               >
                 <MenuItem value={'20'}>20</MenuItem>
                 <MenuItem value={'30'}>30</MenuItem>
                 <MenuItem value={'40'}>40</MenuItem>
                 <MenuItem value={'50'}>50</MenuItem>
                 <MenuItem value={'60'}>60</MenuItem>
                 <MenuItem value={'100'}>100</MenuItem>
               </Select>
               ZAC
</Grid>
<Grid item xs={10}>
  <Typography variant="body1" component="span">
        You will get : {parseFloat(props.payAmount) / 10} lumens
  </Typography>

</Grid>

<Grid item xs={10}>
              <ZacDialog open={diaOpen}  classes={props.classes}  
                message={makeMessage()}
                title="Trade / Exchange Confirmation"  
                handleYes={confirmYes}
                handleNo={confirmNo}
                />
               <Button variant="contained" color="primary" onClick={() =>  {setDiaOpen(true) } } endIcon={<TradeIcon />}  >Trade</Button>
               <Button variant="contained" color="secondary" onClick={() =>  {props.history.push('wallet') } } >Cancel</Button>
             </Grid>
           </Grid>
         </React.Fragment>;
       }
   }
   
   const searchZacAccount = () => {
        if  (!props.hasToPayAcc) {
        return   <React.Fragment>
                      <Typography variant="body1" component="span">
                        Please wait..
                        </Typography>
      
      </React.Fragment>;
        }
   }

const ret =  <React.Fragment>
        {searchZacAccount()}
        {toAccountForm()}
        </React.Fragment>;

    return ret;
};

export default Trade;
