import React from 'react';
import {  AppBar,IconButton,Grid, Typography,Tooltip} from '@material-ui/core/';
//import PropTypes from 'prop-types';


const ContentHeader = (props) => {
    const { classes } = props;
    
    return <AppBar className={classes.searchBar} position="static" color="default" elevation={1}>
      
    <Grid container spacing={1}   alignItems="center"  direction="row"   justify="center">
      <Grid item  xs={10}>
         <Typography variant="subtitle1" align="center">{props.headerdisplay} </Typography>
      </Grid>
      <Grid item  xs={2}>
          {props.has_tp1 ?
            <Tooltip title={props.tp1} aria-label={props.tp1}>
              <IconButton
                color="inherit"
                aria-label="ShowKeys"
                onClick={props.icon1click}
                className={classes.menuButton}
                size="small"
              >
                {props.icon1}
              </IconButton>
              </Tooltip>
              : null }
              {props.has_tp2 ?
              <Tooltip title={props.tp2} aria-label={props.tp2}>
              <IconButton
                color="inherit"
                aria-label="Transactions"
                onClick={props.icon2click}
                className={classes.menuButton}
                size="small"
              >
              {props.icon2}
              </IconButton>
              </Tooltip>
              : null }
              {props.has_tp3 ?
              <Tooltip title={props.tp3} aria-label={props.tp3}>
              <IconButton
                color="inherit"
                aria-label="Payments"
                onClick={props.icon3click}
                className={classes.menuButton}
                size="small"
              >
              {props.icon3}
              </IconButton>
              </Tooltip>
              : null }
        </Grid>
    </Grid>
  </AppBar>

}

/*
ContentHeader.propTypes = {
  classes: PropTypes.object.isRequired,
}

*/
export default ContentHeader;