import React from 'react';
import {Typography} from '@material-ui/core';
//import { ExpandMoreIcon } from '../pages/layout/Gobalicons';
//import ZacAccordian from '../components/ZacAccordion';

const BankDetails = (props) => {
       return <Typography variant="body1" component="span">
                            FNB <br/>
                                  Savings Account<br/>
                                  No : 62760031832<br/>
                                  For : StepIT cc<br/>
                                  Ref : {props.depositref} <br />
                                  Branch code: <br />
                                  Notification Mail : deposit@zac.org.za<br/>
                                <b>Please note it might be 24Hours before the sale to reflect on your account.</b>
                </Typography>
}

export default BankDetails;