import * as ActionTypes from './types/usertypes';
import axios from 'axios';
import { FEDHOST,sendError,sendTxtError } from './thunkActions';
import {userValidate} from './Validations/UserValidation';

export const handleUserFieldChange = (fieldChanged,newValue)  => {
    return ({ type : ActionTypes.USER_FIELD_CHANGE,
            fieldChanged : fieldChanged,
            newValue : newValue 
        });
}


const getUserData = (getState,isValidate) => {
  const usersState = getState().users;
  const accountsState = getState().accounts;
  let fullPhone =  usersState.country_prefix + usersState.phone
  if (isValidate) {
    fullPhone =  usersState.phone
  }
  const userData = {
      pub : accountsState.publicKey,
      domain : accountsState.zac_domain,
      email: usersState.email,
      firstname: usersState.firstname,
      surname: usersState.surname,
      rsa_id: usersState.rsa_id,
      passport_no: usersState.passport_no,
      country_id: usersState.country_id,
      address1: usersState.address1,
      suburb: usersState.suburb,
      city: usersState.city,
      code: usersState.code,
      phone: fullPhone
    //  err_obj: usersState.err_obj,
  }
  return userData;

}

export const validateLvlOne = () => {
  return (dispatch, getState) => {
    const userData = getUserData(getState,true);
    const errors = userValidate.validate(userData);
    //console.log(errors);
    dispatch({
      type: ActionTypes.ADD_LVL_ONE_VALIDATION_RET,
      err_arr: errors
    });
  }
}

export const getPhoneNo = (pub, domain) => {
  return (dispatch,getState) => {
    axios.post(FEDHOST+'/cell',{
      'pub' : pub,
      'domain' : domain
    }
    )
    .then( 
      (response) =>{
          dispatch({type : ActionTypes.GET_PHONE_RET,
            data : response.data
          })
  })
  .catch( (e) => {
    if (e.response.data.message !== undefined) {
      dispatch(sendTxtError(e.response.data.message));
    } else {
      dispatch(sendError(e));
    }
  });
}
}

export const saveLvlOne = () =>{
    // Axios call to save
    return (dispatch,getState) => {
        const userData = getUserData(getState,false);
        axios.post(FEDHOST+'/lvlone',
            userData
           )
        .then( 
            (response) =>{
                dispatch({type : ActionTypes.ADD_LVL_ONE_RET,
                  data : response.data
                })
        })
        .catch( (e) => {
        dispatch(sendError(e));
        console.log("Error from LVL One ADD: " + e);  
          }  );
        }
}