import React, {Component } from 'react';
import {connect} from 'react-redux';
import { withStyles} from '@material-ui/core/styles'
import ContentHeader from '../../components/ContentHeader';
import { Paper } from '@material-ui/core';
import { styles } from '../layout/theme';
import { getToZacNamePubK } from '../../Actions/thunkActions';
import { doPayment,getPaymentArray }  from '../../Actions/stellarActions';
import * as ActionTypes from '../../Actions/types';
import Trade from '../../components/Trade';
//import ReceiptIcon from '@material-ui/icons/Receipt';
import { TransactionsIcon,BalanceIcon } from '../layout/Gobalicons';
import { Redirect } from 'react-router-dom';



//const TradePage = React.memo(props => {

class TradePage extends Component {
    constructor(props) {
        super(props);
        this.state = {  
            payamount : "20",
            memo : ""
        };
    }
/*    
    [paytozacname, paytozacnameChange] = useState("");
    [payamount, payamountChange] = useState("");
    [paymemo, paymemoChange] = useState("");
    [payAsset, payAssetChange] = useState("ZAC");

  */  
    componentDidMount() {
        this.props.dofindToZacNamePubK('transfer',this.props.zacDomain);
        const d = new Date();
        const curTimeShort = d.getDate() + d.toLocaleString('default', { month: 'short' }) + d.getHours()+":"+ d.getMinutes() ;
        const makeMemo  = 'TRADE:' + this.props.zacName + " " + curTimeShort;
        this.setState({memo : makeMemo });
    }

    componentWillUnmount() {
        this.props.clearUserToPay();
    }
 
  locDoPay= (otherPK,amount,memo,payAsset,privK,pubK) => {

        this.props.payOther(otherPK,amount,this.state.memo,'ZAC',privK,pubK);
        this.props.goGetTrans(pubK);
        this.props.history.push('/tran');

    }


    handleChange = field => event => {
/*        if (field === 'paytozacname') {
            paytozacnameChange(event.target.value);
        }*/
        /*
        if (field === 'payamount') {
            //payamountChange(event.target.value);
            this.setState({ payamount: event.target.value});
        }
        if (field === 'paymemo') {
            if (event.target.value.length <=28) {
             // paymemoChange(event.target.value);
              this.setState({ paymemo: event.target.value});
            }
        }
        */
        this.setState({ [field]: event.target.value });
      };

      checkLoggedIn = () => {
      return (!this.props.isLoggedIn ? 
      <Redirect to="/login" /> 
      : null
      );
    }
    render() {
        return (<>
        {this.checkLoggedIn()}
        <Paper elevation={3} className={this.props.classes.paper}>
        <ContentHeader 
        classes={this.props.classes} 
        headerdisplay="Buy Lumens" 
        has_tp2={true}
        tp2={"Wallet"}
        icon2={<BalanceIcon />}
        icon2click={() => {
            this.props.history.push('/wallet');
        }}
        has_tp3={true}
        tp3={"Transactions"}
        icon3={<TransactionsIcon/>}
        icon3click={() => {
               // props.goGetTrans(props.publickey);
               this.props.history.push('/tran');
            }}

        />
        <Trade 
            paytozacname="transfer"
            handleChange={this.handleChange} 
            findToZacNamePubK={this.props.dofindToZacNamePubK}  
            zacDomain={this.props.zacDomain} 
            payAsset='ZAC'
            toPayAccount={this.props.toPayAccount}
            hasToPayAcc={this.props.hasToPayAcc} 
            payOther={this.locDoPay} 
            payMemo={this.state.paymemo} 
            payAmount={this.state.payamount} 
            privK={this.props.privatekey} 
            pubK={this.props.publickey}  
            resetUserToPay={this.props.resetUserToPay}
            classes={this.props.classes} 
            isTrade={this.props.isTrade}
            history={this.props.history} />
    </Paper>
        </>
    );
    }
}
    


const mapStateToProps = (state) => {
    return {
        transactions : state.accounts.transactions,
        privatekey : state.accounts.privateKey,
        publickey : state.accounts.publicKey,
        zacNameMessage : state.accounts.zacNameMessage,
        zacNameAvail : state.accounts.zacNameAvail,
        zacDomain : state.accounts.zac_domain,
        zacName : state.accounts.zacName,
        newZacName : state.accounts.newZacName,
        isLoggedIn : state.accounts.isLoggedIn,
        toPayAccount : state.accounts.toPayAccount,
        hasToPayAcc : state.accounts.hasToPayAcc,
        isTrade : state.accounts.isTrade,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        dofindToZacNamePubK: (zacName,zacDomain) => {
            dispatch(getToZacNamePubK(zacName,zacDomain));
        },
        clearUserToPay: () =>
            dispatch(() => dispatch({type : ActionTypes.CLEAR_ZAC_TO_PAY_NAME}))
        ,
        payOther: (otherPK,amount,memo,payAsset,privK,pubK) => {
            dispatch(doPayment(otherPK,amount,memo,payAsset,privK,pubK));

        },
        goGetTrans: (pubK) => {
           // dispatch({ type: actionTypes.CLEAR_PAY_REC });
            dispatch(getPaymentArray(pubK));
            //dispatch(getAllTransactions(pubK));
          },

   }
}

export default withStyles(styles)(connect(mapStateToProps,mapDispatchToProps)(TradePage));