import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { Paper, Grid, Button } from '@material-ui/core/';
//import { styles } from './theme';
import { styles } from '../../pages/layout/theme';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles'
import { getBalAct, getPaymentArray, doZacTrust, doOldZacTrust } from '../../Actions/stellarActions';
import AllBalances from '../../components/AllBalances';
import ContentHeader from '../../components/ContentHeader';
//import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import FundTestAccount from '../../components/FundTestAccount';
import { fundTestAccount } from '../../Actions/thunkActions';
import { KeysIcon, TransactionsIcon, PaymentIcon } from '../layout/Gobalicons';
import AccountNotActive from '../../components/AccountNotActive';
import LvlOneImg from './lvl1.png';
import LvlTwoImg from './lvl2.png';
//import { repeat } from 'rxjs-compat/operator/repeat';
//import MediaCard from '../../components/MediaCard';
/*
body {
  background-image: url("bg.png");
  background-position: right bottom; 
  background-repeat: no-repeat; 
}
*/
const locStyles = {
  lvlOne: {
    backgroundImage: `url(${LvlOneImg})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "right bottom"

  },
  lvlTwo: {
    backgroundImage: `url(${LvlTwoImg})`,
    backgroundRepeat: "no-repeat",
    backgroundPosition: "right bottom"

  }
};

class Wallets extends Component {

  constructor(props) {
    super(props);

    this.state = {
      showHelp: false,
      showTrans: false,
      isTest: true
    }

    this.changeTest = this.changeTest.bind(this);

  }

  changeTest() {
    //console.log("Run ChangeTest");
    this.setState(prevState => ({
      isTest: !prevState.isTest
    }));
  }

  componentDidMount() {
    if (this.props.publickey) {
    if (this.props.publickey.length > 0) {
      //this.props.doCheckAccount(this.props.publickey);
      this.props.doGetBalance(this.props.publickey, this.props.privatekey);
    }
  }
  }

  showWallets() {
    //console.log("Show Wallets");
    //console.log(this.props.classes);
    //<FundTestAccount isTest={this.state.isTest} changeTest={this.changeTest}  publickey={this.props.publickey} doAccountFund={this.props.doAccountFund} />
    if (this.props.pubIsValid === "-1") {
      return <React.Fragment>
          <AccountNotActive {...this.props} depositref={this.props.publickey.substring(1, 11)} />

        {this.state.isTest ? <Paper>
          <FundTestAccount isTest={this.state.isTest} changeTest={this.changeTest} publickey={this.props.publickey} doAccountFund={this.props.doAccountFund} />
        </Paper> : ""}
      </React.Fragment>

    } else {
      if (this.props.has_balance) {
        //         console.log("Above Allbal");
        //         console.log(this.props);

        return <React.Fragment>

          <AllBalances
            balances={this.props.balance}
            has_balance={this.props.has_balance}
            publickey={this.props.publickey}
            privatekey={this.props.privatekey}
            changeTrust={this.props.doOldTrustZac}
            classes={this.props.classes} />
        </React.Fragment>
      } else {
        return <React.Fragment>
          <Paper>
            Loading Balances.....
               </Paper>
        </React.Fragment>
      }
    }
  }
  render() {
    const getLvlStyle = () => {
      if (this.props.acc_level === 1) {
        return locStyles.lvlOne;
      }
      if (this.props.acc_level === 2) {
        return locStyles.lvlTwo;
      }

    }

    const checkLoggedIn = () => {
      return (!this.props.isLoggedIn ? <Redirect to="/login" /> : null);
    }
    const { classes } = this.props;
    return (
      <>
        {checkLoggedIn()}
          {this.props.pubIsValid !== "-1" ?
            <ContentHeader classes={classes} headerdisplay="Wallet"
              has_tp1={true}
              tp1="Keys"
              icon1={<KeysIcon />}
              icon1click={
                event => {
                  this.props.history.push('/showkeys');
                }}
              tp2="Transactions"
              has_tp2={true}
              icon2={<TransactionsIcon />}
              icon2click={() => {
                this.props.goGetTrans(this.props.publickey);
                this.props.history.push('/tran');
              }}
              tp3="Payment"
              has_tp3={true}
              icon3={<PaymentIcon />}
              icon3click={() => {
                this.props.history.push('/pay');
              }}

            />
            :
            <ContentHeader classes={classes} headerdisplay="Wallet"
              has_tp1={true}
              tp1="Keys"
              icon1={<KeysIcon />}
              icon1click={
                event => {
                  this.props.history.push('/showkeys');
                }}
            />}
        <Paper elevation={3} className={classes.paper}>
          <Grid container direction="column"
            justify="center"
            alignItems="center">
            {this.showWallets()}
          </Grid>
        </Paper>


      </>
    );
  }
}

// Style the Grid with style={locStyles.lvlOne}
/* Mediacard
          <Grid container direction='row'>
            <Grid item>
            <MediaCard image={LvlOneImg} title="Account Level" level={this.props.acc_level} />

            </Grid>
            </Grid>

            */

const mapStateToProps = (state) => {
  return {
    isLoggedIn: state.accounts.isLoggedIn,
    pubIsValid: state.accounts.pubIsValid,
    privatekey: state.accounts.privateKey,
    publickey: state.accounts.publicKey,
    zacDomain: state.accounts.zac_domain,
    zacName: state.accounts.zacName,
    balance: state.accounts.balance,
    acc_level: state.accounts.acc_level,
    has_balance: state.accounts.has_balance,
    paytozacname: state.accounts.paytozacname,
    toPayAccount: state.accounts.toPayAccount,
    hasToPayAcc: state.accounts.hasToPayAcc,
    transactions: state.accounts.transactions
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    doGetBalance: (pubK, privK) => {
      //  console.log("Get Balance:" + pubK);
      dispatch(getBalAct(pubK, privK));
    },
    goGetTrans: (pubK) => {
      // dispatch({ type: actionTypes.CLEAR_PAY_REC });
      dispatch(getPaymentArray(pubK));
    },
    doAccountFund: (pubK) => {
      dispatch(fundTestAccount(pubK));
    },
    doTrustZac: (pubK, privK) => {
      dispatch(doZacTrust(pubK, privK));
    },
    doOldTrustZac: (pubK, privK) => {
      dispatch(doOldZacTrust(pubK, privK));
    }


  }
}


export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(Wallets));
