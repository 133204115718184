import React from 'react';
import { Grid, TextField,Typography } from '@material-ui/core/';


const ShowKeys = (props) => {
    
    const { classes } = props;
    return (
        <React.Fragment>
        <Grid item>
          {!props.print?  
        <TextField
          name="PublicKey"
          id="zacname"
          label="Public Key"
          value={props.publickey}
          className={classes.keyTextInput}
          variant="outlined"
        />
        : <><Typography variant="body1">Public Key:</Typography>
          <Typography variant="body1">{props.publickey}</Typography></>
        
        }
      </Grid>
      {props.privatekey &&
          
        <Grid item>
      {!props.print? 
          <TextField
            name="PrivateKey"
            id="zacname"
            label="Private Key"
            value={props.privatekey}
            className={classes.keyTextInput}
            variant="outlined"
          />
          : <><Typography variant="body1">Private Key:</Typography>
          <Typography variant="body1">{props.privatekey}</Typography></> }
        </Grid>
      }
      <Grid item>
      {!props.print? 
        <TextField
          name="domain"
          id="domain"
          label="Domain"
          value={props.zacDomain}
          style={{ width: 250 }}
          variant="outlined"
        />
        :  <Typography variant="body1">{props.zacDomain}</Typography>}
      </Grid>
      </React.Fragment>
    );
}

export default ShowKeys;