import React, {Component } from 'react';
import { Button, Grid } from '@material-ui/core';
import {connect} from 'react-redux';
import * as actionTypes from '../Actions/types';
import * as sagaTypes from '../Actions/types/sagatypes';

import TextField from '@material-ui/core/TextField';
import { checkfed,getPriv,getSimplePriv }  from '../Actions/thunkActions';
import {Paper,Typography, Select, MenuItem,Input,InputLabel,FormControl,InputAdornment,IconButton} from '@material-ui/core/';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { withStyles } from '@material-ui/core/styles';
//import classNames from 'classnames';
import {appmenustyles } from '../styles/AppMenuStyle';
//import MySnackbarContentWrapper from '../components/MySnackBarContentWrapper';
//import Snackbar from '@material-ui/core/Snackbar';
import { Redirect } from 'react-router-dom';
import { styles } from '../pages/layout/theme';
import ErrorAlert from '../components/ErrorAlert';
//import keyLogo from '../styles/key.jpg';
import ShowKeys from '../components/ShowKeys';
import { getKey } from '../utils/myCrypt';


class Login extends Component {
    constructor() {
        super();
        this.findPubKey = this.findPubKey.bind(this);
        this.handlezacDomain = this.handlezacDomain.bind(this);
        this.renderUser = this.renderUser.bind(this);
        this.getPrivButt = this.getPrivButt.bind(this);
        this.getSimplePriv = this.getSimplePriv.bind(this);
        this.doLogin = this.doLogin.bind(this);
        this.handleClickShowPassword = this.handleClickShowPassword.bind(this);
        this.state = {
            searchzacname : "",
            showPassword: false,
            password: "",
            openSnack: false,
            enteredPriv : "",
            userNotFoundError : false
//            showErrorSnack: false
        }
        
    }

    componentDidMount() {
      this.props.doSetDomain('zac.org.za');
    }

    componentDidUpdate(prevProps, prevState) {
      if (prevProps.zacNameAvail !== this.props.zacNameAvail) {
        this.setState({ userNotFoundError :(this.props.error_msg === "User does not exist.") })
      }
    } 
    
    showSnack = () => {
      this.setState(prevState => ({
        openSnack: !prevState.openSnack
      }));
    };
  
    closeSnack = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
      this.setState( { openSnack : false } );
    };
 

    handleChange = prop => event => {
        let newState = { [prop]: event.target.value };
        console.log(newState);
        if (prop === 'password') { newState.enteredPriv = ""; } // Clear priv if password entered
        if (prop === 'enteredPriv') { newState.password = ""; } // Clear password if priv entered
        console.log(newState);
        this.setState(newState);
      };
/*
    setErrorSnack = (val) => {
        this.setState({showErrorSnack : val});
    }  
*/
      handlezacDomain(e) {
        //console.log("Set Domain : " + e.target.value);
        this.props.doSetDomain(e.target.value);
    };


    findPubKey() {
      //  console.log("Search ZAC NAme :" + this.state.searchzacname);
        this.props.checkZacName(this.state.searchzacname,this.props.zacDomain);

        
    }
    getSimplePriv() {
      const key = getKey(this.state.password,this.props.publickey);
      this.props.doGetSimplePriv({
        "key": key,
        "pub": this.props.publickey,
        "pass": this.state.password
     });
    }

    getPrivButt() {
        this.props.doGetPriv({
           "pub": this.props.publickey,
           "zacname": this.props.zac_name,
           pass: this.state.password
        });
      }

    doLogin() {
      //console.log("login");
      //console.log(this.state);
      if (this.state.password)  {
        this.getSimplePriv();
      } 
      else {
        if (this.state.enteredPriv) {
          //TODO Add verify Function.
          this.props.doLoginWithPrivate(this.props.publickey,this.state.enteredPriv);
        }
      }
    }  

    handleClickShowPassword = () => {
        this.setState(state => ({ showPassword: !state.showPassword }));
    };
    checkRedirect() {
      if (this.props.isLoggedIn) {
        if (this.props.hasToPayAcc) {
          return <Redirect to="/extpay" />
        }  else {
            return <Redirect to="/wallet" /> 
        } 
      } else return null;
    }

  
    renderUser(classes,placeholderText) {
        if (this.props.publickey==="") {
        return (
          <React.Fragment >
            <Paper elevation={3} className={classes.paper}   >
              <Grid container
                direction="column"
                justify="center"
                alignItems="center"><Grid item>
                  <TextField
                    name="zacname"
                    id="zacname"
                    label="ZAC Account Name"
                    placeholder={placeholderText}
                    style={appmenustyles.textInput}
                    helperText="Name / Email you registered with"
                    margin="normal"
                    error={this.state.userNotFoundError}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={this.props.newzacname}
                    onChange={this.handleChange('searchzacname')}
                  />
                </Grid>
                <Grid item>
                  <Select
                    value={this.props.zacDomain}
                    onChange={this.handlezacDomain}
                    input={<Input name="domain" id="zac-domain" value={this.props.zacDomain} />}
                  >
                    <MenuItem value={'zac.org.za'}>zac.org.za</MenuItem>
                    <MenuItem value={'test.zac.org.za'}>test.zac.org.za</MenuItem>
                  </Select>
                </Grid>
                <Grid item>
                  <Button className={classes.button} variant="contained" color="primary" size="small" onClick={this.findPubKey}>Find Public Key</Button>
                </Grid>
              </Grid>
            </Paper>
          </React.Fragment>
        );
    } else {
           return (
             <Paper elevation={3} className={classes.paper}>
               <Grid container
                 direction="column"
                 justify="center"
                 alignItems="center"
               >
                <ShowKeys 
                   publickey={this.props.publickey}
                   classes={classes}
                   privatekey={this.props.privatekey}
                   zacDomain={this.props.zacDomain}
                />
                 <Grid item>
                   <FormControl className={[classes.margin,classes.textField].join(" ")}>
                     <InputLabel htmlFor="adornment-password">Password</InputLabel>
                     <Input
                       id="adornment-password"
                       type={this.state.showPassword ? 'text' : 'password'}
                       value={this.state.password}
                       onChange={this.handleChange('password')}
                       endAdornment={
                         <InputAdornment position="end">
                           <IconButton
                             aria-label="Toggle password visibility"
                             onClick={this.handleClickShowPassword}
                           >
                             {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                           </IconButton>
                         </InputAdornment>
                       }
                     />
                   </FormControl>
                  </Grid
                  ><Grid item>
                     <Typography variant="body1" component="span">
                       OR
                     </Typography>
                     </Grid><Grid item>
                        <TextField
                          type='text'
                          name="PrivateKey"
                          id="adornment-privatekey"
                          label="Private Key"
                          value={this.state.enteredPriv}
                          className={classes.keyTextInput}
                          onChange={this.handleChange('enteredPriv')}
                          variant="outlined"
                        />
                 </Grid>
                 <Grid item>
                   <Button className={classes.button} variant="contained" color="primary" size="small" onClick={this.doLogin}>Login</Button>
                 </Grid>
               </Grid>
             </Paper>
        );
    }
    };   

    render() {
    const { classes } = this.props;
    const placeholderText = "ZACAccount*" + this.props.zacDomain + " ie (myname@gmail.com*zac.org.za)";

/*
OLD LOGIN BUTT
                 <Grid item>
                   <Button className={classes.button} variant="contained" color="primary" size="small" onClick={this.getPrivButt}>OLD Login</Button>
                 </Grid>

*/

/* Removed this snackbar now hadled by error allert                               



           {this.props.error && 
            <Snackbar
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left"
            }}
            open={this.props.error}
            autoHideDuration={6000}
          >
           <MySnackbarContentWrapper
           variant="error"
           className={classes.margin}
           message={"Error :" + this.props.error_msg}
         />
         </Snackbar>
        } 
*/



    //let  redirect = null;
//    if (this.props.isLoggedIn) { redirect = <p>Time to redirect</p> }

    return (
        <React.Fragment>
        {this.checkRedirect()}
        {this.renderUser(classes,placeholderText)}
          <ErrorAlert />
        </React.Fragment>

    )
    }
}


const mapStateToProps = (state) => {
    return {
        isLoggedIn : state.accounts.isLoggedIn,  
        privatekey : state.accounts.privateKey,
        publickey : state.accounts.publicKey,
        zacNameMessage : state.accounts.zacNameMessage,
        zacNameAvail : state.accounts.zacNameAvail,
        zacDomain : state.accounts.zac_domain,
        zacName: state.accounts.zacName,
        hasToPayAcc : state.accounts.hasToPayAcc,
        error : state.accounts.error,
        error_msg: state.accounts.error_msg,

    }
}


const mapDispatchToProps = (dispatch) => {
    return {
        checkZacName: (newZacName,domain) => {
           // console.log("Name: " + newZacName)
            if (newZacName !== '') {
            dispatch(checkfed(newZacName,domain,true))
            }
          },
          doSetDomain: (newDomain) => {
            dispatch(actionTypes.setDomain(newDomain) );
        }, 
         doGetPriv: (pubK) => {
            dispatch(getPriv(pubK));
         },
         doGetSimplePriv: (theKey) => {
           dispatch(getSimplePriv(theKey));
         },
         doLoginWithPrivate: ( pub,pk ) => {
           dispatch({ 
              type: sagaTypes.VERIFY_PRIV_KEY,
              pub: pub,
              priv: pk
          });
         }


   }
}
 

export default withStyles(styles)(connect(mapStateToProps,mapDispatchToProps)(Login));

