import { pink, green, deepPurple } from '@material-ui/core/colors';

const drawerWidth = 256;

//export const styles = theme => console.log(theme) || ({
export const styles = theme =>  ({
    root: {
        maxWidth: 936,  
        padding: theme.spacing(2),
    },
    drawer: {
      [theme.breakpoints.up('sm')]: {
        width: drawerWidth,
        flexShrink: 0,
      },
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
    },
    paper: {
      maxWidth: 936,
      margin: 'auto',
      overflow: 'hidden',
      padding: theme.spacing(2),
    },
    searchBar: {
      borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    },
    searchInput: {
      fontSize: theme.typography.fontSize,
    },
    block: {
      display: 'block',
    },
    addUser: {
      marginRight: theme.spacing(1),
    },
    contentWrapper: {
      margin: '40px 16px',
    },
    table: {
      minWidth: 650,
    },
    select: {
        minWidth: 200,
    },
    textField: {
        flexBasis: 200,
        minWidth: 650,
      },
      purple: {
        color: theme.palette.getContrastText(deepPurple[500]),
        backgroundColor: deepPurple[500],
      },
      pink: {
        color: theme.palette.getContrastText(pink[500]),
        backgroundColor: pink[500],
      },
      green: {
        color: '#fff',
        backgroundColor: green[500],
      },
      paperWallet: {
        display: 'flex',
        flexWrap: 'wrap',
        '& > *': {
          margin: theme.spacing(10),
          hight: theme.spacing(36),
          width: theme.spacing(16),
          padding: '20px',
        },
      },
        currcard: {
          padding: theme.spacing(2),
          margin: theme.spacing(2),
          raised : true
        },
        button: {
          padding: theme.spacing(1),
          margin: theme.spacing(1),
        },
        firebase: {
          fontSize: 24,
          color: theme.palette.common.white,
        },
      
  });
