import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import {Provider} from 'react-redux';
import { createStore, applyMiddleware } from 'redux';
//import { compose } from  'redux';
import thunk from 'redux-thunk';
import  rootReducer  from './reducers/rootReducer';
import createSagaMiddleware from "redux-saga";
import { stellarActions } from './reducers/sagas/mainrunsaga';
//import { unregister } from './registerServiceWorker';
//import registerServiceWorker from './registerServiceWorker';

//import stelMiddle from './Actions/Middleware/stellarStreamerMiddleware';
//import watch from './reducers/watchers/watcher';

//const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const sagaMiddleware = createSagaMiddleware();

const store = createStore(
    rootReducer,
    //composeEnhancers(
    applyMiddleware(thunk,sagaMiddleware),
    //)
);

sagaMiddleware.run(stellarActions);

//--------------------watch.monitor_store_changes();


//unregister(); --ATTEMPT TO FIX CACHE DONT USE
ReactDOM.render(<Provider store={store}><App /></Provider>, document.getElementById('root'));
//registerServiceWorker();
