import React from 'react';
import {Paper, Grid, Button} from '@material-ui/core';
import { styles } from './content/theme';
import { withStyles } from '@material-ui/core/styles';
import  {useParams } from "react-router-dom";
import {connect} from 'react-redux';
//import { Redirect } from 'react-router-dom';
//import * as SagaActions from '../../Actions/types/sagatypes';
import {externalPayInitAction } from '../Actions/stellarActions';
//import {useLocation } from 'react-router-dom';

const ReceivePay = React.memo(props => {
    
    const { classes } = props;
    
    let { 
        receiver,amount,comment
    } = useParams();
    /*
    const receiver  ='ONE';
    const amount  ='TWO';
    const comment  ='TREE';
*/
//receiver = receiver.replace('%23','#');
    const handleClick = () => {
        props.doExternalInit({
            receiver :receiver,
            amount :amount,
            comment :comment
    })
        props.history.push('/login');
    }

    return(
            <>
            <Paper elevation={3} className={classes.paper}   >
              <Grid container
                direction="column"
                justify="center"
                alignItems="center">
                    <Grid item>
                Pay to: {receiver}<br/>
                Amount: ZAC {amount}<br/>
                Comment: {comment}
                </Grid>
                <Grid item>
                <Button variant="contained" color="primary"  onClick={handleClick} >Login and pay</Button>
                    </Grid>
              </Grid>
            </Paper>
            </>
        );
});

/*
               <Grid item>
                  <p>QUERIES</p>
                  <p>
                      {useLocation().search}
                  </p>
              </Grid>

 */

const mapStateToProps = (state) => {
    return {
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        doExternalInit:  ( params ) => dispatch(externalPayInitAction(params)),
    }
    
}

export default withStyles(styles)(connect(mapStateToProps,mapDispatchToProps)(ReceivePay));