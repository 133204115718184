import * as actionTypes from '../Actions/types';
import * as actionUserTypes from '../Actions/types/usertypes';
import StellarSdk from 'stellar-sdk';
import sjcl from 'sjcl';
import { decrypt } from '../utils/myCrypt';



const initialState = {
    isLoggedIn : false,  
    privateKey:"",
    publicKey : "",
    pubIsValid : "0",
    allowKeyChange: true,
    zacName : "",
    zacNameMessage : null,
    zacNameAvail : false,
    newZacName: "",
    zac_domain : 'zac.org.za',
    sel_server: null,
    error_msg : "",
    error:  false,
    error_type : "error",
    has_balance : false,
    balance: [],
    hasToPayAcc : false,
    transactions: [],
    tranStreamRunning : false,
    newTransactions : 0,
    tranMaxPageToken: "0",
    acc_level: "",
    stelserver : {},
    currentNetwork: {},
    zac_asset : {},
    fundPubIsValid : "0",
    toFundAccount : "",
    toFundStellar : "",
    hasFundAcc  : false,
    isTrade:false,
    extAmount : "",
    extComment : "",
};

function transTranlate(stelVal, stelAmm, asset) {
  if (stelAmm==='undefined') { stelAmm=0; }
  let TwoDecAmm = stelAmm;
  if (asset === 'ZAC') {
    TwoDecAmm = parseFloat(stelAmm).toFixed(2);
  }
  switch (stelVal) {
    case "account_debited" : return { tran : "Payment", amm_deb : "", amm_cred : TwoDecAmm }
    case "account_credited" : return { tran : "Received", amm_deb : TwoDecAmm, amm_cred : "" }
    default: return { tran : stelVal, amm_deb : TwoDecAmm, amm_cred : "" }
  }
    
}

const accountRecuder = (state=initialState,action) => {
        let newstate = { ...state};
       
        switch (action.type) {
          /*  
          case actionTypes.CREATE_TRUST:
                     
               return newstate;
               */
            case actionTypes.LOGOUT:{
              return initialState;
            }
            case actionTypes.GET_SIMPLE_PRIV_RET: {
              const priv = decrypt(action.data.pass, action.data.pub, action.data.ct);
              const pair =  StellarSdk.Keypair.fromSecret(priv);
              newstate = {
                ...state,
                privateKey : priv,
                accountKeypair: pair,
                isLoggedIn : true,
                error: false,
                error_msg: ''
              }
              return newstate;
            }
            case actionTypes.GET_PRIV_RET: {
              //console.log('AXIOS GET PRIV RETURN DATA :');
              //console.log(action.data);
              //var parsedMessage = JSON.parse(action.data);
              //console.log(parsedMessage);
              //let parsedMessage2 = Object.assign({mode:"ccm",iter:1000,ks:128,ts:64,v:1,cipher:"aes",adata:"",salt:"adsrwerbgdhuknc1425(8234098)(*(*&717623|:alkd==vnbcvbntt"}, parsedMessage);
              let messageWithParameters = {
                 mode: "ccm",
                 iter:10000,
                 ks: 128,
                 ts: 64,
                 v: 1,
                 cipher:"aes",
                 adata:"",
                 salt: action.data.st,
                 iv:action.data.iv,
                 ct:action.data.ct
              }
          //    console.log("Add Params");
          //    console.log(messageWithParameters);
              const JSONmessageWithParameters = JSON.stringify(messageWithParameters);
          //    console.log("JSON" +JSONmessageWithParameters);
              let decryptedMessage = "";
              try {
                decryptedMessage = sjcl.decrypt(action.pass,JSONmessageWithParameters)
              } catch(e) {
                 console.log("Raise error here Decrypting Password wrong : " + e);
                 if (e.message.match(/doesn't match/)) {
                  newstate = {
                    ...state,
                    error_msg : "Password Wrong",
                    error: true,
                    error_type : "error"
                   }
                 }
                 return newstate;
                }
              const pair =  StellarSdk.Keypair.fromSecret(decryptedMessage);

  
              newstate = {
                ...state,
                privateKey : decryptedMessage,
                accountKeypair: pair,
                isLoggedIn : true,
                error: false,
                error_msg: ''
              }
              return newstate;
            }

            case actionTypes.ADD_PRIV_RET: {
              newstate =
              { ...state,
                isLoggedIn : true,
                zacName : state.newZacName
              }
              return newstate;
            }
            case actionTypes.CHANGE_PRIV_RET: {
              newstate = {
                ...state,
              error: true,
              error_msg: "Password changed successfully.",
              error_type : "info"
              }
              return newstate; 
            }
            case actionTypes.CHANGE_PUB_K: {
              if (newstate.allowKeyChange) {
                newstate =
                {
                  ...state,
                  publicKey: action.publickey,
                }
              } else {
                newstate =
                {
                  ...state
                }
              }
              return newstate;
            }
            case actionTypes.CHANGE_PRIV_K: {
              if (newstate.allowKeyChange) {
              newstate =
              { ...state,
                privateKey : action.privatekey,
              }
              }
              return newstate;
            }

            case actionTypes.LOGIN_PK: {
              const pair =  StellarSdk.Keypair.fromSecret(action.privatekey);
              newstate =
              { ...state,
                privateKey : action.privatekey,
                accountKeypair: pair,
                isLoggedIn : true,
                error: false,
                error_msg: '',
                error_type : "error"
              }
              return newstate;
            }
              case actionTypes.SET_ZAC_FUND_NAME: {
                newstate = { ...state,
                  toFundAccount : action.toFundAccount,
                  toFundStellar : action.toFundStellar,
                  hasFundAcc  : true
                 };
                 return newstate;
              }
              case actionTypes.CLEAR_ZAC_FUND_NAME: {
                newstate = { ...state,
                  toFundAccount : "",
                  toFundStellar : "",
                  hasFundAcc  : false,
                  fundPubIsValid : "0"
                 };
                 return newstate;
              }
              case actionTypes.CLEAR_ZAC_TO_PAY_NAME: {
                newstate = { ...state,
                  toPayAccount : null,
                  toPayStellar : null,
                  hasToPayAcc  : false
                 };
                 return newstate;
              }
              case actionTypes.SET_ZAC_TO_PAY_NAME: {
                newstate =
                { ...state,
                  toPayAccount : action.toPayAccount,
                  toPayStellar : action.toPayStellar,
                  hasToPayAcc  : true
                 };

              return newstate;
            }

            case actionTypes.NEW_KP_TO_STORE:
                
                //console.log("New KP");
                if ((newstate.publicKey === null) || (newstate.publicKey === "")) {
                  //console.log("PK empty");
//                var Server = new StellarSdk.Server('https://horizon-testnet.stellar.org');
                //StellarSdk.Network.useTestNetwork();
                const kp =  StellarSdk.Keypair.random();
                newstate =
                  { ...state,
                    privateKey : kp.secret(),
                    publicKey : kp.publicKey(),
                    error: false,
                    allowKeyChange : false,
                   };
                  }
               return  newstate;

             case actionTypes.OTHER_USER_NAME_RET:

                if (action.data.account_id !== 'DOES_NOT_EXIST') {
                  newstate = {
                    ...state,
                    otherUserName :  action.otherusername +"*" + action.domain, 
                    otherUserPubk :  action.data.account_id,

                    hasOtherUser : true
                }
                }
                return newstate;

             case actionTypes.CHECK_ACCOUNT: 
                 newstate = {
                   ...state,
                   pubIsValid : action.pubIsValid
                 }
             return newstate;
             case actionTypes.CHECK_FUND_ACCOUNT:
                 newstate = {
                   ...state,
                   fundPubIsValid : action.pubIsValid
                 }
             return newstate;
             case actionTypes.ZAC_NAME_ERROR: 
                newstate = {
                  ...state,
                  zacNameMessage : action.err,
                  zacNameAvail :false,
                }
             return newstate;
             

             case actionTypes.CHECK_ZAC_NAME_RET:
                
                if (action.data.account_id !== 'DOES_NOT_EXIST') {

                  newstate = {
                    ...state,
                    zacNameMessage : action.newzacname +"*" + action.domain +" is taken",
                    zacNameAvail : false,
                    acc_level: action.data.acc_level,
                    deposit_key: action.data.deposit_key,
                    error: false
                  }  
                } else {
                  newstate = {
                    ...state,
                    zacNameMessage : action.newzacname +"*" + action.domain + " is available",
                    zacNameAvail :true,
                    newZacName : action.newzacname,
                    error: false
                  }  
                }

                if ((action.setPubK ) && (action.data.account_id !== 'DOES_NOT_EXIST') ) {
                  newstate = {
                    ...newstate,
                    zacName : action.newzacname,
                    zacNameAvail : true,
                    publicKey: action.data.account_id,
                    acc_level: action.data.acc_level,
                    error: false,
                    error_msg: ""
                  }
                  }  else if ((action.setPubK ) && (action.data.account_id === 'DOES_NOT_EXIST') ) {
                      newstate = {
                        ...newstate,
                        publicKey: "",
                        acc_level: "",
                        error: true,
                        error_msg: "User does not exist.",
                        error_type : "info"
                      }
                  }
                
                /*
                if (action.data.newZacName.length > 3) {
                newstate = {
                  zacNameMessage : action.data.newZacName + " is available"
                } }
                else {
                  newstate = {
                    zacNameMessage : "Name is Too short"
                  } 
                }
                */
               return newstate;
             case actionTypes.SET_DOMAIN:
               /* Set new state back to INITITAL STATE ALL VALUE ARE LOST*/
               let stelserver = {};
               let currentNetwork = {};
               let zac_asset = {} ;
               if (action.text === 'zac.org.za') {
                   stelserver = new StellarSdk.Server('https://horizon.stellar.org');
                   currentNetwork = StellarSdk.Networks.PUBLIC;  
                   zac_asset = new StellarSdk.Asset("ZAC", "GCXPSQG4GOUMX5UARDEHVAZJ3MNRPREEQWZKOCHJS334VAGU5HHKYUNK");
               } else {
                 stelserver =  new StellarSdk.Server('https://horizon-testnet.stellar.org');
//                 stelserver =  new StellarSdk.Server('https://horizon-test.zac.org.za');
                 currentNetwork = StellarSdk.Networks.TESTNET;

                 zac_asset = new StellarSdk.Asset("ZAC", "GB4KB5EFDJ4GEI4BULFKGY5JGPB33GHV7PPP3UTSOKVLS6CZTLFONG6J");
               }
                newstate = {
                  ...initialState
                } 
                newstate = {
                    ...initialState,
                    hasToPayAcc : state.hasToPayAcc, /* Preserve hasToPay to make MakeExternalPay work */
                    toPayAccount : state.toPayAccount,
                    toPayStellar : state.toPayStel,
                    extAmount : state.extAmount,
                    extComment : state.extComment,
                    zac_domain : action.text,
                    stelserver : stelserver,
                    currentNetwork : currentNetwork,
                    zac_asset : zac_asset,
                    error: false,

                }  
               return newstate; 
              case actionTypes.SET_BALANCE:

            const setNative =  (balance) => {
                  if (balance.asset_type === "native") {
                      balance.asset_code = 'Internal Currency (Lumens)'
                  }
              return   balance;
            };
            

                  newstate = {
                    ...state,
                    has_balance : true,
                    balance: action.balance.map(setNative)}
              return newstate;
            case actionTypes.RESET_TRAN_COUNT:
              newstate = {
                ...state,
                newTransactions : 0
              };
              return newstate;

            case actionTypes.CLEAR_PAY_REC:
                newstate = {
                  ...state,
                  transactions :[],
                  newTransactions : 0,
                  tranStreamRunning : false
                };
                return newstate;
                /* Does not work not being used
            case actionTypes.SET_ALL_PAY_REC_ARRAY:
              var allNewTran = action.allEffects.map;
              newstate = {
                ...state,
                transactions :allNewTran
              };
                return newstate;
                */
            case actionTypes.PAY_LISTNER_START :               
            newstate = {
              ...state,
              tranStreamRunning : true
            };
            return newstate;
            case actionTypes.SET_TRAN_ARRAY:
/*
                allTranArr: [
                  {
                    memo: 'ZAC0.15',
                    successful: true,
                    time: '2020-03-02T07:06:15Z',
                    source_account: 'GA2FEWSPS4PR6TWNSUGE7O2IOBXS74R2RZYBBTYDA6PQ7CPKLH5L36DO',
                    source_account_sequence: '1247730949160968',
                    valid_before: '2020-03-02T07:09:10Z',
                    pagining_token: '2239456077684736',
                    eff_record_count: 2,
                    eff_pag_token: '2239456077684737-2',
                    type: 'account_debited',
                    type_i: 3,
                    asset_code: 'ZAC',
                    asset_issuer: 'GDLIRGMSAJ7V23HGX3XGE6L3OTWWKI7YHL5442AB52YJF7J6YZ5MFJE3',
                    amount: '0.1500000'
                  },
*/
                let newArr = [];
                newArr = action.allTranArr.map( 
                      (tr) => {
                              const tranlate = transTranlate(tr.type,tr.amount,tr.asset_code);
                              return ( {
                              memo : tr.memo,
                              type : tr.type,
                              displayType : tranlate.tran,
                              type_i : tr.type_i,
                              asset : tr.asset_code ? tr.asset_code : "Lumens",
                              amm_deb : tranlate.amm_deb,
                              amm_cred : tranlate.amm_cred,
                              amount: tr.amount,
                              created_at : new Date(tr.time),
                              id : tr.pagining_token
                              })
                            }
                              );

                const tranMaxPageToken = action.allTranArr
                    .map( tr => tr.pagining_token )
                    .reduce(
                      (accumulator, currentValue) => {
                        return ( currentValue < accumulator  ?  accumulator : currentValue );
                      });
               //   console.log("tranMaxPageToken");
                  //console.log(tranMaxPageToken);

            newstate = {
                      ...state,
                      transactions :newArr,
                       newTransactions : newArr.length,
                       tranMaxPageToken : tranMaxPageToken
              };
            return newstate;  
            case actionTypes.SET_PAY_REC: 
            //console.log(action.payRec);
            const tranlate = transTranlate(action.payRec.type,action.payRec.amount,action.payRec.asset_code);
            const newpayrec = {
              memo : action.payRec.Memo,
              type : action.payRec.type,
              displayType : tranlate.tran,
              type_i : action.payRec.type_i,
              asset : action.payRec.asset_code ? action.payRec.asset_code : "Lumens",
              amm_deb : tranlate.amm_deb,
              amm_cred : tranlate.amm_cred,
              amount: action.payRec.amount,
              created_at : new Date(action.payRec.created_at),
              id : action.payRec.paging_token.split('-', 1)[0]
            }

            const isintran = state.transactions.filter( f => f.id === action.payRec.paging_token.split('-', 1)[0] );
            let  newpayarr= [...state.transactions];
            if ((typeof isintran !== "undefined") & (isintran.length ===0)) {
            newpayarr.push(newpayrec)
            
            newstate = {
              ...state,
              transactions :newpayarr,
              newTransactions : state.newTransactions +1
            };
            } else { // Dont add duplicates
              newstate = {
                ...state,
              }  
            }
            return newstate;
            case 'DECREMENT':
              return newstate - 1
            case actionTypes.ERROR_HANDELING:
               let newErr = action.err_msg;
               if (state.error) {
                newErr = newErr + " " + action.err_msg;
               }
               const errType=action.error_type?action.error_type:"error";
               newstate = {
                  ...state,
                  error : true,
                  error_msg: newErr.substr(0, 500),
                  error_type : errType
               }
             return newstate;
             case actionTypes.CLEAR_ERROR:
              newstate = {
                 ...state,
                 error : false,
                 error_msg: '',
                 error_type: "error"
              }
            return newstate;

            case actionUserTypes.ADD_LVL_ONE_RET: {
              // TODO Chekc if sucessfull savec values :
              newstate = {
                ...state,
                acc_level: 1
             }
           return newstate;
            }
  

            case actionTypes.SET_EXT_VALUES:
              newstate = {
                ...state,
                extAmount : action.amount,
                extComment : action.comment
              }
              return newstate;

             default:
              return newstate

        }
}

export default accountRecuder;
