//import steltk from "stellar-toolkit";
import * as ActionTypes from './types/';
import * as SagaTypes from './types/sagatypes';
import {sendError} from './thunkActions';
//const  { Asset, Keypair } = require('stellar-sdk');

import StellarSdk   from 'stellar-sdk';
//StellarSdk.Network.useTestNetwork();
//export const stellarServer = new StellarSdk.Server('http://192.168.2.115:8000',{allowHttp: true});
//export const stellarServer = new StellarSdk.Server('http://192.168.2.25:8000',{allowHttp: true});

//export const stellarServer = new StellarSdk.Server('https://horizon-testnet.stellar.org');
//export const currentNetwork = StellarSdk.Networks.TESTNET;

//const stellarServer = new StellarSdk.Server('https://horizon.stellar.org');
//const currentNetwork = StellarSdk.Networks.PUBLIC;

//export const zac_asset = new StellarSdk.Asset("ZAC", "GA46N7OCM6WRCSKQZJHV5U5SWOZTQU2STOQIHC3LA5AFNDQ756Z7NGRA");
export const zac_fee = StellarSdk.BASE_FEE;
/*
const getStellarServer = (network) => {
   stellarServer = new StellarSdk.Server('https://horizon-testnet.stellar.org');
}
*/
/*
const zacAss = {
    asset_code : "ZAC",
    asset_issuer : "GACOWOHFB2FPECZ62DJGIE65JVFZTR4SXTOFENQZX54WK5J72JY2OWFG"
    };
*/

export function setBalanceArr(balances) {
    return {
        type : ActionTypes.SET_BALANCE,
        balance: balances
    };
  }

export const setCheckAccount = (accountState)  => {
    return ({ type : ActionTypes.CHECK_ACCOUNT,
             pubIsValid: accountState, 
        });
}
export const setCheckFundAccount = (accountState)  => {
  return ({ type : ActionTypes.CHECK_FUND_ACCOUNT,
           pubIsValid: accountState, 
      });
}

export const doCheckFundAcc = (pubK) => {
  return ({
    type : SagaTypes.CHECK_FUND_ACCOUNT_INIT,
    pubK : pubK,
  });
}

export const doRunFundAcc = (fromPub,fromPriv,desPub,memo) => {
  return ({
    type : SagaTypes.FUND_ACCOUNT_INIT,
    fundDetail : {
      fromPub :fromPub,
      fromPriv: fromPriv,
      desPub : desPub,
      memo : memo
    }
  });
}


/*
  export const updateAlert = (alert, message) => (dispatch, getState) => {  
    dispatch ({
      type: 'UPDATE_ALERT',
      alert,
      message
    });
    return Promise.resolve(getState());
*/

export const getBalAct = (publicKey,privateKey) => {   
   return (
     {
       type : SagaTypes.FETCH_BAL_INIT,
       pubK : publicKey,
       privK: privateKey
     }
     );
    };
  /*
    return (dispatch,getState) => {

        stellarServer.loadAccount(publicKey)
        .then((account) =>{ 
            
            //console.log("Hano");
            //console.log(account.balances);
            //console.log(getState());
            dispatch(setBalanceArr(account.balances));
        }
       )
       .catch(
        (e) =>  {
        if (typeof e.name !== "undefined") {
              if (e.name === "NotFoundError") {
              console.log("Account Does not exist!!")  ;
              dispatch(setCheckAccount("-1") );   // Account Error Does not exist
              } else {    dispatch(sendError(e)); }
        } else {    dispatch(sendError(e)); }
      }
    )
        /*
        steltk.StellarServer.getAccount(publicKey)
           .then(
            (account) =>{ 
                console.log(account.balances);
                dispatch(setBalanceArr(account.balances));
            }
           )
           .catch( (e) =>  {
            dispatch(sendError(e));
            console.log("Error from Stellar Action Get Account: ") ;
            console.log(e);
               }   );
               */
/*              
    }
 */



/* TO CHAIN THUNK ACTIONS SAMPLE 
export function getUserAndTheirFirstPost(userId) {
  // Again, Redux Thunk will inject dispatch here.
  // It also injects a second argument called getState() that lets us read the current state.
  return (dispatch, getState) => {
    // Remember I told you dispatch() can now handle thunks?
    return dispatch(getUser(userId)).then(() => {
      // Assuming this is where the fetched user got stored
      const fetchedUser = getState().usersById[userId]
      // Assuming it has a "postIDs" field:
      const firstPostID = fetchedUser.postIDs[0]
      // And we can dispatch() another thunk now!
      return dispatch(getPost(firstPostID))
    })
  }
}
*/

export const checkAccount = (accPub) => {
  return (dispatch,getState) => {
    const stellarServer = getState().accounts.stelserver;
    
    stellarServer.loadAccount(accPub)
    .then(
      dispatch(setCheckAccount("1"))
    )
    .catch(
        (e) =>  {
        if (typeof e.name !== "undefined") {
              if (e.name === "NotFoundError")  {
              console.log("Account Does not exist!!")  ;
              dispatch(setCheckAccount("-1") );   // Account Error Does not exist
              } else {    dispatch(sendError(e)); }
        } else {    dispatch(sendError(e)); }
      }
    )
  }
}


export const doPayment = (desPub,payAmm,memo,payAsset,fromPriv,fromPub) => {
  const paydetail = {
      desPub : desPub,
      payAmm : payAmm,
      memo : memo,
      payAsset : payAsset,
      fromPriv : fromPriv,
      fromPub : fromPub
  };
    return (
      {
        type : SagaTypes.PAYMENT_INIT,
        paydetail : paydetail
      }
      );

    }

//ToDO Get other account and tun this
export const doPaymentOld = (desPub,payAmm,memo,payAsset,fromPriv,fromPub) => {
  //console.log("Payment to : " + desPub + " Amount : " + payAmm + "Memo : " + memo + " Asset: " + payAsset + " From Pub" + fromPub + ' from Priv' + fromPriv )
  
  //console.log("Asset set to ZAC");

  
  return (dispatch,getState) => {
    const stellarServer = getState().accounts.stelserver;
    const currentNetwork = getState().accounts.currentNetwork;
    const zac_asset = getState().accounts.zac_asset;
    let locAsset = zac_asset;
    if (payAsset !== 'ZAC') {
      locAsset =  StellarSdk.Asset.native();
    }
    stellarServer.loadAccount(desPub)
  // If the account is not found, surface a nicer error message for logging.
  .catch( (e) =>  {
        dispatch(sendError(new Error('The destination account does not exist!')));
        console.log("Dest Account Does not exist") ;
           } )
  // If there was no error, load up-to-date information on your account.
  .then( ()  => {
    return stellarServer.loadAccount(fromPub);
  })
  .then( (sourceAccount) => {
    // Start building the transaction.
    const transaction = new StellarSdk.TransactionBuilder(sourceAccount,
         { fee: StellarSdk.BASE_FEE, networkPassphrase: currentNetwork  }
        )
      .addOperation(StellarSdk.Operation.payment({
        destination: desPub,
        asset: locAsset,
        amount: payAmm
      }))
      .addMemo(StellarSdk.Memo.text(memo.substring(0, 27)))
      // Wait a maximum of three minutes for the transaction
      .setTimeout(180)  
      .build();
    // Sign the transaction to prove you are actually the person sending it.
    transaction.sign(StellarSdk.Keypair.fromSecret(fromPriv));
    // And finally, send it off to Stellar!
    return stellarServer.submitTransaction(transaction);
  })
  .then(     dispatch( {
    type : ActionTypes.CLEAR_ZAC_TO_PAY_NAME,
    toPayAccount: "",
    toPayStellar: ""
  }) )
  .catch( (e) =>  {
    dispatch(sendError(e));
    console.log("Error from Stellar Payment: ") ;
       }   );

  }
}


export const callbackPayment = (payRec,pubK) => {
  return (dispatch) => {
     dispatch({type : ActionTypes.SET_PAY_REC,
      payRec : payRec}
    );
  }
}

export const callbackStartPayment = () => {
  return (dispatch) => {
     dispatch({type: ActionTypes.PAY_LISTNER_START}
    );
  }
}

/* Spend most of the day to figre out how to get an array of all he data on multiple async call 
giving up will revisit later.
We need to run Transactions first to get the memos and then run the effects on each memo. 
I dont see a link between transactions(where the memos are) and effects. That is why 
getPaymentStream calls  txResponse.effects() 
check out command_line newAllTranClean for good stuff but not working :-(
export const getAllTransactions = (pubK, lastCursor=0) => {
  return (dispatch) => {
    const es = stellarServer.effects()
    .forAccount(pubK)
    .cursor(lastCursor)
    .call()
    .then(
        ( all ) => {
            dispatch({
              type:  ActionTypes.SET_ALL_PAY_REC_ARRAY,
              allEffects: all
            }
            );
        }
    )
    .catch( (e) =>  {
      dispatch(sendError(e));} );
  }
}
*/
export const getPaymentStreamSaga = (pubK, lastCursor=0,limit=25) => {
  return (
    {
      type : SagaTypes.PAYMENT_STREAM_INIT,
      pubK :pubK,
      lastCursor : lastCursor,
      limit : limit
    }
    );
}

export const getPaymentArray =  (pubK, lastCursor=0,limit=25) => {
  return ({
      type : SagaTypes.GET_PAYMENT_ARRAY_INIT,
    pubK: pubK
  });
}

export const externalPayInitAction = (params) =>   {
  return (
      { type: SagaTypes.EXTERNAL_PAY_INIT, params : params  }
  )
}

export const getPaymentStreamListner = (pubK, lastCursor="0",limit=25) => {
    /*   console.log("State on Steam");
    console.log(getState());
 
    if (getState().accounts.tranStreamRunning) {
      console.log("Should be ");
      */
   return (dispatch,getState) => {
    const stellarServer = getState().accounts.stelserver;
    if (getState().accounts.tranStreamRunning) {
      dispatch({type: ActionTypes.PAY_LISTNER_REQUEST_BUT_RUNNING});
    } else {
    const tranLog = function (txResponse) {
     txResponse.effects()
       .then((ef) => {
       const accReturn = ef.records.filter(
               (el) => el.account === pubK
          )[0];
          if (accReturn != null) {
            accReturn.Memo =   txResponse.memo;
            accReturn.paging_token = txResponse.paging_token;
           // console.log(accReturn);
            dispatch (callbackPayment(accReturn,pubK));
           }
       }
       );
        }; // end tranLog
       // console.log(lastCursor);
        dispatch(callbackStartPayment());
        stellarServer.transactions()
          .forAccount(pubK)
          .cursor(lastCursor)
          .limit(limit)
          .stream({
              onmessage: tranLog
          })
      }    
        ////#region 
     //console.log("New Receipt");
      //console.log(txResponse.memo);
    /*    console.log("Transaction");
      console.log( JSON.stringify(StellarSdk.xdr.TransactionEnvelope.fromXDR(txResponse.envelope_xdr, 'base64')) );
      console.log("Result");
      console.log( JSON.stringify(StellarSdk.xdr.TransactionResult.fromXDR(txResponse.result_xdr, 'base64')) );
      console.log("Meta");
      console.log( JSON.stringify(StellarSdk.xdr.TransactionMeta.fromXDR(txResponse.result_meta_xdr, 'base64')) );
      */
     //console.log(txResponse);
////#endregion    

  


   } 
/*  } else {
    console.log("Should be PAY_LISTNER_REQUEST_BUT_RUNNING");
    return (dispatch) => { return ({type: ActionTypes.PAY_LISTNER_REQUEST_BUT_RUNNING});  }
      };
      */
}

export const doZacTrust = (pubK,privK) =>   {
  return ({
    type: SagaTypes.ZAC_TRUST_INIT,
    action: {
      pubK :pubK,
      privK: privK
    }
  });
}

  export const doOldZacTrust = (pubK,privK) =>   {
      //const myPriv =  StellarSdk.Keypair.fromSecret(privK);

    return (dispatch,getState) => {
          const stellarServer = getState().accounts.stelserver;
          const currentNetwork = getState().accounts.currentNetwork;
          const zac_asset = getState().accounts.zac_asset;

          const transactionData = {
            asset: zac_asset,
            limit: "1000000"
          };

          const loginSignAccount = StellarSdk.Keypair.fromSecret(privK);
          stellarServer.loadAccount(pubK)
          .then( account => {
             const transaction = new StellarSdk.TransactionBuilder(
                      account,
                      { fee: StellarSdk.BASE_FEE, networkPassphrase: currentNetwork }
                      )
             .addOperation( StellarSdk.Operation.changeTrust(transactionData))
             .setTimeout(180)  
             .build();
             transaction.sign(loginSignAccount);
             // And finally, send it off to Stellar!
             return stellarServer.submitTransaction(transaction);
          }
          )
          .then(
            (pubK) => getBalAct(pubK)
          )
          .catch((e) =>  {
            dispatch(sendError(e));
            console.log("Error from Stellar Action doZacTrust: ") ;
            console.log(e);
               } );

              } 
 }

                          
/*

          StellarSdk.StellarOperations
          .changeTrust(transactionData)(loginSignAccount)
          .then(console.log
          )
          .catch((e) =>  {
            dispatch(sendError(e));
            console.log("Error from Stellar Action doZacTrust: ") ;
            console.log(e);
               } );
*/      
               /*

          steltk.StellarOperations
            .changeTrust({ asset: zac_asset,limit: 10000 })(loginSignAccount)
            .then( 
                 console.log
                
                steltk.StellarServer.getAccount(pubK)
                .then(
                 (account) =>{ 
                     console.log(account.balances);
                     dispatch(setBalanceArr(account.balances));
                 }
                 
                )
                
            )
            .catch((e) =>  {
                dispatch(sendError(e));
                console.log("Error from Stellar Action doZacTrust: ") ;
                console.log(e);
                   } );
                   */
    //}
    

