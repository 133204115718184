export const LOGOUT = 'LOGOUT'; 
export const CHECK_ACCOUNT = 'CHECK_ACCOUNT';
export const NEW_KP_TO_STORE =  'NEW_KP_TO_STORE';
//export const CHECK_ZAC_NAME  = 'CHECK_ZAC_NAME';
export const CHECK_ZAC_NAME_RET = 'CHECK_ZAC_NAME_RET';
export const ADD_ZAC_NAME_RET = 'ADD_ZAC_NAME_RET';
export const ZAC_NAME_ERROR = 'ZAC_NAME_ERROR';
export const SET_DOMAIN = 'SET_DOMAIN';
export const CREATE_TRUST = 'CREATE_TRUST';
export const ADD_PRIV_RET = 'ADD_PRIV_RET';
export const CHANGE_PRIV_RET = 'CHANGE_PRIV_RET';
export const GET_PRIV_RET = 'GET_PRIV_RET';
export const GET_SIMPLE_PRIV_RET = 'GET_SIMPLE_PRIV_RET';

export const LOGIN_PK = 'LOGIN_PK';
export const CHANGE_PUB_K = 'CHANGE_PUB_K';
export const CHANGE_PRIV_K = 'CHANGE_PRIV_K';

export const ASYNC_SEND_OPERATION = 'ASYNC_SEND_OPERATION';
export const ASYNC_CREATE_TRUSTLINE = 'ASYNC_CREATE_TRUSTLINE';
export const ASYNC_GET_ORDERBOOK = 'ASYNC_GET_ORDERBOOK';
export const ASYNC_CREATE_OFFER = 'ASYNC_CREATE_OFFER';

export const PAY_LISTNER_START = 'PAY_LISTNER_START'; // RUN JUST BEFORE STARTING LISTNER
export const PAY_LISTNER_REQUEST_BUT_RUNNING = 'PAY_LISTNER_REQUEST_BUT_RUNNING'; // JUST FOR DEBUG PURPOSES IF LISTENR IS RUNNING WE DISPATCH THIS ON EACH REQUEST
export const SET_PAY_REC = 'SET_PAY_REC';
export const CLEAR_PAY_REC = 'CLEAR_PAY_REC';
export const RESET_TRAN_COUNT = 'RESET_TRAN_COUNT';
export const SET_TRAN_ARRAY = 'SET_TRAN_ARRAY';

export const SET_BALANCE = 'SET_BALANCE';
export const ADD_ZAC_TRUST = 'ADD_ZAC_TRUST';
export const OTHER_USER_NAME_RET = 'OTHER_USER_NAME_RET';
export const SET_ZAC_TO_PAY_NAME = 'SET_ZAC_TO_PAY_NAME';
export const CLEAR_ZAC_TO_PAY_NAME = 'CLEAR_ZAC_TO_PAY_NAME';
export const AFTER_PRIV_RETURN = 'AFTER_PRIV_RETURN';

//export const SET_ALL_PAY_REC_ARRAY = 'SET_ALL_PAY_REC_ARRAY'; Not used anymore
export const ERROR_HANDELING = 'ERROR_HANDELING';
export const CLEAR_ERROR = 'CLEAR_ERROR';

/* FUND ACCOUNT */
export const CHECK_FUND_ACCOUNT = 'CHECK_FUND_ACCOUNT';
export const SET_ZAC_FUND_NAME ='SET_ZAC_FUND_NAME';
export const CLEAR_ZAC_FUND_NAME ='CLEAR_ZAC_FUND_NAME';

/* EXT PAYMENTS */
export const SET_EXT_VALUES = 'SET_EXT_VALUES';

export function setDomain(text) {
    return { type: SET_DOMAIN, text }
  }

  /*
export  function resetTranCount() {
   return {type : RESET_TRAN_COUNT }
}
*/

/* COUNTRIES */
export const COUNTRIES_RETURN = 'COUNTRIES_RETURN';