import React from 'react';
import StelTrans from '../../components/StelTrans';
import {connect} from 'react-redux';
import { withStyles} from '@material-ui/core/styles'
import ContentHeader from '../../components/ContentHeader';
import { Paper } from '@material-ui/core';
import { styles } from './theme';
//import { getPaymentArray }  from '../../Actions/stellarActions';
//import * as actionTypes from '../../Actions/types';
import * as sagaTypes from '../../Actions/types/sagatypes';
import { Redirect } from 'react-router-dom';
import {BalanceIcon, RefreshIcon} from '../layout/Gobalicons';


const Transactions = React.memo(props => {
    const { classes } = props;
    const checkLoggedIn = () => {
        return (!props.isLoggedIn ? <Redirect to="/login" /> : null );
     }

    return ( 
    <>
            {checkLoggedIn()}
    <Paper elevation={3} className={classes.paper}>
        <ContentHeader 
        classes={classes} 
        headerdisplay="Transactions"
        has_tp2={true} 
        tp2="Refresh"
        icon2={<RefreshIcon />}
        icon2click={() => {
            props.goRefreshTrans(props.publickey);
        }}
        has_tp3={true}
        tp3="Balance"
        icon3={<BalanceIcon />}
        icon3click={() => {
            props.history.push('/wallet');
        }}
        

        />
        <StelTrans transactions={props.transactions} classes={classes} />
    </Paper>
    </>
    );
}
    
);

const mapStateToProps = (state) => {
    return {
        transactions : state.accounts.transactions,
        isLoggedIn : state.accounts.isLoggedIn, 
        publickey : state.accounts.publicKey,

    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        goRefreshTrans: (pubK) => {
            dispatch({ type: sagaTypes.REFRESH_TRAN_INIT ,
                pubK : pubK
            });
             //dispatch({ type: actionTypes.CLEAR_PAY_REC });
             //dispatch(getPaymentStream(pubK));
           },
       
   }
}

export default withStyles(styles)(connect(mapStateToProps,mapDispatchToProps)(Transactions));