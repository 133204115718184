import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { BalanceIcon, PayIcon, LoginIcon, NewAccountIcon, TransactionsIcon, FundAccountIcon, TradeIcon, Qr, LogoutIcon, LevelOneIcon, ChangePass, VoucherIcon } from '../pages/layout/Gobalicons';

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    '&:focus': {
      backgroundColor: theme.palette.primary.main,
      '& .MuiListItemIcon-root, & .MuiListItemText-primary': {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

const ZacMenu = (props) => {

  const NotLoggedIn = (props) => {
    return (<StyledMenu
      anchorEl={props.anchorEl}
      id="customized-menu"
      open={props.open}
    >
      <StyledMenuItem onClick={() => {
        props.history.push('/login');
      }

      } >
        <ListItemIcon>
          <LoginIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText primary="Login" />
      </StyledMenuItem>
      <StyledMenuItem onClick={() => {
        props.history.push('/new');
      }

      } >
        <ListItemIcon>
          <NewAccountIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText primary="New Account" />
      </StyledMenuItem>

      <StyledMenuItem onClick={() => {
        props.history.push('/qr');
      }
      } >
        <ListItemIcon>
          <Qr fontSize="small" />
        </ListItemIcon>
        <ListItemText primary="Generate QR Code" />
      </StyledMenuItem>
    </StyledMenu>);
  }


  const LoggedInMenu = (props) => {
    return (<StyledMenu
      anchorEl={props.anchorEl}
      id="customized-menu"
      open={props.open}
    >
      <StyledMenuItem onClick={() => {
        props.doLogout();
        props.history.push('/');
      }

      } >
        <ListItemIcon>
          <LogoutIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText primary="Logout" />
      </StyledMenuItem>

      <StyledMenuItem onClick={() => {
        props.history.push('/wallet');
      }

      } >
        <ListItemIcon>
          <BalanceIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText primary="Wallet" />
      </StyledMenuItem>
      <StyledMenuItem
        onClick={() => props.history.push('/pay')}
      >
        <ListItemIcon>
          <PayIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText primary="Pay" />
      </StyledMenuItem>
      <StyledMenuItem onClick={() => props.history.push('/tran')}>
        <ListItemIcon>
          <TransactionsIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText primary="Transactions" />
      </StyledMenuItem>
      <StyledMenuItem onClick={() => props.history.push('/fund')}>
        <ListItemIcon>
          <FundAccountIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText primary="Fund Another Account" />
      </StyledMenuItem>
      <StyledMenuItem onClick={() => props.history.push('/trade')}>
        <ListItemIcon>
          <TradeIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText primary="Exchange Currencies / Trade" />
      </StyledMenuItem>
      <StyledMenuItem onClick={() => props.history.push('/voucher')}>
        <ListItemIcon>
          <VoucherIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText primary="Voucher / Cash Out" />
      </StyledMenuItem>
      <StyledMenuItem onClick={() => props.history.push('/levone')}>
        <ListItemIcon>
          <LevelOneIcon fontSize="small" />
        </ListItemIcon>
        <ListItemText primary={(props.acc_level < 1) ? "Upgrade to Level One" : "Update Level One"} />
      </StyledMenuItem>
      <StyledMenuItem onClick={() => props.history.push('/passw')}>
        <ListItemIcon>
          <ChangePass fontSize="small" />
        </ListItemIcon>
        <ListItemText primary="Change Password" />
      </StyledMenuItem>
    </StyledMenu>
    );
  };

  return (<>
    {props.isLoggedIn & props.pubIsValid !== "-1" ?
      <LoggedInMenu {...props} />
      : <NotLoggedIn {...props} />
    }
  </>
  )
}


export default ZacMenu;

