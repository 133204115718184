import React from 'react';
import Balance from './BalanceDisplay';
//import AddTrust from './AddTrust';
import Grid from '@material-ui/core/Grid';

const allBalances = (props) =>  {
    let ret = '';
    //console.log("AllBalances");
    //console.log(props);
if (props.has_balance)  {
    ret =  props.balances.map(
        aBalance => 
            <Grid item key={aBalance.asset_code}>
               <Balance key={aBalance.asset_code} asset_code={aBalance.asset_code} balance={aBalance.balance} classes={props.classes} />
             </Grid>
     )

   //  const hasZac = props.balances.some( aBalance => aBalance.asset_code ==='ZAC'  );
/*     if (!(hasZac)) {
        props.changeTrust(props.publickey,props.privatekey);
    }
*/
/*
if (!(hasZac)) {
        const newret =  <React.Fragment>
                    {ret}
            <AddTrust changeTrust={props.changeTrust} privatekey={props.privatekey} publickey={props.publickey} />
        </React.Fragment>

        ret = newret;
}
*/

    } ;

    return ret;
};

export default allBalances;


