import React, { Component } from 'react';
import { Redirect } from 'react-router-dom';
import { Paper, Grid,Button,TextField , Select, InputLabel,FormControl, MenuItem,InputAdornment} from '@material-ui/core/';
//import { styles } from './theme';
import { styles } from '../../pages/layout/theme';
import {connect} from 'react-redux';
import { withStyles} from '@material-ui/core/styles'
import { handleUserFieldChange ,saveLvlOne, validateLvlOne}  from '../../Actions/userActions';
import ContentHeader from '../../components/ContentHeader';
import {KeysIcon,TransactionsIcon, PaymentIcon} from '../layout/Gobalicons';
import { getCountryCodes } from '../../Actions/thunkActions';
import { getPaymentArray }  from '../../Actions/stellarActions';
import clsx from 'clsx';

class LevOneClass extends Component {
    
    componentDidMount() {
            this.props.doGetCountries();
       //     this.props.dohandleUserFieldChange('country_id','ZA');
    }
    

    handleChange = prop => event => {
     //   this.setState({ [prop]: event.target.value });
//        this.props.dohandleUserFieldChange([event.target.name],event.target.value);
        this.props.dohandleUserFieldChange([prop],event.target.value);
      };
    
    handleSubmit = () => {
      if (this.props.err_obj.hasOwnProperty('noerr')) {
        this.props.doSaveLvlOne();
        this.props.history.push('/wallet');
      }
    }

    handleValidate = () => {
      this.props.doValidateLvlOne();
    }
    getErrors = fields => {
      return this.props.err_obj[fields];
      /*switch (field) {
        case 'fullname': 
          errors.fullName = 
            value.length < 5
              ? 'Full Name must be 5 characters long!'
              : '';
          break;
          */
    }
    isError = field => {
      return (field in this.props.err_obj);
    }

    showField = (fieldname,displayLab) => (
      <TextField
      value={this.props[fieldname]}
      onChange={this.handleChange(fieldname)}
      className={this.props.classes.formTextInput}
      name={fieldname}
      id={fieldname}
      label={displayLab}
      InputLabelProps={{
        shrink: true,
      }}
      error={this.isError(fieldname)}
      helperText={this.getErrors(fieldname)}
/>

    )

    render() {
        const checkLoggedIn = () => {
            return (!this.props.isLoggedIn ? <Redirect to="/login" /> : null );
         }
         const { classes } = this.props;
/*
        const formik = useFormik({
          initialValues: { email: "" },
          onSubmit: values => {
            alert(JSON.stringify(values, null, 2));
          }
        });
  */       
        return ( <React.Fragment>
            {checkLoggedIn()}
            <Paper elevation={3} className={classes.paper}>
            {this.props.pubIsValid !=="-1" ?
          <ContentHeader classes={classes} headerdisplay={(this.props.acc_level<1)?"Upgrade to Level One":"Update Level One Data"} 
          has_tp1={true}
          tp1="Keys"
          icon1={<KeysIcon />}
          icon1click={
            event => {
              this.props.history.push('/showkeys');
            }}
          tp2="Transactions"
          has_tp2={true}
          icon2={<TransactionsIcon/>}
          icon2click={() => {
                  this.props.goGetTrans(this.props.publickey);
                  this.props.history.push('/tran');
              }}
              tp3="Payment"
              has_tp3={true}
            icon3={<PaymentIcon />}
            icon3click={() => {
                this.props.history.push('/pay');
            }}
        
          />
          :
          <ContentHeader classes={classes} headerdisplay="User Details" 
          has_tp1={true}
          tp1="Keys"
          icon1={<KeysIcon />}
          icon1click={
            event => {
              this.props.history.push('/showkeys');
            }}
          /> }

            <Grid container  direction="column"
             justify="center"
             alignItems="center">
                       <Grid item>
              {this.showField("email","Email *")}
          </Grid>
          <Grid item>
              {this.showField("rsa_id","RSA ID No")}
          </Grid>
          {this.props.rsa_id.length===0?
          <Grid item>
              {this.showField("passport_no","Passport Number")}
          </Grid>
          :null
          }
                <Grid item>
             {this.showField("firstname","Firstname *")}
            </Grid><Grid item>
              {this.showField("surname","Surname *")}
    </Grid>
    <Grid item>  

    <FormControl variant="filled" className={classes.formControl2}>
        <InputLabel id="country-label1">Country</InputLabel>
        <Select
          labelId="country-label1"
          id="country_id"
          value={this.props.country_id}
          onChange={this.handleChange("country_id")}
        >
          {this.props.countries.map((countryojb, index) =>
                      <MenuItem key={index} value={countryojb.country_code}>{countryojb.country_name}</MenuItem>
                    )}
        </Select>
      </FormControl>
      </Grid>       
          <Grid item>
          <TextField
        value={this.props.address1}
        onChange={this.handleChange("address1")}
        className={this.props.classes.formTextInput}
        name="address1"
        id="address1"
        label="Address"
        InputLabelProps={{
          shrink: true,
        }}
        error={this.isError('address1')}
        helperText={this.getErrors('address1')}
        multiline
        rows={2}
/>
          </Grid>
          <Grid item>
              {this.showField("suburb","Suburb")}
          </Grid>
          <Grid item>
              {this.showField("city","City")}
          </Grid>
          <Grid item>
              {this.showField("code","Code")}
          </Grid>
          <Grid item>

          <TextField
      value={this.props.phone}
      onChange={this.handleChange("phone")}
      className={clsx(this.props.classes.margin, this.props.classes.formTextInput)}
      name="phone"
      id="phone"
      label="Phone *"
      InputLabelProps={{
        shrink: true,
      }}
      error={this.isError("phone")}
      helperText={this.getErrors("phone")}
      InputProps={{
        startAdornment: <InputAdornment position="start">+{this.props.country_prefix} </InputAdornment>,
      }}

/>
          </Grid>
    </Grid>
    <Grid item>
        <p>* Indicates Mandatory Fields</p>
    </Grid>
    <Grid item>
    {this.props.err_obj.hasOwnProperty('noerr')?
       <Button
       variant="contained" color="primary" size="small"       
       onClick={() =>  this.handleSubmit()}>Save</Button>
       :
       <Button
       variant="contained" color="secondary" size="small"       
       onClick={() =>  this.handleValidate()}>Validate</Button>
      }

      </Grid>
      <Grid item>  

  </Grid>       


            </Paper>
          </React.Fragment>
        );
    }



}

const mapStateToProps = (state) => {
    return {
        firstname : state.users.firstname,  
        zacDomain : state.accounts.zac_domain,
        surname : state.users.surname,  
        country_id : state.users.country_id,  
        email : state.users.email,  
        rsa_id : state.users.rsa_id,  
        passport_no : state.users.passport_no,  
        isLoggedIn : state.accounts.isLoggedIn,
        countries : state.users.countries,
        code: state.users.code,
        suburb: state.users.suburb,
        city: state.users.city,
        country_prefix : state.users.country_prefix,
        phone: state.users.phone,
        err_obj : state.users.err_obj,
        acc_level : state.accounts.acc_level
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    dohandleUserFieldChange : (field,value) => {
        //  console.log("dohandleUserFieldChange" + field + " => " + value);
          dispatch(handleUserFieldChange (field,value));
        },
    doGetCountries : () => {
          dispatch(getCountryCodes());
    },
    goGetTrans: (pubK) => {
      //  dispatch({type : actionTypes.CLEAR_PAY_REC});
        dispatch(getPaymentArray(pubK));
     },
     doSaveLvlOne: () => {
          dispatch(saveLvlOne());
     },
     doValidateLvlOne: () => {
          dispatch(validateLvlOne());
     }
  }
}


export default withStyles(styles)(connect(mapStateToProps,mapDispatchToProps)(LevOneClass));
