import React, {Component } from 'react';
import QRCode from 'qrcode.react';
import {Paper, Grid,TextField} from '@material-ui/core';
import { styles } from './content/theme';
import { withStyles } from '@material-ui/core/styles';


class MakeQR extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            amount : "0",
            receiver : "",
            comment: "",
            receive_error: ""
        }
    }

    handleChange = prop => event => {
        let newval =  event.target.value ;
        /*if ((prop === 'receiver') && (!event.target.value.includes("*") )) {
            newval =  event.target.value + '*zac.org.za' ; 
        }
          */ 
         if (prop==="comment" ) {
           if (newval.length <=28) {
             this.setState({ [prop]:newval});
           }
         } else {
        this.setState({ [prop]:newval});
         }
      };

    render() {
        const {classes} = this.props;
        const getBrowserURL = () => {
            return window.location.origin;
        }


        const defaultDomain = (inname) => {
          
          if (inname.indexOf('*') === -1)
          {
            return inname + "*zac.org.za"
          } else { return inname; }
        }
        const url = () => {
            //return "https://api.zac.org.za/secure/to-pay/:" +  encodeURI(defaultDomain(this.state.receiver))  + "/" + encodeURI(this.state.amount) + "/" + encodeURI(this.state.comment);
            //let newURL = "http://localhost:3000/secure/to-pay/" + encodeURI(defaultDomain(this.state.receiver)) + "/" + encodeURI(this.state.amount) + "/" + encodeURI(this.state.comment);
            //return newURL.replace('#','%23');
            //return getBrowserURL() + "/secure/#/to-pay/" + encodeURI(defaultDomain(this.state.receiver)) + "/" + encodeURI(this.state.amount) + "/" + encodeURI(this.state.comment);
            return getBrowserURL() + "/secure/?to-pay=1&receiver=" + encodeURI(defaultDomain(this.state.receiver)) + "&amount=" + encodeURI(this.state.amount) + "&comment=" + encodeURI(this.state.comment);
            //http://localhost:3000/secure/?to-pay=1&receiver=hano1*zac.org.za&amount=1333&comment=TEST%20ME%20SOME%20SPACE
              };

        return (
            <>
            <Paper elevation={3} className={classes.paper}   >
              <Grid container
                direction="column"
                justify="center"
                alignItems="center"><Grid item>
                  <TextField
                    name="zacname"
                    id="receiver_name"
                    label="ZAC Name of Receiver account"
                    placeholder="name*zac.org.za"
                    helperText="name*zac.org.za"
                    margin="normal"
                    error={!this.state.receiver}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={this.state.receiver}
                    onChange={this.handleChange('receiver')}
                    className={classes.textField}
                  />
                </Grid>
                <Grid item>
                  <TextField
                    name="amount"
                    id="amount"
                    label="Amount to be paid"
                    placeholder="ZAC Amount"
                    margin="normal"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={this.state.amount}
                    onChange={this.handleChange('amount')}
                    className={classes.textField}
                  />
                </Grid>                
                <Grid item>
                  <TextField
                    name="comment"
                    id="comment"
                    label="Comment"
                    placeholder="Comment used by receiver to identify transaction"
                    margin="normal"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    value={this.state.comment}
                    onChange={this.handleChange('comment')}
                    className={classes.textField}
                  />
                </Grid>                
              </Grid>
            </Paper>
            <br />
            <Paper elevation={3} className={classes.paper} >
               <QRCode value={url()}  /><br />
               <TextField value={url()} label="Generated Link" 
               className={classes.textField}/>
            </Paper>
            </>
        );
    }
}

export default withStyles(styles)(MakeQR);