import React from 'react';
import { Card , CardContent,Typography, CardHeader } from '@material-ui/core';

const currencyCard = (props) => {
    const {classes} = props;
    return (<Card className={classes.currcard}>
          <CardHeader
        avatar={props.avatar}
        title={props.currenytitle}
         />
        
      <CardContent>
        <Typography className={classes.title} color="textSecondary" gutterBottom>
        {props.asset_code} {props.balance}
        </Typography>

      </CardContent>
    </Card>);
    }

export default currencyCard;