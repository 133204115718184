import Schema  from 'validate'
 
export const userValidate = new Schema({
  firstname: {
    type: String,
    required: true,
    length: { min: 1, max: 50 }
  },
  surname: {
    type: String,
    required: true,
    length: { min: 2, max: 50 }
  },
  email: {
      type: String,
      required : true,
      match: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, //eslint-disable-line
      message: 'Email is required, and has to be correct.'
  },
  rsa_id: {
    type:String,
    length: {min:13, max:13},
    match: /^[0-9][0-9][0-1][0-9][0-3][0-9][0-9]{7}$/,
    message: "ID Must be 13 Long and contain only numbers"
  },
  phone: {
    type: String,
    match: /^[0-9]{9,11}$/,
    message: "Phone must be between 9 and 11 with no spaces and only numbers."
  }

}
);





