import axios from 'axios';
import * as ActionTypes from './types/';
import * as SagaActions from './types/sagatypes';
import { setCheckAccount, doCheckFundAcc } from './stellarActions';

//const FEDHOST = 'http://192.168.2.116:8080'; //https://api.zac.org.za
//const FEDHOST = 'http://192.168.2.118:8000';
//export const FEDHOST = 'http://localhost:8000';
export const FEDHOST = 'https://api.zac.org.za';




export const sendError = (er) => {
    //console.log("Send Error:");
    //console.error(er);
    /* CHECK IF STELLAR ERROR */
    
    if (typeof er.response === 'undefined') {
    return {
        type: ActionTypes.ERROR_HANDELING,
        err_msg: er.message,
        error_type: 'error'
      }}
      else return {
        type: ActionTypes.ERROR_HANDELING,
        err_msg: er.message + " : " + JSON.stringify(er.response),
        error_type: 'error'
      }
}
export const sendTxtError = (txtErrMes) => {
  console.log("Send Error:" +txtErrMes)
  return  {
      type: ActionTypes.ERROR_HANDELING,
      err_msg: txtErrMes,
      error_type: 'error'
     }
}
export const sendTxtInfo = (txtInfoMes) => {
  return  {
      type: ActionTypes.ERROR_HANDELING,
      err_msg: txtInfoMes,
      error_type: 'info'
     }
}


export const getCountryCodes = () => {
  return (dispatch) => {
     axios.get(FEDHOST + '/lu_country')
      .then(
          (response) => {

            dispatch({type : ActionTypes.COUNTRIES_RETURN,
                 data: response.data
            });
          }
      )
      .catch( (e) =>  {
        console.log("Error in lu_country");
      dispatch(sendError(e));
         })
  }
}

export const getUserPub = (usertocheck,domain='zac.org.za') => { 
  return (dispatch) => {
    //    https://api.zac.org.za/
    axios.get(FEDHOST +'/federation?type=name&q='+usertocheck+'*' +domain ,
    { 
        q : usertocheck
     } )
    .then( 
        (response) =>{
            //console.log("Check other user return Data");
            //console.log(response);
            dispatch({type : ActionTypes.OTHER_USER_NAME_RET,
              data : response.data,
              domain : domain,
              otherusername : usertocheck
            })
    })
    .catch( (e) =>  {
      console.log("Error in getUserPub");
    dispatch(sendError(e));
    //console.log("Error from check fed : ") ;
    //console.log(e);
       }   )
    }
}

export const checkfed = (usertocheck,domain='zac.org.za',setPubK=false) => { 
        
        return (dispatch) => {
        //    https://api.zac.org.za/
        axios.get(FEDHOST +'/federation?type=name&q='+usertocheck+'*' +domain + '&extended=1' ,
        { 
            q : usertocheck
         } )
        .then( 
            (response) =>{
          //      console.log("Retrun Data");
                //console.log(response);
                dispatch({type : ActionTypes.CHECK_ZAC_NAME_RET,
                  data : response.data,
                  domain : domain,
                  setPubK: setPubK,
                  newzacname : usertocheck

                })
        })
        .catch( (e) =>  {
        dispatch(sendError(e));
       // console.log("Error from check fed : ") ;
        //console.log(e);
           }   )
        }
}

export const addfed = (zacrecord) => { 
    //console.log("addfed");
    //console.log(zacrecord);
    //https://api.zac.org.za/
    return (dispatch) => {
    axios.post(FEDHOST+'/federation_add',
       zacrecord )
    .then( 
        (response) =>{
            dispatch({type : ActionTypes.ADD_ZAC_NAME_RET,
              data : response.data,
              newzacname : zacrecord
            })
    })
    .catch( (e) => {
    dispatch(sendError(e));
    console.log("Error from federation add: " + e);  
      }  )
    }
}

export const changeSimplePriv = (enc) => {
  // TODO HOW TO DELETE OLD PASSWORD???
}

export const changePriv = (stelkp) => {
  return (dispatch) => {
    axios.post(FEDHOST+'/change_priv',stelkp
    )
    .then(
        (response) =>{
             //This is not a ERROR Successful Message
            dispatch(sendTxtInfo("Password Changed Successfully!"))
        })
    .catch( (e) => 
    {
        dispatch(sendError(e));
        console.log("Error from Change Priv : " + e) ;}   )
}
}

export const addSPriv = (enc) => {
  return (dispatch) => {
      axios.post(FEDHOST+'/add_spriv',enc
      )
      .then(
          (response) =>{
            dispatch({type : ActionTypes.CHANGE_PRIV_RET,
                data : response.data
              });
            }
       )
      .catch( (e) => 
      {
          dispatch(sendError(e));
          console.log("Error from Add SPriv : " + e) ;}   )
  }
}

export const newSPriv = (enc) => {
  return (dispatch) => {
    axios.post(FEDHOST+'/add_spriv',enc
    )
    .then(
        (response) =>{
          dispatch({type : ActionTypes.ADD_PRIV_RET,
              data : response.data
            });
          }
     )
    .catch( (e) => 
    {
        dispatch(sendError(e));
        console.log("Error from Add SPriv : " + e) ;}   )
}
}


export const addPriv = (stelkp) => {
    return (dispatch) => {
        axios.post(FEDHOST+'/add_priv',stelkp
        )
        .then(
            (response) =>{
                dispatch({type : ActionTypes.ADD_PRIV_RET,
                  data : response.data
                })
            })
        .catch( (e) => 
        {
            dispatch(sendError(e));
        console.log("Error from Add Priv : " + e) ;}   )
    }
}

export const getSimplePriv = (inprops) => {
  return  (dispatch) => {
    axios.post(FEDHOST+'/get_simple_priv',{
        key:   inprops.key
    })
      .then((response) => {
          if (response.data.error) {
            dispatch(sendTxtError(response.data.error));
          } else {
          dispatch({type : ActionTypes.GET_SIMPLE_PRIV_RET,
            data : 
              { ct: response.data.ct,
                pub: inprops.pub, 
                pass: inprops.pass
              }
          });
        }
      }
      ) 
      .then( () =>  {
        dispatch({type : SagaActions.GET_PAYMENT_ARRAY_INIT,
          pubK: inprops.pub
        } );
      }
      )
      .catch( (e) => 
      {
        console.log("Get Priv Error : " + e);
        dispatch(sendError(e));
      })
  }
}

export const getPriv = (privData) => {
    const pubK = {
        "pub": privData.pub,
        "zacname": privData.zac_name,
    }
    return  (dispatch) => {
        axios.post(FEDHOST+'/get_pri',pubK)
          .then((response) => {
          //  console.log("Then1");
            dispatch({type : ActionTypes.GET_PRIV_RET,
              data : response.data,
              pass: privData.pass
            })
          }
          )
          .then( () =>  {
          //console.log("Then2");
          //console.log("Error in getUserPub")
          //dispatch({type : ActionTypes.AFTER_PRIV_RETURN} );
          dispatch({type : SagaActions.GET_PAYMENT_ARRAY_INIT,
            pubK: privData.pub
          } );
          
        }
        )    
          .catch( (e) => 
          {
            console.log("Get Priv Error : " + e);
            dispatch(sendError(e));
          })
    }
  }

 export const fundTestAccount = (pubKTest) => {
   //console.log("Run Fund Test:" + pubKTest);
   return (dispatch) => {
       axios.get('https://friendbot.stellar.org?addr=' + pubKTest)
       .then((response) =>{
          //console.log("Funded Account");
          //console.log(response);
          dispatch(setCheckAccount(1));
       }
       )
       .catch( (e) =>     {
        console.log("fundTestAccount Error : " + e);   
        dispatch(setCheckAccount(-1));
       }
       )
   }
  }

  export const getToZacNamePubK = (toZacName,domain) => {
      return (dispatch) => {
      //    https://api.zac.org.za/
      axios.post(FEDHOST +'/federation?type=name&q='+toZacName+'*' +domain ,
      { 
          q : toZacName
       } )
      .then( 
          (response) =>{
//              console.log("Get Zac To Pay");
              //console.log(response);
              if (response.data.account_id === 'DOES_NOT_EXIST') {
                dispatch({type : ActionTypes.CLEAR_ZAC_TO_PAY_NAME
                });
                dispatch(sendTxtError("User not found"));
              } else {
              dispatch({type : ActionTypes.SET_ZAC_TO_PAY_NAME,
                toPayAccount : response.data.account_id,
                toPayStellar : response.data.stellar_address
              });
            }
              
      })
      .catch( (e) =>  {
        console.log("Error from check fed : ") ;
        console.log(e);
        dispatch(sendError(e));
         }   )
      }
}

export const getFundZacNamePubK = (toZacName,domain) => {
  return (dispatch) => {
  axios.post(FEDHOST +'/federation?type=name&q='+toZacName+'*' +domain ,
  { 
      q : toZacName
   } )
  .then( 
      (response) =>{
          if (response.data.account_id === 'DOES_NOT_EXIST') {
            dispatch({type : ActionTypes.CLEAR_ZAC_FUND_NAME
            });
            dispatch(sendTxtError("User not found"));
          } else {
          dispatch({type : ActionTypes.SET_ZAC_FUND_NAME,
            toFundAccount : response.data.account_id,
            toFundStellar : response.data.stellar_address
          });
          dispatch(doCheckFundAcc(response.data.account_id));
        }
          
  })
  .catch( (e) =>  {
    console.log("Error from check fed : ") ;
    console.log(e);
    dispatch(sendError(e));

     }   )
  }
}
//export  default { checkfed, addfed} ;
