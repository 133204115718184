import React  from 'react';
import { Grid, Typography, Slider,Paper,CardContent,Card} from '@material-ui/core';
const passStrenth = [
    "Very Poor",
    "Weak",
    "OK",
    "Good",
    "Strong"
  ];
const getAriaLabel = (val) => {
    return passStrenth[val];
}
const getAriaValueText = (value, index) => 
{  
    //console.log("From getAria" + value + " INDEX :" + index);
    //console.log(passStrenth[value]);
    return passStrenth[value]; }

const PasswLevel = (props) =>{
    const makeSugestions =() => {
        let keyCount = 0;
        return props.passCheck.suggestions.map(   sug => {
            keyCount++;
            return (   <Grid item key={"SUG" + keyCount} > 
                <Typography variant="caption" className={props.classes.title} color="secondary" gutterBottom>
                    {sug}
                </Typography>
                </Grid>
            )
        }
            )
    }

    return <Grid container
                 direction="column"
                 justify="center"
                 alignItems="center"
               >
                <Card className={props.classes.card}>
                 <CardContent>
                  <Grid item>
    <Paper className={props.classes.paperfour}>
        {props.passCheck.warning ?
            <Typography variant="caption" className={props.classes.title} color="error" gutterBottom>
                {props.passCheck.warning}
            </Typography> : ""
        }
        {makeSugestions()}
        <br/>
        <br/>
        <Slider
         value={props.passCheck.score}
  defaultValue={0}
  getAriaValueText={getAriaValueText}
  step={1}
  marks
  min={0}
  max={4}
  style={{"width" : "150"}}
  valueLabelDisplay="on"
  aria-label="Password Strength"
  aria-labelledby="pass-strength-slider"
/>
<Typography id="pass-strength-slider" gutterBottom>
  Password Strength
</Typography>
</Paper>
</Grid>
</CardContent>

</Card>
</Grid>;

}

export default PasswLevel;