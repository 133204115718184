import React, {useState, useEffect } from 'react';
import withWidth from '@material-ui/core/withWidth';
import {connect} from 'react-redux'
import { withStyles} from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Input from '@material-ui/core/Input';
import * as actionTypes from '../../Actions/types';
import CheckIcon from '@material-ui/icons/CheckCircle';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { checkfed,addfed,newSPriv,sendTxtError }  from '../../Actions/thunkActions';
import SearchIcon from '@material-ui/icons/Search';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
//import sjcl from 'sjcl';
import { Redirect } from 'react-router-dom';
import { styles } from '../layout/theme';
import { Stepper,Step,StepLabel,Button,Typography,FormHelperText,FormControl  } from '@material-ui/core';
import { InputAdornment,Grid,TextField,InputLabel,Card,CardContent,CardHeader,Checkbox,FormControlLabel} from '@material-ui/core/';
import {RE_ASCII_LC_AND_AT} from '../../utils/globalvar';
import zxcvbn from 'zxcvbn';
import PasswLevel from '../../components/PasswLevel';
import { GiHorizontalFlip } from 'react-icons/gi';
import { doEncrypt} from '../../utils/myCrypt';



function getSteps() {
    return ['Start','Test / Production', 'Create / Insert Keys', 'Choose Name','Password','Verify'];
  }


 const ZeroStartStep = (props) => {
    return (
        <Card className={props.classes.card} >
            <Typography variant="h6" className={props.classes.title} color="textSecondary" >
              Info
            </Typography>
        <CardContent>
            <Typography variant="body1">
              If you have an existing Pre-Created Account you can opt to your pre-existing account by unchecking the box below.<br />
            </Typography>
        <Typography variant="body1" >
              If not then you can choose to create a new one. If you are unsure then generate new keys.<br />
        </Typography>
        <Typography variant="body1">
              <FormControlLabel control={<Checkbox checked={props.createAcc} onChange={props.handleCheck} name="createCB" color="primary" />} label="Generate New Keys" />
        </Typography>
          </CardContent>
        </Card>
    )
 }


 const DomainStep = (props) => {
   console.log(props.zacDomain);
   return (
      <Card className={props.classes.card}>
        <CardContent>
        <Typography variant="h6" className={props.classes.title} color="textSecondary">
          Test / Production
        </Typography>
        <Typography variant="body1" >
            ZAC has a test site so you can create a test account and play around. Choose if you want a real account or a test one.<br />
        </Typography>
       <Select className={props.classes.select}
         onChange={event => {
           props.doSetDomain(event.target.value);
         }} spacing={2}
         input={<Input name="domain" id="zac-domain" value={props.zacDomain} />}
       >
         <MenuItem value={'zac.org.za'}>zac.org.za</MenuItem>
         <MenuItem value={'test.zac.org.za'}>test.zac.org.za</MenuItem>
       </Select>
      </CardContent>
    </Card>
   )
 };

 const KeyStep = (props) => {

  return (
      <Card className={props.classes.card}>
        <CardContent>
          <Typography variant="h6" className={props.classes.title} color="textSecondary" gutterBottom>
            Account Keys
          </Typography>
          <Typography variant="body1" component="span">
            Crypto currency is based on 2 Keys. Both are required for a transaction.<br />
            The Public Key is used by everyone. It is like your bank account number in crypto terms.<br />
            The Private Key is the security key. If someone gets hold of this key your account has then been compromised, so keep it very safe.<br/>
            A good idea is to write it down or print it out and hide it.
          </Typography>
      <Card variant="outlined">
      <CardHeader subheader="Your New Account Keys" />
      <Grid item>
      <TextField
        value={props.publickey}
        onChange={e => {
          props.doChangePubK(
            { publickey: e.target.value }
          );
        }
        }
        className={props.classes.keyTextInput}
        name="publickey"
        id="publickey"
        label="Public Key (Everyone can view)"
        style={{ margin: 8 }}
        margin="normal"
        InputLabelProps={{
          shrink: true,
        }}
      />
    </Grid>
    <Grid item>
      <TextField
        value={props.privatekey}
        onChange={e => {
          props.doChangePrivK(
            { privatekey: e.target.value }
          );
        }
        }
        className={props.classes.keyTextInput}
        name="privatekey"
        id="privatekey"
        label="Private Key (Keep Safe)"
        style={{ margin: 8 }}
        margin="normal"
        InputLabelProps={{
          shrink: true,
        }}
      />
      </Grid>
      </Card>
    <Typography variant="subtitle2" >
            <b>WE DONT HAVE YOUR PRIVATE KEY. YOU NEED TO WRITE IT DOWN OR PRINT IT OUT AND KEEP IT SAFE.</b>
             <b>YOU WILL REQUIRE THIS PRIVATE KEY IF YOU FORGOT YOUR PASSWORD</b>
     </Typography>

    
    </CardContent>
    </Card>
  )
 }

 const NameStep = (props) => {

        const removeSpaceAndLc = (val)  => {
          let ret = val;
          if (val) {
            ret = val.toLowerCase().replace(/\s+/g, '');;
          }
          return ret;
        }
   // This  step is saving the public key with a name that u pick to simplify transactions. 
   return (
    <Card className={props.classes.card}>
                <Typography variant="h6" className={props.classes.title} color="textSecondary" gutterBottom>
            Name Your Account
          </Typography>
          <Typography variant="body1" component="span">
            To ease payments your account will be associated with a name you choose.<br />
            This field can have any Lower Case Letters and Dot(.) and At(@) characters so you can use your email.
          </Typography>
   <Grid item>
   <TextField
   name="newzacname"
   id="newzacname"
   label="ZAC Account Name"
   style={{ margin: 8 }}
   placeholder={props.placeholderText}
   className={props.classes.textField}
   helperText={props.varHelperText}
   margin="normal"
   error={!props.zacNameAvail}
   InputLabelProps={{
       shrink: true,
   }}
   value={props.newzacname}
   onChange={event => {
           props.setnewzacname(removeSpaceAndLc(event.target.value));
         }}
   InputProps={{
       endAdornment: (
         <InputAdornment position="end">
           {props.zacNameAvail ? (<CheckIcon /> ) : (<ErrorOutlineIcon />)}
         </InputAdornment>
       ),}}
   
   />
                       <Grid item>
                    <Button  className={props.classes.button} variant="contained" color="primary" size="small" onClick={  () =>  {
                            if (RE_ASCII_LC_AND_AT.test(props.newzacname)) {
                              props.locCheckZacName(props.newzacname, props.zacDomain);
                          } else {
                            props.doSendError("Some Character in the name is not allowed. Please change." );
                          }
                      }}
                      endIcon={<SearchIcon />}
                      >Check if Name is  Available</Button>            
                    </Grid>

</Grid>     
</Card>                       
   )
 }

 const PasswStep = (props) => {
   return(
    <Card className={props.classes.card}>
      <CardContent>
      <Typography variant="h6" className={props.classes.title} color="textSecondary" gutterBottom>
            Encrypt your Private Key
          </Typography>
          <Typography variant="body1" component="span">
            In this step we encrypt your private key using <a href="https://en.wikipedia.org/wiki/Advanced_Encryption_Standard">AES</a> <br />
            We dont force you to choose a difficult password, it is your responsibility to keep your account safe.<br />
            If you are planning to have small amounts in your account you can choose a simple password, if not make it at least 8 characters with special characters.
          </Typography>

  <Grid item>
  <InputLabel htmlFor="adornment-password">Password</InputLabel>
  <Input
    id="adornment-password"
    type={props.showPassword ? 'text' : 'password'}
    value={props.password}
    onChange={(e) => {
      props.setPassword(e.target.value);
    }
    
  }
    endAdornment={
      <InputAdornment position="end">
        <IconButton
          aria-label="Toggle password visibility"
          onClick={(e) => {
            props.setshowPassword(!props.showPassword);
          }}
        >
          {props.showPassword ? <Visibility /> : <VisibilityOff />}
        </IconButton>
      </InputAdornment>
    }
  />
</Grid>

<br/>
                <Grid item>
                {(props.passCheck.score !== -1)?
                <PasswLevel {...props} />
                :""}
                </Grid>

</CardContent>
</Card>
)
 }

 const VerifyPass = (props) => {
   const checkPass = () => {
   if (props.password!==props.otherPassword)  {
      return "Passwords do not match";
   } else {
        return "";
   }
  }

  return(
   <Card className={props.classes.card}>
     <CardContent>
     <Typography variant="h6" className={props.classes.title} color="textSecondary" gutterBottom>
           Verify Password
         </Typography>
 <Grid item>
 <FormControl>
    <InputLabel htmlFor="adornment-password"
      error={props.password!==props.otherPassword}>
        Password
    </InputLabel>
 <Input
   id="adornment-password"
   type={props.showPassword ? 'text' : 'password'}
   value={props.password}
   onChange={(e) => {
     props.setPassword(e.target.value);
   }
 }
   endAdornment={
     <InputAdornment position="end">
       <IconButton
         aria-label="Toggle password visibility"
         onClick={(e) => {
           props.setshowPassword(!props.showPassword);
         }}
       >
         {props.showPassword ? <Visibility /> : <VisibilityOff />}
       </IconButton>
     </InputAdornment>
   }
 error={props.password!==props.otherPassword}>
 </Input>
 <FormHelperText id="var-pass-help-text">{checkPass()}</FormHelperText>
 </FormControl>
</Grid>
</CardContent>
</Card>
)
}


const NewAccStep = React.memo(props => {
    const [newzacname, setnewzacname] = useState("");
    const [showPassword, setshowPassword] = useState(false);
    const [verShowPassword, setverShowPassword] = useState(false);
    const [ password, setPassword ] = useState("");
    const [ verPassword, setVerPassword ] = useState("");
    const [activeStep, setActiveStep] = React.useState(0);
    const [skipped, setSkipped] = React.useState(new Set());
    const [ disableNext, setDisableNext] = useState(false);
    const [ passCheck, setPassCheck ] = useState(
      {  score: -1,
          warning: "",
          suggestions : []
   });

   const doPasswordChange  = ( val ) => {
    if (val.length > 1) {
        const psswScore = zxcvbn(val);
        setDisableNext(psswScore.score<2);
        setPassCheck( {
            score: psswScore.score,
            warning: psswScore.feedback.warning,
            suggestions : psswScore.feedback.suggestions
        }
        );
    } else {
        setDisableNext(true);
        setPassCheck( {
            score: -1,
            warning: "",
            suggestions : []
        })
    }
    setPassword(val);
}


    const steps = getSteps();
    const { classes } = props;
    const [createAcc, setCreateAcc] = React.useState(true);
   // const [varHelperText, setVarHelperText] = React.useState(props.zacNameMessage);
    const placeholderText = "ZACAccount*" + props.zacDomain + " ie (myname@gmail.com*zac.org.za)";
  
    const initDoSetDomain = () => {
      props.doSetDomain('zac.org.za');
    }

    useEffect(() => {
      initDoSetDomain(); // Use function to not have warning of calling props within the useEffect but not in the [] 
// eslint-disable-next-line
    }, []); // passing an empty array as second argument triggers the callback in useEffect only after the initial render thus replicating `componentDidMount` lifecycle behaviour
  
    useEffect( () => {
      if (props.zacNameAvail)  {
        setDisableNext(false);
      }
    },[props.zacNameAvail]);


  const onChangeVerify = (val) =>{
    if (val===password) {
      setDisableNext(false);
    } else {
      if (!disableNext) {
          setDisableNext(true);
      }
    }
    setVerPassword(val);

  }

  const locCheckZacName = (newzacname,zacDomain) => {
    console.log("Check zac name :" + newzacname +zacDomain );
    props.checkZacName(newzacname, zacDomain);
/*
    if (props.zacNameAvail) {
      setVarHelperText(props.zacNameMessage);
    } else {
      setVarHelperText("Sorry Name is taken. Pick an unique public accessible name for your zac account. You can even use your email.");
    }
    */
  }


    function getStepContent(step) {
        switch (step) {
          case 0:
            return <ZeroStartStep {...props}  createAcc={createAcc}
            handleCheck={handleCheck}  />;
          case 1:
            return <DomainStep {...props }/>;
          case 2:
           return <KeyStep {...props}  />;
          case 3:
            return <NameStep {...props} placeholderTPasswStepext={placeholderText} varHelperText={props.zacNameMessage} setnewzacname={setnewzacname} newzacname={newzacname} locCheckZacName={locCheckZacName} doSendError={props.doSendError}/>;
          case 4:
            return <PasswStep {...props} showPassword={showPassword} setshowPassword={setshowPassword} password={password} setPassword={doPasswordChange} passCheck={passCheck}  />
          case 5:
            return <VerifyPass {...props} showPassword={verShowPassword} setshowPassword={setverShowPassword} password={verPassword} setPassword={onChangeVerify} otherPassword={password} />
          default:
            return 'Unknown step';
        }
      }
    
    

    const handleCheck = event => {
        setCreateAcc(event.target.checked);
      };
    
     
    const isStepOptional = step => {
          return step === -1;
      };
      
    const isStepSkipped = step => {
          return skipped.has(step);
     };
      
    const handleNext = () => {
       let newSkipped = skipped;
          if (isStepSkipped(activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStep);
        }
      
        if (activeStep === 1) {
            if (createAcc) {
              props.doCreateAccount();
          }

          }
          if ((activeStep===2)  &&(!disableNext)) {
            setDisableNext(true && !props.zacNameAvail)  ;
          }
          if ((activeStep===3)  &&(!disableNext)) {
            setDisableNext(true);
          }

          if ((activeStep===4)  &&(!disableNext)) {
            setDisableNext(true);
          }

          if ( activeStep ===3 ) {
            if ((props.zacNameAvail) && (props.newZacName !==""))  { setActiveStep(prevActiveStep => prevActiveStep + 1); } 
                else {  props.doSendError({ message: "You need to choose a valid name and click on the Check button." }) }
          } else {
          setActiveStep(prevActiveStep => prevActiveStep + 1);
          }
          setSkipped(newSkipped);
        };
/*
        if (activeStep===5) {
          console.log(toString(activeStep) + password + "=>" +verPassword);
          if ((password !== verPassword))  {
              props.doSendError({ message: "Passwords do not match." })
          } 
        }
  */    
        const handleBack = () => {
          setActiveStep(prevActiveStep => prevActiveStep - 1);
          setDisableNext(false);
        };
      
        const handleSkip = () => {
          if (!isStepOptional(activeStep)) {
            // You probably want to guard against something like this,
            // it should never occur unless someone's actively trying to break something.
            throw new Error("You can't skip a step that isn't optional.");
          }
      
          setActiveStep(prevActiveStep => prevActiveStep + 1);
          setSkipped(prevSkipped => {
            const newSkipped = new Set(prevSkipped.values());
            newSkipped.add(activeStep);
            return newSkipped;
          });
        };
      
        const handleReset = () => {
          setActiveStep(0);
        };

        const  createNewZacAccount = () => {
          const newStelRec = { "stellar_id": props.publickey, "zac_name" : props.newZacName, "domain": props.zacDomain  };
          props.doSubmitZacAcc(newStelRec);
          const toEncrypt = doEncrypt(password,props.publickey, props.privatekey);
          toEncrypt.pub = props.publickey;
          props.doSubmitNewPriv(toEncrypt);
 

          /*
          const encryptedpri = sjcl.encrypt(password,props.privatekey);
          const parsedEnc = JSON.parse(encryptedpri);
          //console.log(parsedEnc);
    
          props.doSubmitNewPriv({
              "pub": props.publickey,
              "iv": parsedEnc.iv,
              "st": parsedEnc.salt,
              'ct': parsedEnc.ct
          });
          */
          
     };
    
     const checkLoggedIn = () => {
      return (props.isLoggedIn ? <Redirect to="/wallet" /> : null );
   }
      const isVertical = (curWidth) => {
        return curWidth==='xs'?"vertical":"horizontal";
      }
        return (
          <React.Fragment>
          {checkLoggedIn()}
          <div className={classes.steproot}>
            <Stepper activeStep={activeStep} orientation={isVertical(props.width)} >
              {steps.map((label, index) => {
                const stepProps = {};
                const labelProps = {};
                if (isStepOptional(index)) {
                  labelProps.optional = <Typography variant="caption">Optional</Typography>;
                }
                if (isStepSkipped(index)) {
                  stepProps.completed = false;
                }
                return (
                  <Step key={label} {...stepProps}>
                    <StepLabel {...labelProps}>{label}</StepLabel>
                  </Step>
                );
              })}
            </Stepper>
            <>
              {activeStep === steps.length ? (
                <>
                  <Typography className={classes.instructions}>
                  <Button className={classes.button} variant="contained" color="primary" size="small" onClick={createNewZacAccount}>Create Account as : {props.newZacName}</Button>            
                  </Typography>
                  <Button  variant="contained" color="secondary" size="small" onClick={handleReset} className={classes.button}>
                    Reset
                  </Button>
                  </>
              ) : (
                <>
                {getStepContent(activeStep,props)}
                    <Button disabled={activeStep === 0} onClick={handleBack} className={classes.button}>
                      Back
                    </Button>
                    {isStepOptional(activeStep) && (
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSkip}
                        className={classes.button}
                      >
                        Skip
                      </Button>
                    )}
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleNext}
                      className={classes.button}
                      disabled={disableNext}
                    >
                      {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                    </Button>
                    </>
              )}
            </>
          </div>
          </React.Fragment>
        );
    }
);

const mapStateToProps = (state) => {
    return {
        privatekey : state.accounts.privateKey,
        publickey : state.accounts.publicKey,
        zacNameMessage : state.accounts.zacNameMessage,
        zacNameAvail : state.accounts.zacNameAvail,
        zacDomain : state.accounts.zac_domain,
        zacName : state.accounts.zacName,
        newZacName : state.accounts.newZacName,
        isLoggedIn : state.accounts.isLoggedIn
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        doCreateAccount: () => {
                dispatch({ type: actionTypes.NEW_KP_TO_STORE });
            },
        doChangePubK: (pubk) => {
                dispatch(
                  {
                    type: actionTypes.CHANGE_PUB_K,
                    ...pubk
                  });
            },
        doChangePrivK: (privk) => {
                dispatch(
                  {
                    type: actionTypes.CHANGE_PRIV_K,
                    ...privk
                  });
            },
        checkZacName: (newZacName,domain) => {
                if (newZacName !== '') {
                dispatch(checkfed(newZacName,domain))
                }
              },
        doSetDomain: (newDomain) => {
                dispatch(actionTypes.setDomain(newDomain) );
            },
        doSubmitZacAcc: (newZACRecord) => {
              dispatch(addfed(newZACRecord));
              //dispatch(addfed({ "stellar_id": "GBJYXC5JJLZVKDAWBB4OUUAYXO32VJ6275SPFWE2PXYB3SCOEMRHGK52", "zac_name" : "HANO", "domain":"test.zac.org.za" }));
           },   
        doSubmitNewPriv: (privrecord) => {
            dispatch(newSPriv(privrecord)); 
         },
        doSendError: (err)  => {
          //console.log("Send Error : " + err);
         // dispatch(sendTxtError(err));
         dispatch({type : actionTypes.ZAC_NAME_ERROR,
                    err : err   });
        },
}
}



export default withWidth()(withStyles(styles)(connect(mapStateToProps,mapDispatchToProps)(NewAccStep)));