import React from 'react';
/*
import { green } from '@material-ui/core/colors';
*/
import { GiPayMoney } from 'react-icons/gi';
import PeopleIcon from '@material-ui/icons/People';
import AddBoxIcon from '@material-ui/icons/AddBox';
//import VpnKeyIcon from'@material-ui/icons/VpnKey'; //Old Keys
//import ReceiptIcon from '@material-ui/icons/Receipt'; //Old transcaction icon
//import SettingsEthernetIcon from '@material-ui/icons/SettingsEthernet'; // Old Pay Icon
import {IoIosFiling} from 'react-icons/io';
//import {FaBalanceScale} from 'react-icons/fa';
import RefreRefreshIcon from '@material-ui/icons/Refresh';
import {GiHouseKeys} from 'react-icons/gi'
import MoreVert from '@material-ui/icons/MoreVert';
import { MdTransferWithinAStation } from 'react-icons/md';
import { GiWallet} from 'react-icons/gi';
//import {FaCheckDouble,FaCheck } from 'react-icons/fa';
import {IoIosClose } from 'react-icons/io';
import MDDoneIcon from  '@material-ui/icons/Done'
import MDDoneAllIcon from  '@material-ui/icons/DoneAll'
import {GiTrade} from 'react-icons/gi';
import {IoIosQrScanner} from 'react-icons/io';
import {AiOutlineLogout} from 'react-icons/ai';
import {GiUpgrade} from 'react-icons/gi';
import {FaUserLock} from 'react-icons/fa';

import stellaricon from '../../styles/stellar.png';
import zacIcon32 from '../../styles/zac_logo32.png';
import zacIcon16 from '../../styles/zac_logo16.png';
import zacIcon from '../../styles/zac_logo101.png';
import zacIconInvert from '../../styles/zac_logo101_invert.png';
import zacCoin from '../../styles/zacCoin.png';
import {GiTicket} from 'react-icons/gi';
import anchorUSD from '../../styles/achorusd.png';

import ExpandMore from '@material-ui/icons/ExpandMore';
//import { ReactComponent as zacWaitLogo } from '../../styles/ZacWaitLogoAnimate.svg';

/*
const getMDIcon = () => {
    return <MDDoneIcon  style={{ color: green[500] }} />
}

*/
const  zacCoinImg = (props) => {
    // Import result is the URL of your image
    return <img width={props.size} height={props.size} src={zacCoin} alt="ZAC Coin" />;
  }

export const PayIcon = GiPayMoney;
export const LoginIcon = PeopleIcon;
export const LogoutIcon = AiOutlineLogout;
export const NewAccountIcon = AddBoxIcon;
export const KeysIcon = GiHouseKeys; //VpnKeyIcon;
export const TransactionsIcon = IoIosFiling; // ReceiptIcon;
export const PaymentIcon = GiPayMoney;
export const BalanceIcon = GiWallet; //FaBalanceScale;
export const RefreshIcon = RefreRefreshIcon;
export const MenuIcon = MoreVert;
export const FundAccountIcon = MdTransferWithinAStation;
export const SingleCheckIcon = MDDoneIcon;
export const DoubleCheckIcon = MDDoneAllIcon;
export const CrossIcon  = IoIosClose;
export const TradeIcon = GiTrade; 
export const Qr = IoIosQrScanner;
export const LevelOneIcon = GiUpgrade;
export const Stellaricon = stellaricon;
export const ZacIcon32 = zacIcon32;
export const ZacIcon16 = zacIcon16;
export const ZacIcon = zacIcon;
export const ZacIconInvert = zacIconInvert;
export const ZacCoin = zacCoin;
export const USDImg = anchorUSD;
export const ZacCoinImg = zacCoinImg;
export const ChangePass = FaUserLock;
export const VoucherIcon = GiTicket;
export const ExpandMoreIcon = ExpandMore;
//export const ZacWaitLogo = zacWaitLogo;



