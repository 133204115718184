import React from 'react';
import {FundAccountIcon,MenuIcon} from '../pages/layout/Gobalicons';
import {Grid,Typography,Button} from '@material-ui/core';
import BankDetails from '../components/BankDetails';
import ZacAccordian from '../components/ZacAccordion';

const AccountNotActive = (props) => {
       return <React.Fragment>
            <Grid container
              direction="row"
              justify="center"
              alignItems="center"
            >
              <Grid item xs={10} >
              <Typography variant="subtitle1" align="center"><b>Account Not Active</b></Typography>
              <Typography variant="subtitle1" align="center"><b>To activivate do one of the following:</b></Typography>
              </Grid>

              <Grid item xs={10} >
              <ZacAccordian heading="Activate yourself or by someone that does have an active account" body={(props) =><>
                        <Typography variant="body1" component="span">
                                Ask someone who has a ZAC account to fund it by clicking on the<b> Menu icon 
                                <MenuIcon /></b> and then in the <b>Fund Another Account <FundAccountIcon /></b>
                            </Typography>
                            </>} {...props} />
              </Grid>
              <Grid item xs={10}>
                    <ZacAccordian heading="Buy ZAC by EFT" body={(props) =><>
                            <Typography variant="body1" component="span">The ZAC Amount should reflect the deposit amount less R20 cost for the Lumens. Cash deposits the bank changes will be deducted aswell</Typography>
                            <br />
                            <BankDetails depositref={props.depositref} classes={props.classes} />
                    </> } {...props} />
              </Grid>
                <Grid item xs={10} >
                  <Button variant="contained" color="secondary" onClick={ 
                              event => {
                                  props.history.push('/showkeys');
                            }}>Show Keys</Button>

                <Button variant="contained" color="primary" onClick={() => {
                            window.print();
                        }
                         }>PRINT</Button>
                </Grid>
                <Grid item xs={10} >
                  <Typography variant="body1" component="span">
                      VERY IMPORTANT. Please PRINT or write down your PRIVATE key on a piece of paper.<br />
                      Login in with your private key is the ONLY way to recover LOST passwords.<br />
                      We CAN NOT reset your password because WE DONT have your PRIVATE KEY </Typography>
                 </Grid>
                      <Grid item xs={10} >
                      <Typography variant="body1" component="span">
                  If this is a new account the cost of a new account is R20 and a minimum ZAC you can buy is R200.<br />
                   The R20 will be allocated to your account as 2 Lumens, and the remaining R180 will be issued in ZAC.<br />
                   You may buy for opening the account a maximun of R10 000.00<br />
                   Bank Fees for cash deposits will also be deducted
                   </Typography>
                </Grid>

                </Grid>

        </React.Fragment>  ;
        
  };

export default AccountNotActive;