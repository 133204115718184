import * as actionTypes from '../Actions/types';
import * as actionUserTypes from '../Actions/types/usertypes';

const initialState = {
    firstname : "",
    surname : "",  
    country_id : "", //Set to ZA after 
    email : "",
    rsa_id : "",
    passport_no : "",
    bank : "",
    branch_code : "",
    account_no : "",
    account_type : "",    
    countries : [],
    suburb: "",
    city: "",
    code: "",
    phone: "",
    country_prefix: "",
    err_obj: { 
     
     }
}

const userRecuder = (state=initialState,action) => {
    let newstate = { ...state};
    switch (action.type) {

            case actionUserTypes.ADD_LVL_ONE_VALIDATION_RET: {
              //arr.reduce(callback( accumulator, currentValue[, index[, array]] )[, initialValue])
              //            console.log(action);
              var err_obj = {
                noerr: 'noerr'
              }
              if (action.err_arr.length > 0) {
                // Create Error JS object from array
                    err_obj = action.err_arr.reduce((acc, aErr, i)  => {
                                  return {
                                    ...acc,
                                    [aErr.path]: aErr.message
                                  }
                    }, {});
            }
              newstate = {
                ...state,
                err_obj: err_obj
              }
              return newstate;
            }
          case actionUserTypes.USER_FIELD_CHANGE: {
            //console.log([action.field]);
            //console.log(action.value);
            newstate = {
              ...state,
              [action.fieldChanged] : action.newValue,
              err_obj: { 
              }
            };
              // If coutry change change the corrosponding country tel prefix aswell
              if (action.fieldChanged[0] === 'country_id') {
                  const country_prefix =  state.countries.filter( c => c.country_code ===action.newValue)[0].phonecode
                  newstate = {
                    ...newstate,
                    country_prefix : country_prefix
                  }
              }
            return newstate;
          }
          case actionTypes.LOGOUT:{
            return initialState;
          }
          case actionTypes.COUNTRIES_RETURN :{
            newstate = {
              ...state,
              country_id: 'ZA', // Default the countirs to SOUTH ARICA
              country_prefix: '27',
              countries:  action.data
            };
            return newstate;
          }
          case actionUserTypes.GET_PHONE_RET: {
            newstate = {
              ...state,
              phone: action.data.phone
            }
            return newstate;
          }
          default:
            return newstate
    }

    
}

export default userRecuder;