import { createMuiTheme ,responsiveFontSizes } from '@material-ui/core/styles';
import { pink, green, deepPurple } from '@material-ui/core/colors';
import zacfonts from './zacfonts.css';
/*  
#00263b
#00a1ab
#6f0000
#ff5200
#0047cc
#343f43

        light: #63ccff,
        main: #009be5,
        dark: #006db3,
      light: '#63ccff',
      main: '#0047cc',
      dark: '#002d80'

*/


let theme_loc =  createMuiTheme({
  palette: {
    primary: {
      light: '#63ccff',
      main: '#0047cc',
      dark: '#002d80',
    },
    secondary: {
      light: '#ff4747',
      main: '#cc0000',
      dark: '#910101'
    },
  },
  typography: {
    fontFamily: 'Montserrat',
    h5: {
      fontWeight: 500,
      fontSize: 26,
      letterSpacing: 0.5,
    },
  },
  shape: {
    borderRadius: 8,
  },
  props: {
    MuiTab: {
      disableRipple: true,
    },
  },
  mixins: {
    toolbar: {
      minHeight: 48,
    },
  },
});

theme_loc = {
  ...theme_loc,
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': [
          'Montserrat'
      ]
      },
    },
    MuiDrawer: {
      paper: {
        backgroundColor: '#18202c',
      },
    },
    MuiButton: {
      label: {
        textTransform: 'none',
      },
      contained: {
        boxShadow: 'none',
        '&:active': {
          boxShadow: 'none',
        },
      },
    },
    MuiTabs: {
      root: {
        marginLeft: theme_loc.spacing(1),
      },
      indicator: {
        height: 3,
        borderTopLeftRadius: 3,
        borderTopRightRadius: 3,
        backgroundColor: theme_loc.palette.common.white,
      },
    },
    MuiTab: {
      root: {
        textTransform: 'none',
        margin: '0 16px',
        minWidth: 0,
        padding: 0,
        [theme_loc.breakpoints.up('md')]: {
          padding: 0,
          minWidth: 0,
        },
      },
    },
    MuiIconButton: {
      root: {
        padding: theme_loc.spacing(1),
      },
    },
    MuiTooltip: {
      tooltip: {
        borderRadius: 4,
      },
    },
    MuiDivider: {
      root: {
        backgroundColor: '#404854',
      },
    },
    MuiListItemText: {
      primary: {
        fontWeight: theme_loc.typography.fontWeightMedium,
      },
    },
    MuiListItemIcon: {
      root: {
        color: 'inherit',
        marginRight: 0,
        '& svg': {
          fontSize: 20,
        },
      },
    },
    MuiAvatar: {
      root: {
        width: 32,
        height: 32,
      },
    },
  },
};


export let theme = responsiveFontSizes(theme_loc);

theme.typography.body1 = {
  fontSize: '0.7rem',
  '@media (min-width:600px)': {
    fontSize: '0.9rem',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '1rem',
  },
};

export const drawerWidth = 256;


export const styles = {
  root: {
    display: 'flex',
    minHeight: '100vh',
    margin: theme.spacing(1),
    padding: theme.spacing(2),
    fontFamily: '"Baloo Chettan 2"'
  },
   accordianheading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },

  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  categoryHeader: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  categoryHeaderPrimary: {
    color: theme.palette.common.white,
  },
  item: {
    paddingTop: 1,
    paddingBottom: 1,
    color: 'rgba(255, 255, 255, 0.7)',
    '&:hover,&:focus': {
      backgroundColor: 'rgba(255, 255, 255, 0.08)',
    },
  },
  itemCategory: {
    backgroundColor: '#232f3e',
    boxShadow: '0 -1px 0 #404854 inset',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  firebase: {
    fontSize: 24,
    color: theme.palette.common.white,
  },
  itemActiveItem: {
    color: '#4fc3f7',
  },
  itemPrimary: {
    fontSize: 'inherit',
  },
  itemIcon: {
    minWidth: 'auto',
    marginRight: theme.spacing(2),
  },
  divider: {
    marginTop: theme.spacing(2),
  },
  app: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  main: {
    flex: 1,
    padding: theme.spacing(6, 4),
    background: '#eaeff1',
  },
  footer: {
    padding: theme.spacing(2),
    background: '#eaeff1',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    margin: 'auto',
    width: 'fit-content',
  },
  formControl: {
    marginTop: theme.spacing(2),
    minWidth: 120,
  },
  formControl2: {
    margin: theme.spacing(1),
    minWidth: 250,
  },
  margin: {
    margin: theme.spacing(1),
  },
  formControlLabel: {
    marginTop: theme.spacing(1),
  },
  keyTextInput: {
    margin: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      width: 320,
    },
    [theme.breakpoints.up('sm')]: {
      width: 680,
    },

  },
  formTextInput: {
    margin: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      width: 150,
    },
    [theme.breakpoints.up('md')]: {
      width: 250,
    },
  },
  paper: {
    margin: theme.spacing(2),
  },
  cardwiz: {
    margin: theme.spacing(2),
    height: theme.spacing(16)
  },
  currcard: {
    padding: theme.spacing(2),
    margin: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      //backgroundColor: theme.palette.secondary.main,
      backgroundColor: theme.palette.background.paper,
      width: 300,
    },
    [theme.breakpoints.up('md')]: {
      width: 500,
      //backgroundColor: theme.palette.primary.main,
      backgroundColor: theme.palette.background.paper,
    },
    raised: true
  },
  paperfour:{
    width: 400,
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 4, 3),
  },
  paperprint: {
    position: 'absolute',
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
  purple: {
    color: theme.palette.getContrastText(deepPurple[500]),
    backgroundColor: deepPurple[500],
  },
  pink: {
    color: theme.palette.getContrastText(pink[500]),
    backgroundColor: pink[500],
  },
  green: {
    color: '#fff',
    backgroundColor: green[500],
  },
  button: {
    padding: theme.spacing(1),
    margin: theme.spacing(1),
  },
  instructions: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  steproot: {
    width: '100%',
  },
  intoText: {
    //fontFamily: '"Baloo Chettan 2"',
    fontFamily: 'Oswald',
    fontStyle: 'bold',
    fontDisplay: 'swap',
    fontWeight: 400,
  }

};


export const navstyles = theme => ({
  categoryHeader: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  categoryHeaderPrimary: {
    color: theme.palette.common.white,
  },
  item: {
    paddingTop: 1,
    paddingBottom: 1,
    color: 'rgba(255, 255, 255, 0.7)',
    '&:hover,&:focus': {
      backgroundColor: 'rgba(255, 255, 255, 0.08)',
    },
  },
  itemCategory: {
    backgroundColor: '#232f3e',
    boxShadow: '0 -1px 0 #404854 inset',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
  firebase: {
    fontSize: 24,
    color: theme.palette.common.white,
  },
  itemActiveItem: {
    color: '#4fc3f7',
  },
  itemPrimary: {
    fontSize: 'inherit',
  },
  itemIcon: {
    minWidth: 'auto',
    marginRight: theme.spacing(2),
  },
  divider: {
    marginTop: theme.spacing(2),
  },
});

export const headstyles = theme => ({
  secondaryBar: {
    zIndex: 0,
  },
  menuButton: {
    marginLeft: -theme.spacing(1),
  },
  iconButtonAvatar: {
    padding: 4,
  },
  link: {
    textDecoration: 'none',
    color: lightColor,
    '&:hover': {
      color: theme.palette.common.white,
    },
  },
  button: {
    borderColor: lightColor,
  },
});

export const lightColor = 'rgba(255, 255, 255, 0.7)';

