import React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Grid from '@material-ui/core/Grid';
//import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
//import MenuIcon from '@material-ui/icons/Menu';
import NotificationsIcon from '@material-ui/icons/Notifications';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import Badge from '@material-ui/core/Badge';
import { headstyles } from './theme';
//import VPNKeyIcon from '@material-ui/icons/VpnKey';

import {connect} from 'react-redux';
//import { resetTranCount} from '../../Actions/index';
import * as actionTypes from '../../Actions/types';
//import loginsvg from '../../styles/log-in-outline.svg'
//import SvgIcon from '@material-ui/core/SvgIcon';
import {LoginIcon, NewAccountIcon, MenuIcon } from '../layout/Gobalicons';
import ZacMenu from '../../components/ZacMenu';
import { ZacIconInvert } from '../layout/Gobalicons';
//import  {useParams } from "react-router-dom";
/*
function LoginIcon(props) {
  return (
    <SvgIcon {...props}>
         component={loginsvg}
    </SvgIcon>
  );
}
*/

const Header = React.memo(props => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [menuOpen, setMenuOpen] = React.useState(false);
  const { classes } = props;
  
  const getQueryStringParams = query => {
    return query
        ? (/^[?#]/.test(query) ? query.slice(1) : query)
            .split('&')
            .reduce((params, param) => {
                    let [key, value] = param.split('=');
                    params[key] = value ? decodeURIComponent(value.replace(/\+/g, ' ')) : '';
                    return params;
                }, {}
            )
        : {}
};

  const headerDsp = () => {
    if (props.location.search) {
    //  console.log(getQueryStringParams(props.location.search));
    let { 
      receiver,amount,comment
     } = getQueryStringParams(props.location.search);
       props.history.push('/to-pay/'+receiver+'/' + amount + '/' + comment);
    }

    switch (props.location.pathname) {
      case '/login': return "Login";
      case '/tran': return "Transactions";
      case '/new': return "Create Account";
      case '/wallet': return "Wallet /  Balances";
      case '/pay': return "Payment";
      default: return "ZAC";
    }
    /*
    if (props.location.pathname) {
      return props.location.pathname;
    } else {
      return "ZAC";
    }
    */
  };

  const doResetAndDirect = ()  => {
     props.doReset();
     props.history.push('/tran');
   }


   const openMenuClick = (event) => {
     setAnchorEl(event.currentTarget);
     setMenuOpen(!menuOpen);
   };
 
   const showName = () => {
     if (props.isLoggedIn) {
        if (props.zacName.indexOf("*") > 0)  {
          return props.zacName;
        } else { 
            return props.zacName + "*"  + props.zacDomain;
        }
      } else return "Not Logged In";
   }

  return (
    <React.Fragment>
      <AppBar color="primary" position="sticky" elevation={2}>
        <Toolbar>
          <Grid container spacing={1} alignItems="center"   direction="row"   justify="center">
            <Grid item xs={1}>
               <img src={ZacIconInvert} alt="ZACLogo" />
              </Grid>
            <Grid item xs={8} >
              <Typography color="inherit" variant="h6" component="h1">
                {headerDsp()}
              </Typography>
              <Typography color="inherit" variant="caption" component="h1">
                {showName()}
              </Typography>
            </Grid>

            <Grid item xs={3}>
{props.isLoggedIn ?
            <Tooltip title="Account Alert">
                  <IconButton color="inherit" disabled={!props.isLoggedIn} size="small" onClick={doResetAndDirect}>
                    <Badge className={classes.margin} badgeContent={props.newTransactions} color="secondary">
                      <NotificationsIcon />
                    </Badge>
                  </IconButton>
                </Tooltip>
                : null}
{!props.isLoggedIn ?                
                <Tooltip title="New Account">
                  <IconButton color="inherit" size="small" onClick={() => props.history.push('/new')}>
                    <NewAccountIcon />
                  </IconButton>
                </Tooltip>
                : null}
                <Tooltip title="Login">
                <IconButton color="inherit" size="small" onClick={() => props.history.push('/login')}>
                   <LoginIcon />
                </IconButton>
                </Tooltip>
                <IconButton color="inherit" size="small" onClick={(event) =>  {
                    openMenuClick(event);
                }
                 }>
                   <MenuIcon  />
                   <ZacMenu open={menuOpen} doLogout={props.doLogout} anchorEl={anchorEl} {...props} />               
                </IconButton>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
    </React.Fragment>
  );
});

Header.propTypes = {
  classes: PropTypes.object.isRequired,
//  onDrawerToggle: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return {
      isLoggedIn : state.accounts.isLoggedIn,
      zacName : state.accounts.zacName,
      zacDomain : state.accounts.zac_domain,
      newTransactions : state.accounts.newTransactions,
      pubIsValid : state.accounts.pubIsValid,
      acc_level : state.accounts.acc_level,
      isLoggedIn : state.accounts.isLoggedIn,
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
      doReset: () => { dispatch({type : actionTypes.RESET_TRAN_COUNT }); },
      doLogout: () => { dispatch({ type: actionTypes.LOGOUT }); }
 }
}

export default withStyles(headstyles)(connect(mapStateToProps,mapDispatchToProps)(Header));
