import React,{useState} from 'react';
import { Button } from '@material-ui/core';
import { Grid,Typography,TextField} from '@material-ui/core';
import ZacDialog from './ZacDialog';
import {  VoucherIcon } from '../pages/layout/Gobalicons';

const Voucher = (props) =>  {
   const [ diaOpen , setDiaOpen ] = useState(false);
   

   const confirmNo = () => {
      setDiaOpen(false);

   }
   const confirmYes = () => {
     props.payOther(props.toPayAccount, props.payAmount, props.payMemo, props.payAsset, props.privK, props.pubK);
     setDiaOpen(false);
   }
   const makeMessage = () => {
     return "Do you want to Buy a Cash Voucher for : " +  props.payAmount  + " (ZAC)? Note: After Payment has gone through it is irreversable.You will receive a SMS on phone no : " + props.phone ;
   }

   const VoucherPhone = (props) => {
     if (props.phone) {
       return "Create voucher and deliver to phone no: " + props.phone ;
     } else {
       return "Loading...";
     }

   }
   const toAccountForm = () => {
       if (props.hasToPayAcc) {
         return <React.Fragment>
           <Grid container
             direction="row"
             justify="center"
             alignItems="center"
             spacing={2}
           >
             <Grid item xs={10} >
             <Typography>
                <VoucherPhone phone={props.phone}/>
               </Typography>
             </Grid>
             <Grid item xs={10} >
             <TextField
                 name="payamount"
                 id="payamount"
                 label="Amount"
                 placeholder="Amount ZAC"
                 helperText={setErrorText("payamount")}
                 margin="normal"
                 error={false}
                 InputLabelProps={{
                   shrink: true,
                 }}
                 value={props.payAmount}
                 onChange={props.handleChange('payamount')}
               />  
</Grid>
<Grid item xs={10}>
  <Typography variant="body1" component="span">
        You will be issued a voucher to the value of R {props.payAmount}.
  </Typography>
  <Typography variant="body1" component="span">
        <br/>The voucher can be used at Checkers / Shoprite / PNP / Clicks / Usave / Dischem
  </Typography>

</Grid>

<Grid item xs={10}>
              <ZacDialog open={diaOpen}  classes={props.classes}  
                message={makeMessage()}
                title="Buy Voucher Confirmation"  
                handleYes={confirmYes}
                handleNo={confirmNo}
                />
               <Button variant="contained" color="primary" onClick={() =>  {setDiaOpen(true) } } endIcon={<VoucherIcon />}  >Buy Voucher</Button>
               <Button variant="contained" color="secondary" onClick={() =>  {props.history.push('wallet') } } >Cancel</Button>
             </Grid>
           </Grid>
         </React.Fragment>;
       }
   }
   
   const searchZacAccount = () => {
        if  (!props.hasToPayAcc) {
        return   <React.Fragment>
                      <Typography variant="body1" component="span">
                        Please wait..
                        </Typography>
      
      </React.Fragment>;
        }
   }

   const setErrorText = (fromField) => {

switch(fromField) {
    case "payamount" :
        if (props.payAsset ==='ZAC')  {
        return "ZAC amount to be paid";
        }
        else  {
          return "Amount to be paid";
        }
        
    case "paytozacname" :
          return "The zac address of user to be paid";
    case "paymemo":
        return "Description memo to the receiver to identify the payment";
    default: 
    if (props.hasToPayAcc === 'DOES_NOT_EXIST') {
        return "User not found";
   } else {
       return "Name / Email of Receiver";
   }
   }
}
   

const ret =  <React.Fragment>
        {searchZacAccount()}
        {toAccountForm()}
        </React.Fragment>;

    return ret;
};

export default Voucher;
