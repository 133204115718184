import * as SagaActions from '../../Actions/types/sagatypes';
import {getBalanceSaga,
        doZacTrustSaga,
        doPaymentSaga,
        checkFundAccountExist,
        doAccountFundSaga,
        doTranRefreshSaga,
        createPaymentStream,
        initTrade,
        streamReturn,
        getHistoricPayments,
        initExternalPayment,
        verifyKP} from './stellarSagas';
import { takeEvery, 
        all, 
        takeLatest,
        takeLeading 
      } from "redux-saga/effects";



export function* stellarActions() {
    yield all([
        takeLatest(SagaActions.FETCH_BAL_INIT, getBalanceSaga),
        takeLatest(SagaActions.ZAC_TRUST_INIT, doZacTrustSaga),
        takeLatest(SagaActions.PAYMENT_INIT,doPaymentSaga),
        takeEvery(SagaActions.CHECK_FUND_ACCOUNT_INIT,checkFundAccountExist),
        takeEvery(SagaActions.FUND_ACCOUNT_INIT,doAccountFundSaga),
        takeLatest(SagaActions.REFRESH_TRAN_INIT,doTranRefreshSaga),
        takeLatest(SagaActions.TRADE_INIT,initTrade),
        takeLeading(SagaActions.PAYMENT_STREAM_INIT,createPaymentStream),
        takeEvery(SagaActions.PAYMENT_STREAM_LINE,streamReturn),
        takeLeading(SagaActions.GET_PAYMENT_ARRAY_INIT,getHistoricPayments),
        takeEvery(SagaActions.EXTERNAL_PAY_INIT,initExternalPayment),
        takeLatest(SagaActions.VERIFY_PRIV_KEY,verifyKP)
//      takeEvery(actionTypes.AUTH_INITIATE_LOGOUT, logoutSaga),
      //takeEvery(actionTypes.AUTH_USER, authUserSaga),
      //takeEvery(actionTypes.AUTH_CHECK_STATE, authCheckStateSaga)
    ]);
  }
  