import React from 'react';
import {connect} from 'react-redux';
import { withStyles} from '@material-ui/core/styles'
import ContentHeader from '../../components/ContentHeader';
import { Paper } from '@material-ui/core';
import { styles } from '../layout/theme';
import { getToZacNamePubK } from '../../Actions/thunkActions';
import { doPayment,getPaymentArray }  from '../../Actions/stellarActions';
import * as ActionTypes from '../../Actions/types';
import Payment from '../../components/Payment';
//import ReceiptIcon from '@material-ui/icons/Receipt';
import { TransactionsIcon,BalanceIcon } from '../layout/Gobalicons';
import { Redirect } from 'react-router-dom';


const ExternalPayPage = React.memo(props => {

    const { classes } = props;
    //const [paytozacname, paytozacnameChange] = useState("");
    //const [payamount, payamountChange] = useState("");
    //const [paymemo, paymemoChange] = useState("");
    //const [payAsset, payAssetChange] = useState("ZAC");

    const locDoPay= (otherPK,amount,memo,payAsset,privK,pubK) => {
        props.payOther(otherPK,amount,memo,payAsset,privK,pubK);
        props.goGetTrans(pubK);
        props.history.push('/tran');

    }

    const handleChange = field => event => {
  /*      if (field === 'paytozacname') {
            paytozacnameChange(event.target.value);
        }
        if (field === 'payamount') {
            payamountChange(event.target.value);
        }
        if (field === 'paymemo') {
            if (event.target.value.length <=28) {
              paymemoChange(event.target.value);
            }
        }
        if (field === 'payAsset') {
            payAssetChange(event.target.value);
        }

        //this.setState({ [prop]: event.target.value });
        */
      };

      const checkLoggedIn = () => {
      return (!props.isLoggedIn ? 
      <Redirect to="/login" /> 
      : null
      );
    }
    const locReset = () => {
        props.resetUserToPay();
        props.history.push('/wallet');
    }

    return ( 
    <>
        {checkLoggedIn()}
        <Paper elevation={3} className={classes.paper}>
        <ContentHeader 
        classes={classes} 
        headerdisplay="External Payment" 
        has_tp2={true}
        tp2={"Wallet"}
        icon2={<BalanceIcon />}
        icon2click={() => {
            props.history.push('/wallet');
        }}
        has_tp3={true}
        tp3={"Transactions"}
        icon3={<TransactionsIcon/>}
        icon3click={() => {
               // props.goGetTrans(props.publickey);
                props.history.push('/tran');
            }}

        />
        <Payment 
            paytozacname={props.paytozacname} 
            handleChange={handleChange} 
         //   findToZacNamePubK={props.dofindToZacNamePubK}  
            zacDomain={props.zacDomain} 
            payAsset="ZAC"
            toPayAccount={props.toPayAccount}
            hasToPayAcc={props.hasToPayAcc} 
            payOther={locDoPay} 
            payMemo={props.extComment} 
            payAmount={props.extAmmout} 
            privK={props.privatekey} 
            pubK={props.publickey}  
            resetUserToPay={locReset}
            classes={classes} 
//            isTrade={props.isTrade} 
            
            />
    </Paper>
        </>
    );
}
    
);

const mapStateToProps = (state) => {
    return {
        transactions : state.accounts.transactions,
        privatekey : state.accounts.privateKey,
        publickey : state.accounts.publicKey,
        zacNameMessage : state.accounts.zacNameMessage,
        zacNameAvail : state.accounts.zacNameAvail,
        zacDomain : state.accounts.zac_domain,
        zacName : state.accounts.zacName,
        newZacName : state.accounts.newZacName,
        isLoggedIn : state.accounts.isLoggedIn,
        toPayAccount : state.accounts.toPayAccount,
        paytozacname : state.accounts.toPayStellar,
        hasToPayAcc : state.accounts.hasToPayAcc,
        isTrade : state.accounts.isTrade,
        extAmmout : state.accounts.extAmount,
        extComment : state.accounts.extComment 

    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        dofindToZacNamePubK: (zacName,zacDomain) => {
            dispatch(getToZacNamePubK(zacName,zacDomain));
        },
        resetUserToPay: () =>  dispatch(() => dispatch({type : ActionTypes.CLEAR_ZAC_TO_PAY_NAME}))
        ,
        payOther: (otherPK,amount,memo,payAsset,privK,pubK) => {
            dispatch(doPayment(otherPK,amount,memo,payAsset,privK,pubK));

        },
        goGetTrans: (pubK) => {
           // dispatch({ type: actionTypes.CLEAR_PAY_REC });
            dispatch(getPaymentArray(pubK));
            //dispatch(getAllTransactions(pubK));
          },

   }
}

export default withStyles(styles)(connect(mapStateToProps,mapDispatchToProps)(ExternalPayPage));
