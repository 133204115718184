import sjcl from 'sjcl';

const encPub  = (passw, publicK) => {
    const myParamsStr =  {"iv":publicK.substring(1,23)+ '=' ,"v":1,"iter":10000,"ks":128,"ts":64,"mode":"ccm","adata":"","cipher":"aes","salt":publicK.substring(1,12)+ "="};
    const encryptedpub = sjcl.encrypt(passw,publicK,myParamsStr);
    const encryptedpubClass = JSON.parse(encryptedpub);
    return encryptedpubClass;
}

const getPrivParams = (passw, publicK) => {
    const encryptedpubClass = encPub(passw, publicK)
    const privParams =  {"iv":encryptedpubClass.ct.substring(1,23)+ '=' ,"v":1,"iter":10000,"ks":128,"ts":64,"mode":"ccm","adata":"","cipher":"aes","salt":encryptedpubClass.ct.substring(1,12)+ "="};
    return  privParams;
    
}

export const encrypt = (passw, publicK,privateK) => {
  const privParamsStr = getPrivParams(passw, publicK);
  const encryptedpriv = sjcl.encrypt(passw,privateK,privParamsStr);
 return JSON.parse(encryptedpriv).ct;
}

export const getKey = (passw, publicK) => {
    const privParams = encPub(passw, publicK);
    if   ((privParams.ct) &&   (privParams.ct.length > 23)) {
         return  privParams.ct.substring(23,(privParams.ct.length-2)) ;
    } else {
        return "";
    }
    //return  privParams ;
}
export const decrypt = (passw,publicK,ct ) => {
    const encryptedpubClass = getPrivParams(passw, publicK);
    encryptedpubClass.ct = ct;
    return sjcl.decrypt(passw,JSON.stringify(encryptedpubClass));
}

export const doEncrypt = (passw, publickey,privatekey) => {
        const ct = encrypt(passw,publickey,privatekey);
        const key = getKey(passw,publickey,privatekey);
        return {
            ct : ct,
            key : key
        }
}