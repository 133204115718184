
import React from 'react';
import Alert from '../components/Alert';
import {connect} from 'react-redux';
import * as ActionTypes from '../Actions/types';
import Snackbar from '@material-ui/core/Snackbar';

const ErrorAlert = React.memo(props => {
    
/*  Check of iets hier gebuer?     closeSnack = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        this.setState( { openSnack : false } );
      };
   
  */

  return (
<Snackbar open={props.acc_error} autoHideDuration={20000} onClose={props.closeSnack}>
  <Alert onClose={props.closeSnack} severity={props.acc_error_type}>{props.acc_error_msg}</Alert>
</Snackbar>
            
        );
    }
);


const mapStateToProps = (state) => {
    return {
        acc_error : state.accounts.error,
        acc_error_msg: state.accounts.error_msg,
        acc_error_type : state.accounts.error_type,
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        closeSnack : () =>  { dispatch({
            type: ActionTypes.CLEAR_ERROR
        }) }
    }
}


export default (connect(mapStateToProps,mapDispatchToProps)(ErrorAlert));


