import React, { Component } from 'react';
import AppCss from './App.css';
import Header from './pages/layout/Header';
//import Navigator from './pages/layout/Navigator';
import Login from './pages/Login';
import {Switch, Route,BrowserRouter } from 'react-router-dom';
//import Account from './pages/Account';
//import NewAcc from './pages/content/NewAcc';
import NewAccStep from './pages/content/NewAccStep';
import Wallets from './pages/content/Wallets';
import CssBaseline from '@material-ui/core/CssBaseline';
import Transactions from './pages/content/Transactions';
import PaymentPage from './pages/content/PaymentPage';
import ChangePassw from './pages/content/ChangePassw';
import ErrorAlert from './components/ErrorAlert';
//import Paperbase from './pages/paperbase/Paperbase';
import ShowKeysPage from './pages/content/ShowKeysPage';
//import CacheBuster from './CacheBuster';
//import AccountUpgrade from './pages/content/AccountUpgrade';
import Info from './pages/content/Info';
import { theme } from './pages/layout/theme';
import { ThemeProvider } from '@material-ui/core/styles';
//import TestResponsiveDrawer from './pages/paperbase/TestResponsiveDrawer';
import FundAccount from './pages/content/FundAccount';
//import SagaHelper from './reducers/sagas/SagaHelper';
import TradePage from './pages/content/TradePage';
import MakeQR from './pages/MakeQR';
import ReceivePay from './pages/ReceivePay';
import ExternalPayPage from './pages/content/ExternalPayPage';
import LevOne from './pages/content/LevOne';
import VoucherPage from './pages/content/VoucherPage';

/*  classes={AppCss} 
npm version patch
npm run-script build
*/


class App extends Component {

  

  render() {

/*    return (
      <CacheBuster>
      {({ loading, isLatestVersion, refreshCacheAndReload }) => {
        if (loading) return null;
        if (!loading && !isLatestVersion) {
          // You can decide how and when you want to force reload
          refreshCacheAndReload();
        }
        */
       const {classes} = this.props;
    return (
      <React.Fragment>
         <ThemeProvider theme={theme}>
       <CssBaseline />
      <BrowserRouter basename="/secure">   
       <div className="App">
         
         <Route path="/" render={(props) => <Header {...props}  store={this.store} classes={classes} />}/>  
       { /*  <nav className={classes.drawer}>
         <Route path="/" render={(props) => <Navigator {...props}  store={this.store} classes={classes} open={false} />}/>  
         </nav>
          <Route path="/account" component={Account}/>
    */ 
   }

        <Switch>
          
         
  
          <Route path="/new" render={(props) => <NewAccStep {...props} />}/>
          <Route path="/wallet" render={(props) => <Wallets {...props} />}/>
          <Route path="/tran/:cur" render={(props) => <Transactions {...props}  />}/>
          <Route path="/tran" render={(props) => <Transactions {...props}  />}/>
          <Route path="/pay" render={(props) => <PaymentPage {...props} />}/>
          <Route path="/login" component={Login}/>
          <Route path="/showkeys" render={(props) => <ShowKeysPage {...props} />}/>
          <Route path="/fund" render={(props) => <FundAccount {...props} />}/> 
          <Route path="/trade" render={(props) => <TradePage {...props} />}/> 
          <Route path="/voucher" render={(props) => <VoucherPage {...props} />}/> 
          <Route path="/qr" render={(props) => <MakeQR {...props} />}/> 
          <Route path="/levone" render={(props) => <LevOne {...props} />}/>
          <Route path="/to-pay/:receiver/:amount/:comment"  render={(props) => <ReceivePay {...props} />}/>
          <Route path="/to-pay/:receiver/:amount"  render={(props) => <ReceivePay {...props} />}/>
          <Route path="/to-pay/:receiver" render={(props) => <ReceivePay {...props} />}/>
          <Route path="/extpay" render={(props) => <ExternalPayPage {...props} />}/>
          <Route path="/passw" render={(props) => <ChangePassw {...props} />}/>
          <Route exact path="/" component={(props) => <Info {...props} />}/>
          <Route exact path="/secure" component={(props) => <Info {...props} />}/>
          <Route path="/posts/:slug">
          
        </Route>

          </Switch>
     
         <ErrorAlert />
      </div>
      </BrowserRouter>
       </ThemeProvider>  
      </React.Fragment>
    );
 /*    }}
            </CacheBuster>
    );
    */
    
  }
}

export default (App);

/*
IN PROGRESS
<Route path="/upgrade" render={(props) => <AccountUpgrade {...props} />}/> 

OLD 
import CreateAccount from './pages/CreateAccount';
import Paperbase from './pages/paperbase/Paperbase';
import NewLayout from './pages/layout';

<Route path="/paper" component={Paperbase}/>
          <Route path="/create" component={CreateAccount}/>
          <Route path="/newlayout" component={NewLayout}/>  
          <Route path="/newold" render={(props) => <NewAcc {...props} />}/>
                    <Route path="/paper" component={Paperbase}/>
          <Route path="/drawer" component={TestResponsiveDrawer} /> 
          <Route path="/nav" render={(props) => <Navigator {...props}  store={this.store} />} />  

*/