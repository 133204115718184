import React from 'react';
import ShowKeys from '../../components/ShowKeys';
import {connect} from 'react-redux';
import { Paper, Grid,Button } from '@material-ui/core/';
import { styles } from '../layout/theme';
import { withStyles} from '@material-ui/core/styles'
import ContentHeader from '../../components/ContentHeader';
//import PrintThisComponent from '../../components/PrintThisComponent';
//import PrintKeysPage from './PrintKeysPage';
import { Redirect } from 'react-router-dom';
import {BalanceIcon } from '../layout/Gobalicons';
// import { addSPriv } from '../../Actions/thunkActions';
// import { encrypt, getKey } from '../../utils/myCrypt';


const ShowKeysPage = React.memo(props => {
    const [print, setPrint] = React.useState(false);
    const { classes } = props;
/*
    const testNewPriv = () => {
            const enc = encrypt('hano123',props.publickey,props.privatekey);
            console.log(enc);
            const key = getKey('hano123',props.publickey,props.privatekey);
            props.doSubmitNewPriv( {
                key: key,
                ct: enc,
                pub: props.publickey
            });
    }
*/
    const checkLoggedIn = () => {
        return (!props.isLoggedIn ? <Redirect to="/login" /> : null );
     }


    return ( 
        <>
        {checkLoggedIn()}
    <Paper elevation={3} className={classes.paper}>
        <ContentHeader 
        classes={classes} 
        headerdisplay="Keys" 
        has_tp3={true}
        tp3="Balance"
        icon3={<BalanceIcon />}
        icon3click={() => {
            props.history.push('/wallet');
        }}
        />

               <Grid container
                 direction="column"
                 justify="center"
                 alignItems="center"
               >
                <ShowKeys 
                   publickey={props.publickey}
                   classes={classes}
                   privatekey={props.privatekey}
                   zacDomain={props.zacDomain}
                   print={print}
                />
                <Grid item>
                    <Button variant="contained" color="primary" onClick={() => {
                         setPrint(true);
                         setTimeout(
                         () => {
                            window.print();
                            setPrint(false);
                         }
                         ,500);
                        }
                         }>PRINT</Button>
                   {/*  <PrintThisComponent />
                   */
                  }
                 { /*  <Button variant="contained" color="primary" onClick={() => { testNewPriv(); } }>TEST</Button> */ }
                </Grid>

                  

                </Grid>

                </Paper>
   </>
    )
    }
);

const mapStateToProps = (state) => {
    return {
        privatekey : state.accounts.privateKey,
        publickey : state.accounts.publicKey,
        zacDomain : state.accounts.zac_domain,
        isLoggedIn : state.accounts.isLoggedIn,
    }
}
const mapDispatchToProps = (dispatch) => {
    return {
      /*  doSubmitNewPriv: (privrecord) => {
            dispatch(addSPriv(privrecord));
         },
         */
}
}

export default withStyles(styles)(connect(mapStateToProps,mapDispatchToProps)(ShowKeysPage));
